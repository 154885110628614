import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import PaywallScreenDescription from 'src/components/Paywall/PaywallScreenDescription';
import { useEffect } from 'react';
import { logAnalyticsEvent } from 'src/modules/analytics/Amplitude';
import PaywallScreenDescriptionVideo from 'src/components/Paywall/PaywallScreenDescriptionVideo';
import { useFlags } from 'src/contexts/FlagsContext';

const OnboardingParentChildPaywallDescription = () => {
  const { childId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { display_video_paywall } = useFlags();

  useEffect(() => {
    logAnalyticsEvent('paywall_viewed', {
      type: display_video_paywall
        ? 'onboarding_video_parent'
        : 'onboarding_screen1_parent',
    });
  }, []);

  if (display_video_paywall) {
    return (
      <PaywallScreenDescriptionVideo
        onContinue={() => {
          navigate(
            `/onboarding/parent/child/${childId}/school_level/device/paywall/pay?${searchParams.toString()}`,
          );
        }}
      />
    );
  }

  return (
    <PaywallScreenDescription
      onContinue={() => {
        navigate(
          `/onboarding/parent/child/${childId}/school_level/device/paywall/pay?${searchParams.toString()}`,
        );
      }}
    />
  );
};

export default OnboardingParentChildPaywallDescription;
