import { useState } from 'react';
import AddLessonModal from 'src/components/AddLesson/AddLessonModal';
import PlusIcon from 'src/images/plus.svg?react';
import { SafeArea } from 'antd-mobile';

const AddLessonFloatingButton = () => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div
        className={'absolute bottom-20 right-4'}
        data-testid={'btn-add-lesson'}
      >
        <div
          onClick={handleClickOpen}
          className={
            'flex items-center justify-center rounded-full w-16 h-16 bg-structural-darkest'
          }
        >
          <PlusIcon className={'fill-white w-4 h-4'} />
        </div>
        <SafeArea position={'bottom'} />
      </div>
      <AddLessonModal isOpen={open} onClose={handleClose} />
    </>
  );
};

export default AddLessonFloatingButton;
