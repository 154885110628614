import { Trans, useTranslation } from 'react-i18next';
import Badge from 'src/components/common/designSystem/Badge';
import { formatPrice } from 'src/utils/price';
import {
  PACKAGE_TYPE,
  PurchasesPackage,
} from '@revenuecat/purchases-capacitor';
import { usePaywall } from 'src/contexts/PaywallContext';

const Offer = ({
  isSelected = true,
  promo,
  onClick,
  purchasesPackage,
}: {
  isSelected?: boolean;
  promo?: string;
  onClick?: (purchasesPackage: PurchasesPackage) => void;
  purchasesPackage: PurchasesPackage;
}) => {
  const { t } = useTranslation();
  const { promoCodes } = usePaywall();

  const promoToApply = promoCodes.find(promoCode =>
    promoCode.product_ids.includes(purchasesPackage.product.identifier),
  );

  return (
    <div
      className={'relative cursor-pointer w-full'}
      onClick={onClick ? () => onClick(purchasesPackage) : undefined}
    >
      {promoToApply ? (
        <Badge
          text={t(`paywall.panel.${purchasesPackage.packageType}.promo`, {
            promo: promoToApply.discount,
          })}
        />
      ) : promo ? (
        <Badge text={promo} />
      ) : null}
      <div
        data-testid={`radio-${purchasesPackage.packageType}`}
        className={`w-full items-center ${isSelected ? 'bg-gradient-to-r' : 'bg-structural-lighter'} from-paywall-darkest-from to-paywall-darkest-to rounded-xl p-1`}
      >
        <div
          className={
            'flex flex-row justify-between w-full h-full items-center bg-white rounded-lg p-4 px-4 gap-2'
          }
        >
          <div className={'flex flex-row gap-4 items-center justify-center'}>
            <div
              className={`rounded-full flex justify-center items-center w-6 h-6 ${isSelected ? 'bg-gradient-to-r' : 'bg-structural-lightest'}  from-paywall-darkest-from to-paywall-darkest-to`}
            >
              <div className={'rounded-full w-2 h-2 bg-white'} />
            </div>
            <div className={'flex flex-col'}>
              <div
                className={
                  'font-extrabold text-lg leading-none text-structural-darker'
                }
              >
                {t(`paywall.panel.${purchasesPackage.packageType}.type`)}
              </div>
              {purchasesPackage.packageType !== PACKAGE_TYPE.MONTHLY ? (
                <div
                  className={'text-base leading-none text-structural-darker'}
                >
                  <Trans
                    i18nKey={`paywall.panel.${purchasesPackage.packageType}.description`}
                    values={{
                      price: promoToApply
                        ? formatPrice(promoToApply.price_eur / 12)
                        : purchasesPackage.product.pricePerMonthString,
                    }}
                  >
                    Soit <b>5,49€</b> par mois
                  </Trans>
                </div>
              ) : null}
            </div>
          </div>
          <div className={'flex flex-col text-right'}>
            <div
              className={
                'font-extrabold text-lg leading-none text-structural-darker'
              }
            >
              {promoToApply
                ? formatPrice(promoToApply.price_eur)
                : purchasesPackage.product.priceString}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Offer;
