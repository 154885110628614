import { FC, type SVGProps } from 'react';

const PaywallDescription = ({
  title,
  description,
  IconComponent,
}: {
  title: string;
  description: string;
  IconComponent: FC<SVGProps<SVGSVGElement>>;
}) => {
  return (
    <div className={'flex flex-row justify-center items-center gap-4'}>
      <IconComponent className={'w-10 h-10'} />
      <div className={'flex flex-col'}>
        <div className={'font-bold text-base text-structural-darker'}>
          {title}
        </div>
        <div className={'text-structural-darker text-base text-balance'}>
          {description}
        </div>
      </div>
    </div>
  );
};

export default PaywallDescription;
