import { RevenueCatPlacement } from 'src/modules/purchase/purchase.constants';
import {
  type PACKAGE_TYPE,
  type PRODUCT_CATEGORY,
  type PRODUCT_TYPE,
  PurchasesOfferings,
  PurchasesPackage,
} from '@revenuecat/purchases-typescript-internal-esm';

export const fakeRevenueCatOfferings: PurchasesOfferings = {
  current: null,
  all: {
    default: {
      identifier: 'default',
      metadata: {},
      monthly: {
        offeringIdentifier: 'default',
        product: {
          pricePerWeek: 1.84,
          pricePerMonth: 7.99,
          identifier: 'premium',
          currencyCode: 'EUR',
          description: 'Profitez de l’expérience Baobab sans limite !',
          introPrice: {
            cycles: 1,
            periodUnit: 'WEEK',
            price: 0,
            priceString: '0,00 €',
            period: 'P1W',
            periodNumberOfUnits: 1,
          },
          priceString: '7,99 €',
          pricePerMonthString: '7,99 €',
          productType: 'AUTO_RENEWABLE_SUBSCRIPTION' as PRODUCT_TYPE,
          pricePerYear: 95.88,
          subscriptionPeriod: 'P1M',
          productCategory: 'SUBSCRIPTION' as PRODUCT_CATEGORY,
          discounts: [],
          defaultOption: null,
          subscriptionOptions: null,
          presentedOfferingIdentifier: null,
          presentedOfferingContext: null,
          pricePerWeekString: '1,84 €',
          pricePerYearString: '95,88 €',
          price: 7.99,
          title: 'Abonnement mensuel',
        },
        presentedOfferingContext: {
          offeringIdentifier: 'default',
          placementIdentifier: null,
          targetingContext: null,
        },
        identifier: '$rc_monthly',
        packageType: 'MONTHLY' as PACKAGE_TYPE,
      },
      lifetime: null,
      threeMonth: null,
      twoMonth: null,
      annual: null,
      sixMonth: null,
      weekly: null,
      serverDescription: 'The standard set of package',
      availablePackages: [
        {
          offeringIdentifier: 'default',
          identifier: '$rc_monthly',
          presentedOfferingContext: {
            offeringIdentifier: 'default',
            targetingContext: null,
            placementIdentifier: null,
          },
          packageType: 'MONTHLY' as PACKAGE_TYPE,
          product: {
            description: 'Profitez de l’expérience Baobab sans limite !',
            pricePerWeekString: '1,84 €',
            priceString: '7,99 €',
            discounts: [],
            defaultOption: null,
            subscriptionOptions: null,
            presentedOfferingIdentifier: null,
            presentedOfferingContext: null,
            currencyCode: 'EUR',
            pricePerYearString: '95,88 €',
            pricePerMonthString: '7,99 €',
            productType: 'AUTO_RENEWABLE_SUBSCRIPTION' as PRODUCT_TYPE,
            pricePerWeek: 1.84,
            subscriptionPeriod: 'P1M',
            pricePerYear: 95.88,
            price: 7.99,
            identifier: 'premium',
            productCategory: 'SUBSCRIPTION' as PRODUCT_CATEGORY,
            introPrice: {
              periodNumberOfUnits: 1,
              price: 0,
              period: 'P1W',
              cycles: 1,
              priceString: '0,00 €',
              periodUnit: 'WEEK',
            },
            pricePerMonth: 7.99,
            title: 'Abonnement mensuel',
          },
        },
      ],
    },
    premium_annual: {
      availablePackages: [
        {
          offeringIdentifier: 'premium_annual',
          product: {
            pricePerMonth: 5.5,
            productType: 'AUTO_RENEWABLE_SUBSCRIPTION' as PRODUCT_TYPE,
            productCategory: 'SUBSCRIPTION' as PRODUCT_CATEGORY,
            subscriptionPeriod: 'P1Y',
            discounts: [],
            defaultOption: null,
            subscriptionOptions: null,
            presentedOfferingIdentifier: null,
            presentedOfferingContext: null,
            pricePerWeek: 1.27,
            pricePerMonthString: '5,50 €',
            priceString: '65,99 €',
            title: 'Abonnement annuel',
            introPrice: {
              price: 0,
              priceString: '0,00 €',
              period: 'P1W',
              periodNumberOfUnits: 1,
              cycles: 1,
              periodUnit: 'WEEK',
            },
            currencyCode: 'EUR',
            description: 'Profitez de l’expérience Baobab sans limite !',
            pricePerYear: 65.99,
            identifier: 'premium_annual',
            price: 65.99,
            pricePerYearString: '65,99 €',
            pricePerWeekString: '1,27 €',
          },
          presentedOfferingContext: {
            offeringIdentifier: 'premium_annual',
            placementIdentifier: null,
            targetingContext: null,
          },
          packageType: 'ANNUAL' as PACKAGE_TYPE,
          identifier: '$rc_annual',
        },
      ],
      annual: {
        presentedOfferingContext: {
          placementIdentifier: null,
          offeringIdentifier: 'premium_annual',
          targetingContext: null,
        },
        packageType: 'ANNUAL' as PACKAGE_TYPE,
        identifier: '$rc_annual',
        offeringIdentifier: 'premium_annual',
        product: {
          currencyCode: 'EUR',
          price: 65.99,
          pricePerMonth: 5.5,
          pricePerYear: 65.99,
          pricePerWeekString: '1,27 €',
          pricePerYearString: '65,99 €',
          productType: 'AUTO_RENEWABLE_SUBSCRIPTION' as PRODUCT_TYPE,
          title: 'Abonnement annuel',
          subscriptionPeriod: 'P1Y',
          priceString: '65,99 €',
          description: 'Profitez de l’expérience Baobab sans limite !',
          pricePerMonthString: '5,50 €',
          pricePerWeek: 1.27,
          discounts: [],
          defaultOption: null,
          subscriptionOptions: null,
          presentedOfferingIdentifier: null,
          presentedOfferingContext: null,
          identifier: 'premium_annual',
          productCategory: 'SUBSCRIPTION' as PRODUCT_CATEGORY,
          introPrice: {
            price: 0,
            periodUnit: 'WEEK',
            periodNumberOfUnits: 1,
            priceString: '0,00 €',
            period: 'P1W',
            cycles: 1,
          },
        },
      },
      lifetime: null,
      threeMonth: null,
      twoMonth: null,
      monthly: null,
      sixMonth: null,
      weekly: null,
      serverDescription: 'The annual offer',
      metadata: {},
      identifier: 'premium_annual',
    },
    without_trial: {
      metadata: {},
      serverDescription: 'The default offer without trial',
      identifier: 'without_trial',
      availablePackages: [
        {
          product: {
            currencyCode: 'EUR',
            pricePerYear: 95.88,
            productCategory: 'SUBSCRIPTION' as PRODUCT_CATEGORY,
            identifier: 'premium_without_trial',
            price: 7.99,
            pricePerMonth: 7.99,
            productType: 'AUTO_RENEWABLE_SUBSCRIPTION' as PRODUCT_TYPE,
            introPrice: null,
            priceString: '7,99 €',
            discounts: [],
            defaultOption: null,
            subscriptionOptions: null,
            presentedOfferingIdentifier: null,
            presentedOfferingContext: null,
            title: 'Abonnement mensuel',
            pricePerWeek: 1.84,
            subscriptionPeriod: 'P1M',
            pricePerYearString: '95,88 €',
            description: 'Profitez de l’expérience Baobab sans limite !',
            pricePerWeekString: '1,84 €',
            pricePerMonthString: '7,99 €',
          },
          identifier: '$rc_monthly',
          offeringIdentifier: 'without_trial',
          packageType: 'MONTHLY' as PACKAGE_TYPE,
          presentedOfferingContext: {
            offeringIdentifier: 'without_trial',
            targetingContext: null,
            placementIdentifier: null,
          },
        },
      ],
      monthly: {
        packageType: 'MONTHLY' as PACKAGE_TYPE,
        offeringIdentifier: 'without_trial',
        product: {
          pricePerMonth: 7.99,
          introPrice: null,
          pricePerWeekString: '1,84 €',
          pricePerMonthString: '7,99 €',
          price: 7.99,
          priceString: '7,99 €',
          description: 'Profitez de l’expérience Baobab sans limite !',
          pricePerWeek: 1.84,
          productCategory: 'SUBSCRIPTION' as PRODUCT_CATEGORY,
          pricePerYear: 95.88,
          currencyCode: 'EUR',
          discounts: [],
          defaultOption: null,
          subscriptionOptions: null,
          presentedOfferingIdentifier: null,
          presentedOfferingContext: null,
          title: 'Abonnement mensuel',
          subscriptionPeriod: 'P1M',
          productType: 'AUTO_RENEWABLE_SUBSCRIPTION' as PRODUCT_TYPE,
          pricePerYearString: '95,88 €',
          identifier: 'premium_without_trial',
        },
        identifier: '$rc_monthly',
        presentedOfferingContext: {
          targetingContext: null,
          offeringIdentifier: 'without_trial',
          placementIdentifier: null,
        },
      },
      lifetime: null,
      threeMonth: null,
      twoMonth: null,
      annual: null,
      sixMonth: null,
      weekly: null,
    },
    semi_annual_and_monthly: {
      serverDescription: 'Semi annual and monthly offer',
      monthly: {
        presentedOfferingContext: {
          placementIdentifier: null,
          targetingContext: null,
          offeringIdentifier: 'semi_annual_and_monthly',
        },
        product: {
          subscriptionPeriod: 'P1M',
          productType: 'AUTO_RENEWABLE_SUBSCRIPTION' as PRODUCT_TYPE,
          description: 'Profitez de l’expérience Baobab sans limite !',
          priceString: '7,99 €',
          title: 'Abonnement mensuel',
          pricePerMonth: 7.99,
          pricePerYear: 95.88,
          pricePerYearString: '95,88 €',
          discounts: [],
          defaultOption: null,
          subscriptionOptions: null,
          presentedOfferingIdentifier: null,
          presentedOfferingContext: null,
          price: 7.99,
          productCategory: 'SUBSCRIPTION' as PRODUCT_CATEGORY,
          pricePerWeek: 1.84,
          identifier: 'premium',
          introPrice: {
            period: 'P1W',
            periodNumberOfUnits: 1,
            price: 0,
            periodUnit: 'WEEK',
            cycles: 1,
            priceString: '0,00 €',
          },
          pricePerWeekString: '1,84 €',
          pricePerMonthString: '7,99 €',
          currencyCode: 'EUR',
        },
        identifier: '$rc_monthly',
        packageType: 'MONTHLY' as PACKAGE_TYPE,
        offeringIdentifier: 'semi_annual_and_monthly',
      },
      metadata: {},
      identifier: 'semi_annual_and_monthly',
      sixMonth: {
        packageType: 'SIX_MONTH' as PACKAGE_TYPE,
        product: {
          introPrice: {
            price: 0,
            periodUnit: 'WEEK',
            priceString: '0,00 €',
            period: 'P1W',
            periodNumberOfUnits: 1,
            cycles: 1,
          },
          description: 'Profitez de l’expérience Baobab sans limite !',
          pricePerMonthString: '6,58 €',
          pricePerYearString: '78,98 €',
          subscriptionPeriod: 'P6M',
          priceString: '39,49 €',
          identifier: 'premium_semi_annual',
          pricePerYear: 78.98,
          title: 'Premium semi annuel',
          price: 39.49000000000001,
          pricePerWeek: 1.51,
          productCategory: 'SUBSCRIPTION' as PRODUCT_CATEGORY,
          productType: 'AUTO_RENEWABLE_SUBSCRIPTION' as PRODUCT_TYPE,
          pricePerMonth: 6.58,
          discounts: [],
          defaultOption: null,
          subscriptionOptions: null,
          presentedOfferingIdentifier: null,
          presentedOfferingContext: null,
          pricePerWeekString: '1,51 €',
          currencyCode: 'EUR',
        },
        identifier: '$rc_six_month',
        offeringIdentifier: 'semi_annual_and_monthly',
        presentedOfferingContext: {
          placementIdentifier: null,
          targetingContext: null,
          offeringIdentifier: 'semi_annual_and_monthly',
        },
      },
      lifetime: null,
      threeMonth: null,
      twoMonth: null,
      annual: null,
      weekly: null,
      availablePackages: [
        {
          product: {
            pricePerMonthString: '6,58 €',
            price: 39.49000000000001,
            pricePerWeek: 1.51,
            discounts: [],
            defaultOption: null,
            subscriptionOptions: null,
            presentedOfferingIdentifier: null,
            presentedOfferingContext: null,
            productType: 'AUTO_RENEWABLE_SUBSCRIPTION' as PRODUCT_TYPE,
            productCategory: 'SUBSCRIPTION' as PRODUCT_CATEGORY,
            introPrice: {
              periodNumberOfUnits: 1,
              period: 'P1W',
              price: 0,
              priceString: '0,00 €',
              cycles: 1,
              periodUnit: 'WEEK',
            },
            identifier: 'premium_semi_annual',
            priceString: '39,49 €',
            currencyCode: 'EUR',
            pricePerMonth: 6.58,
            pricePerYearString: '78,98 €',
            description: 'Profitez de l’expérience Baobab sans limite !',
            pricePerYear: 78.98,
            subscriptionPeriod: 'P6M',
            title: 'Premium semi annuel',
            pricePerWeekString: '1,51 €',
          },
          identifier: '$rc_six_month',
          packageType: 'SIX_MONTH' as PACKAGE_TYPE,
          offeringIdentifier: 'semi_annual_and_monthly',
          presentedOfferingContext: {
            offeringIdentifier: 'semi_annual_and_monthly',
            placementIdentifier: null,
            targetingContext: null,
          },
        },
        {
          identifier: '$rc_monthly',
          offeringIdentifier: 'semi_annual_and_monthly',
          product: {
            introPrice: {
              period: 'P1W',
              periodNumberOfUnits: 1,
              periodUnit: 'WEEK',
              cycles: 1,
              price: 0,
              priceString: '0,00 €',
            },
            title: 'Abonnement mensuel',
            subscriptionPeriod: 'P1M',
            description: 'Profitez de l’expérience Baobab sans limite !',
            price: 7.99,
            priceString: '7,99 €',
            pricePerWeek: 1.84,
            pricePerMonthString: '7,99 €',
            pricePerWeekString: '1,84 €',
            pricePerYearString: '95,88 €',
            productCategory: 'SUBSCRIPTION' as PRODUCT_CATEGORY,
            identifier: 'premium',
            discounts: [],
            defaultOption: null,
            subscriptionOptions: null,
            presentedOfferingIdentifier: null,
            presentedOfferingContext: null,
            pricePerMonth: 7.99,
            currencyCode: 'EUR',
            productType: 'AUTO_RENEWABLE_SUBSCRIPTION' as PRODUCT_TYPE,
            pricePerYear: 95.88,
          },
          packageType: 'MONTHLY' as PACKAGE_TYPE,
          presentedOfferingContext: {
            placementIdentifier: null,
            targetingContext: null,
            offeringIdentifier: 'semi_annual_and_monthly',
          },
        },
      ],
    },
    premium_annual_without_trial: {
      annual: {
        offeringIdentifier: 'premium_annual_without_trial',
        presentedOfferingContext: {
          placementIdentifier: null,
          targetingContext: null,
          offeringIdentifier: 'premium_annual_without_trial',
        },
        packageType: 'ANNUAL' as PACKAGE_TYPE,
        product: {
          productCategory: 'SUBSCRIPTION' as PRODUCT_CATEGORY,
          identifier: 'premium_annual_without_trial',
          subscriptionPeriod: 'P1Y',
          pricePerYearString: '65,99 €',
          pricePerWeek: 1.27,
          pricePerYear: 65.99,
          pricePerWeekString: '1,27 €',
          title: 'Abonnement annuel',
          productType: 'AUTO_RENEWABLE_SUBSCRIPTION' as PRODUCT_TYPE,
          price: 65.99,
          introPrice: null,
          priceString: '65,99 €',
          currencyCode: 'EUR',
          description: 'Profitez de l’expérience Baobab sans limite !',
          discounts: [],
          defaultOption: null,
          subscriptionOptions: null,
          presentedOfferingIdentifier: null,
          presentedOfferingContext: null,
          pricePerMonth: 5.5,
          pricePerMonthString: '5,50 €',
        },
        identifier: '$rc_annual',
      },
      serverDescription: 'The annual offer without trial',
      metadata: {},
      identifier: 'premium_annual_without_trial',
      lifetime: null,
      threeMonth: null,
      twoMonth: null,
      monthly: null,
      sixMonth: null,
      weekly: null,
      availablePackages: [
        {
          product: {
            discounts: [],
            defaultOption: null,
            subscriptionOptions: null,
            presentedOfferingIdentifier: null,
            presentedOfferingContext: null,
            pricePerMonth: 5.5,
            title: 'Abonnement annuel',
            priceString: '65,99 €',
            pricePerMonthString: '5,50 €',
            pricePerYearString: '65,99 €',
            introPrice: null,
            identifier: 'premium_annual_without_trial',
            pricePerWeekString: '1,27 €',
            productType: 'AUTO_RENEWABLE_SUBSCRIPTION' as PRODUCT_TYPE,
            pricePerYear: 65.99,
            subscriptionPeriod: 'P1Y',
            currencyCode: 'EUR',
            price: 65.99,
            productCategory: 'SUBSCRIPTION' as PRODUCT_CATEGORY,
            pricePerWeek: 1.27,
            description: 'Profitez de l’expérience Baobab sans limite !',
          },
          presentedOfferingContext: {
            offeringIdentifier: 'premium_annual_without_trial',
            placementIdentifier: null,
            targetingContext: null,
          },
          identifier: '$rc_annual',
          packageType: 'ANNUAL' as PACKAGE_TYPE,
          offeringIdentifier: 'premium_annual_without_trial',
        },
      ],
    },
    premium_semi_annual: {
      serverDescription: 'Premium semi annual',
      metadata: {},
      availablePackages: [
        {
          presentedOfferingContext: {
            offeringIdentifier: 'premium_semi_annual',
            targetingContext: null,
            placementIdentifier: null,
          },
          offeringIdentifier: 'premium_semi_annual',
          product: {
            pricePerMonth: 6.58,
            description: 'Profitez de l’expérience Baobab sans limite !',
            price: 39.49000000000001,
            identifier: 'premium_semi_annual',
            pricePerYear: 78.98,
            currencyCode: 'EUR',
            pricePerWeekString: '1,51 €',
            pricePerMonthString: '6,58 €',
            productCategory: 'SUBSCRIPTION' as PRODUCT_CATEGORY,
            pricePerYearString: '78,98 €',
            title: 'Premium semi annuel',
            pricePerWeek: 1.51,
            productType: 'AUTO_RENEWABLE_SUBSCRIPTION' as PRODUCT_TYPE,
            priceString: '39,49 €',
            introPrice: {
              period: 'P1W',
              priceString: '0,00 €',
              price: 0,
              periodUnit: 'WEEK',
              periodNumberOfUnits: 1,
              cycles: 1,
            },
            subscriptionPeriod: 'P6M',
            discounts: [],
            defaultOption: null,
            subscriptionOptions: null,
            presentedOfferingIdentifier: null,
            presentedOfferingContext: null,
          },
          packageType: 'SIX_MONTH' as PACKAGE_TYPE,
          identifier: '$rc_six_month',
        },
      ],
      sixMonth: {
        packageType: 'SIX_MONTH' as PACKAGE_TYPE,
        product: {
          description: 'Profitez de l’expérience Baobab sans limite !',
          introPrice: {
            periodNumberOfUnits: 1,
            price: 0,
            periodUnit: 'WEEK',
            priceString: '0,00 €',
            period: 'P1W',
            cycles: 1,
          },
          price: 39.49000000000001,
          pricePerMonth: 6.58,
          discounts: [],
          defaultOption: null,
          subscriptionOptions: null,
          presentedOfferingIdentifier: null,
          presentedOfferingContext: null,
          priceString: '39,49 €',
          pricePerWeekString: '1,51 €',
          identifier: 'premium_semi_annual',
          productType: 'AUTO_RENEWABLE_SUBSCRIPTION' as PRODUCT_TYPE,
          pricePerWeek: 1.51,
          currencyCode: 'EUR',
          pricePerYear: 78.98,
          pricePerYearString: '78,98 €',
          title: 'Premium semi annuel',
          subscriptionPeriod: 'P6M',
          productCategory: 'SUBSCRIPTION' as PRODUCT_CATEGORY,
          pricePerMonthString: '6,58 €',
        },
        offeringIdentifier: 'premium_semi_annual',
        presentedOfferingContext: {
          placementIdentifier: null,
          targetingContext: null,
          offeringIdentifier: 'premium_semi_annual',
        },
        identifier: '$rc_six_month',
      },
      lifetime: null,
      threeMonth: null,
      twoMonth: null,
      annual: null,
      monthly: null,
      weekly: null,
      identifier: 'premium_semi_annual',
    },
    annual_and_monthly_without_trial: {
      availablePackages: [
        {
          identifier: '$rc_annual',
          packageType: 'ANNUAL' as PACKAGE_TYPE,
          product: {
            productType: 'AUTO_RENEWABLE_SUBSCRIPTION' as PRODUCT_TYPE,
            title: 'Abonnement annuel',
            pricePerWeek: 1.27,
            discounts: [],
            defaultOption: null,
            subscriptionOptions: null,
            presentedOfferingIdentifier: null,
            presentedOfferingContext: null,
            priceString: '65,99 €',
            pricePerWeekString: '1,27 €',
            description: 'Profitez de l’expérience Baobab sans limite !',
            price: 65.99,
            productCategory: 'SUBSCRIPTION' as PRODUCT_CATEGORY,
            pricePerYear: 65.99,
            subscriptionPeriod: 'P1Y',
            introPrice: null,
            pricePerMonthString: '5,50 €',
            identifier: 'premium_annual_without_trial',
            pricePerMonth: 5.5,
            pricePerYearString: '65,99 €',
            currencyCode: 'EUR',
          },
          presentedOfferingContext: {
            placementIdentifier: null,
            offeringIdentifier: 'annual_and_monthly_without_trial',
            targetingContext: null,
          },
          offeringIdentifier: 'annual_and_monthly_without_trial',
        },
        {
          identifier: '$rc_monthly',
          packageType: 'MONTHLY' as PACKAGE_TYPE,
          product: {
            priceString: '7,99 €',
            discounts: [],
            defaultOption: null,
            subscriptionOptions: null,
            presentedOfferingIdentifier: null,
            presentedOfferingContext: null,
            pricePerWeek: 1.84,
            identifier: 'premium_without_trial',
            pricePerMonthString: '7,99 €',
            pricePerWeekString: '1,84 €',
            productCategory: 'SUBSCRIPTION' as PRODUCT_CATEGORY,
            price: 7.99,
            subscriptionPeriod: 'P1M',
            description: 'Profitez de l’expérience Baobab sans limite !',
            pricePerMonth: 7.99,
            productType: 'AUTO_RENEWABLE_SUBSCRIPTION' as PRODUCT_TYPE,
            title: 'Abonnement mensuel',
            pricePerYearString: '95,88 €',
            pricePerYear: 95.88,
            currencyCode: 'EUR',
            introPrice: null,
          },
          offeringIdentifier: 'annual_and_monthly_without_trial',
          presentedOfferingContext: {
            placementIdentifier: null,
            offeringIdentifier: 'annual_and_monthly_without_trial',
            targetingContext: null,
          },
        },
      ],
      monthly: {
        identifier: '$rc_monthly',
        packageType: 'MONTHLY' as PACKAGE_TYPE,
        product: {
          price: 7.99,
          pricePerMonth: 7.99,
          title: 'Abonnement mensuel',
          pricePerYearString: '95,88 €',
          description: 'Profitez de l’expérience Baobab sans limite !',
          pricePerWeek: 1.84,
          pricePerMonthString: '7,99 €',
          discounts: [],
          defaultOption: null,
          subscriptionOptions: null,
          presentedOfferingIdentifier: null,
          presentedOfferingContext: null,
          productType: 'AUTO_RENEWABLE_SUBSCRIPTION' as PRODUCT_TYPE,
          identifier: 'premium_without_trial',
          introPrice: null,
          subscriptionPeriod: 'P1M',
          priceString: '7,99 €',
          pricePerYear: 95.88,
          currencyCode: 'EUR',
          pricePerWeekString: '1,84 €',
          productCategory: 'SUBSCRIPTION' as PRODUCT_CATEGORY,
        },
        offeringIdentifier: 'annual_and_monthly_without_trial',
        presentedOfferingContext: {
          offeringIdentifier: 'annual_and_monthly_without_trial',
          placementIdentifier: null,
          targetingContext: null,
        },
      },
      annual: {
        identifier: '$rc_annual',
        product: {
          discounts: [],
          defaultOption: null,
          subscriptionOptions: null,
          presentedOfferingIdentifier: null,
          presentedOfferingContext: null,
          identifier: 'premium_annual_without_trial',
          priceString: '65,99 €',
          pricePerWeekString: '1,27 €',
          description: 'Profitez de l’expérience Baobab sans limite !',
          price: 65.99,
          introPrice: null,
          pricePerMonth: 5.5,
          pricePerYear: 65.99,
          pricePerWeek: 1.27,
          productType: 'AUTO_RENEWABLE_SUBSCRIPTION' as PRODUCT_TYPE,
          title: 'Abonnement annuel',
          pricePerMonthString: '5,50 €',
          currencyCode: 'EUR',
          subscriptionPeriod: 'P1Y',
          pricePerYearString: '65,99 €',
          productCategory: 'SUBSCRIPTION' as PRODUCT_CATEGORY,
        },
        offeringIdentifier: 'annual_and_monthly_without_trial',
        packageType: 'ANNUAL' as PACKAGE_TYPE,
        presentedOfferingContext: {
          targetingContext: null,
          placementIdentifier: null,
          offeringIdentifier: 'annual_and_monthly_without_trial',
        },
      },
      lifetime: null,
      threeMonth: null,
      twoMonth: null,
      sixMonth: null,
      weekly: null,
      identifier: 'annual_and_monthly_without_trial',
      serverDescription: 'Annual and monthly offers without trial',
      metadata: {},
    },
    annual_and_monthly: {
      monthly: {
        packageType: 'MONTHLY' as PACKAGE_TYPE,
        offeringIdentifier: 'annual_and_monthly',
        product: {
          identifier: 'premium',
          pricePerYear: 95.88,
          pricePerYearString: '95,88 €',
          priceString: '7,99 €',
          currencyCode: 'EUR',
          productCategory: 'SUBSCRIPTION' as PRODUCT_CATEGORY,
          productType: 'AUTO_RENEWABLE_SUBSCRIPTION' as PRODUCT_TYPE,
          title: 'Abonnement mensuel',
          subscriptionPeriod: 'P1M',
          discounts: [],
          defaultOption: null,
          subscriptionOptions: null,
          presentedOfferingIdentifier: null,
          presentedOfferingContext: null,
          description: 'Profitez de l’expérience Baobab sans limite !',
          price: 7.99,
          pricePerMonth: 7.99,
          pricePerWeek: 1.84,
          pricePerWeekString: '1,84 €',
          pricePerMonthString: '7,99 €',
          introPrice: {
            period: 'P1W',
            periodNumberOfUnits: 1,
            cycles: 1,
            periodUnit: 'WEEK',
            price: 0,
            priceString: '0,00 €',
          },
        },
        identifier: '$rc_monthly',
        presentedOfferingContext: {
          offeringIdentifier: 'annual_and_monthly',
          placementIdentifier: null,
          targetingContext: null,
        },
      },
      identifier: 'annual_and_monthly',
      annual: {
        identifier: '$rc_annual',
        offeringIdentifier: 'annual_and_monthly',
        presentedOfferingContext: {
          offeringIdentifier: 'annual_and_monthly',
          targetingContext: null,
          placementIdentifier: null,
        },
        product: {
          currencyCode: 'EUR',
          pricePerMonth: 5.5,
          productCategory: 'SUBSCRIPTION' as PRODUCT_CATEGORY,
          productType: 'AUTO_RENEWABLE_SUBSCRIPTION' as PRODUCT_TYPE,
          introPrice: {
            period: 'P1W',
            price: 0,
            priceString: '0,00 €',
            periodUnit: 'WEEK',
            periodNumberOfUnits: 1,
            cycles: 1,
          },
          pricePerWeek: 1.27,
          pricePerWeekString: '1,27 €',
          title: 'Abonnement annuel',
          price: 65.99,
          subscriptionPeriod: 'P1Y',
          pricePerYear: 65.99,
          description: 'Profitez de l’expérience Baobab sans limite !',
          discounts: [],
          defaultOption: null,
          subscriptionOptions: null,
          presentedOfferingIdentifier: null,
          presentedOfferingContext: null,
          identifier: 'premium_annual',
          priceString: '65,99 €',
          pricePerYearString: '65,99 €',
          pricePerMonthString: '5,50 €',
        },
        packageType: 'ANNUAL' as PACKAGE_TYPE,
      },
      lifetime: null,
      threeMonth: null,
      twoMonth: null,
      sixMonth: null,
      weekly: null,
      metadata: {},
      serverDescription: 'Annual and monthly offer',
      availablePackages: [
        {
          offeringIdentifier: 'annual_and_monthly',
          packageType: 'ANNUAL' as PACKAGE_TYPE,
          presentedOfferingContext: {
            targetingContext: null,
            placementIdentifier: null,
            offeringIdentifier: 'annual_and_monthly',
          },
          product: {
            discounts: [],
            defaultOption: null,
            subscriptionOptions: null,
            presentedOfferingIdentifier: null,
            presentedOfferingContext: null,
            productCategory: 'SUBSCRIPTION' as PRODUCT_CATEGORY,
            currencyCode: 'EUR',
            introPrice: {
              periodNumberOfUnits: 1,
              cycles: 1,
              priceString: '0,00 €',
              periodUnit: 'WEEK',
              period: 'P1W',
              price: 0,
            },
            pricePerMonth: 5.5,
            identifier: 'premium_annual',
            description: 'Profitez de l’expérience Baobab sans limite !',
            price: 65.99,
            pricePerWeek: 1.27,
            pricePerYear: 65.99,
            pricePerMonthString: '5,50 €',
            title: 'Abonnement annuel',
            pricePerYearString: '65,99 €',
            subscriptionPeriod: 'P1Y',
            priceString: '65,99 €',
            pricePerWeekString: '1,27 €',
            productType: 'AUTO_RENEWABLE_SUBSCRIPTION' as PRODUCT_TYPE,
          },
          identifier: '$rc_annual',
        },
        {
          offeringIdentifier: 'annual_and_monthly',
          presentedOfferingContext: {
            placementIdentifier: null,
            offeringIdentifier: 'annual_and_monthly',
            targetingContext: null,
          },
          identifier: '$rc_monthly',
          packageType: 'MONTHLY' as PACKAGE_TYPE,
          product: {
            subscriptionPeriod: 'P1M',
            description: 'Profitez de l’expérience Baobab sans limite !',
            priceString: '7,99 €',
            productCategory: 'SUBSCRIPTION' as PRODUCT_CATEGORY,
            productType: 'AUTO_RENEWABLE_SUBSCRIPTION' as PRODUCT_TYPE,
            pricePerWeek: 1.84,
            pricePerYear: 95.88,
            pricePerMonthString: '7,99 €',
            pricePerMonth: 7.99,
            title: 'Abonnement mensuel',
            discounts: [],
            defaultOption: null,
            subscriptionOptions: null,
            presentedOfferingIdentifier: null,
            presentedOfferingContext: null,
            introPrice: {
              priceString: '0,00 €',
              periodUnit: 'WEEK',
              periodNumberOfUnits: 1,
              cycles: 1,
              price: 0,
              period: 'P1W',
            },
            price: 7.99,
            pricePerWeekString: '1,84 €',
            identifier: 'premium',
            pricePerYearString: '95,88 €',
            currencyCode: 'EUR',
          },
        },
      ],
    },
  },
};

export const fakePurchasePackages = (
  placement?: RevenueCatPlacement,
): PurchasesPackage[] => {
  switch (placement) {
    case RevenueCatPlacement.PAYWALL_PANEL:
      return fakeRevenueCatOfferings.all['annual_and_monthly_without_trial']
        .availablePackages;
    case RevenueCatPlacement.ONBOARDING_PARENT:
      return fakeRevenueCatOfferings.all['premium_annual'].availablePackages;
    case RevenueCatPlacement.ONBOARDING_PARENT_TOO_EXPENSIVE:
      return fakeRevenueCatOfferings.all['annual_and_monthly']
        .availablePackages;
    case RevenueCatPlacement.ONBOARDING_PARENT_WITH_AMBASSADOR_CODE:
      return fakeRevenueCatOfferings.all['annual_and_monthly']
        .availablePackages;
    case RevenueCatPlacement.ONBOARDING_CHILD:
      return fakeRevenueCatOfferings.all['premium_annual'].availablePackages;
    default:
      return fakeRevenueCatOfferings.all['premium_annual'].availablePackages;
  }
};

export const fakeSemiAnnualPurchasePackages = (
  placement?: RevenueCatPlacement,
): PurchasesPackage[] => {
  switch (placement) {
    case RevenueCatPlacement.PAYWALL_PANEL:
      return fakeRevenueCatOfferings.all['annual_and_monthly_without_trial']
        .availablePackages;
    case RevenueCatPlacement.ONBOARDING_PARENT:
      return fakeRevenueCatOfferings.all['premium_semi_annual']
        .availablePackages;
    case RevenueCatPlacement.ONBOARDING_PARENT_TOO_EXPENSIVE:
      return fakeRevenueCatOfferings.all['semi_annual_and_monthly']
        .availablePackages;
    case RevenueCatPlacement.ONBOARDING_PARENT_WITH_AMBASSADOR_CODE:
      return fakeRevenueCatOfferings.all['semi_annual_and_monthly']
        .availablePackages;
    case RevenueCatPlacement.ONBOARDING_CHILD:
      return fakeRevenueCatOfferings.all['premium_semi_annual']
        .availablePackages;
    default:
      return fakeRevenueCatOfferings.all['premium_semi_annual']
        .availablePackages;
  }
};
