import { useTranslation } from 'react-i18next';
import PaywallReview from 'src/components/Paywall/PaywallScreenPay/PaywallReview';
import { useFlags } from 'src/contexts/FlagsContext';
import { PaywallScreen2Parent } from 'src/types/flags.types';
import StoreReviews from 'src/components/Reviews/StoreReviews';

const PaywallReviews = () => {
  const { paywall_screen2_parent } = useFlags();
  const { t } = useTranslation();

  const paywallWithReviews = [
    PaywallScreen2Parent.paywall_timeline_box_reviews,
    PaywallScreen2Parent.paywall_timeline_txt_reviews,
  ].includes(paywall_screen2_parent);

  if (!paywallWithReviews) {
    return null;
  }

  return (
    <div className={'flex flex-col gap-4 items-center'}>
      <StoreReviews />
      <div className={'flex flex-col gap-8'}>
        <PaywallReview content={t('paywall.reviews.review1')} />
        <PaywallReview content={t('paywall.reviews.review2')} />
        <PaywallReview content={t('paywall.reviews.review3')} />
      </div>
    </div>
  );
};

export default PaywallReviews;
