import { createPortal } from 'react-dom';
import { useEffect, useRef } from 'react';
import './XPHeaderCounter.scss';
import { loadLottieAnimation } from 'src/modules/animation/Lottie';
import animationDataXP from 'src/assets/animations/xp.json';

const XPHeaderCounterAnimation = ({
  newXP,
  counterElement,
}: {
  newXP: number;
  counterElement: HTMLDivElement | null;
}) => {
  const animationRef = useRef<HTMLDivElement | null>();

  useEffect(() => {
    playAnimation();
  }, [counterElement]);

  const playAnimation = () => {
    if (!animationRef.current || !counterElement || !newXP) {
      return;
    }

    const counterRefPosition = counterElement.getBoundingClientRect();
    const startPosition = [window.innerWidth / 2, window.innerHeight / 2, 0];
    const endPosition = [
      counterRefPosition.x + counterRefPosition.width / 2,
      counterRefPosition.y + counterRefPosition.height,
      0,
    ];

    const animation = loadLottieAnimation(
      animationRef.current,
      animationDataXP,
      {
        loop: false,
        variables: {
          In: [
            {
              type: 'position',
              value: startPosition,
            },
          ],
          Out: [{ type: 'position', value: endPosition }],
          Controller_eclairs_bg: [
            {
              type: 'position',
              value: startPosition,
            },
          ],
          Nombre_Xp: [
            {
              type: 'position',
              value: startPosition,
            },
            {
              type: 'number',
              value: newXP,
            },
          ],
        },
      },
    );
    animation.show();
    animation.play();
  };

  if (!newXP) {
    return null;
  }

  return createPortal(
    <div
      className={'fixed z-10 w-full h-full top-0 left-0 pointer-events-none'}
      data-testid={'xp-header-counter-animation'}
    >
      <div
        className={'w-full h-full'}
        ref={ref => {
          animationRef.current = ref;
          playAnimation();
        }}
      />
    </div>,
    document.body,
  );
};

export default XPHeaderCounterAnimation;
