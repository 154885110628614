import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { type ButtonProps } from 'antd';
import { useNotification } from 'src/contexts/NotificationContext';
import { PURCHASES_ERROR_CODE } from '@revenuecat/purchases-capacitor';
import { usePaywall } from 'src/contexts/PaywallContext';
import Button from 'src/components/common/designSystem/Button';
import { reportError } from 'src/modules/logs/Sentry';
import { RevenueCatOffering } from 'src/modules/purchase/purchase.constants';

const PayButton = ({
  className,
  onPay,
  offering = RevenueCatOffering.ANNUAL_WITHOUT_TRIAL,
  ...props
}: ButtonProps & { onPay?: () => void; offering?: RevenueCatOffering }) => {
  const { t } = useTranslation();
  const { isPremium, subscribePremium } = usePaywall();
  const [paying, setPaying] = useState(false);
  const { showError } = useNotification();

  useEffect(() => {
    if (isPremium && onPay) {
      onPay();
    }
  }, [isPremium, onPay]);

  const pay = async () => {
    try {
      setPaying(true);
      await subscribePremium(offering);
    } catch (error: any) {
      if (error.code === PURCHASES_ERROR_CODE.PURCHASE_CANCELLED_ERROR) {
        setPaying(false);
        return;
      }

      reportError('Fail to subscribe premium without trial', error);
      showError({
        error,
        message: t('paywall.error'),
      });
    }
    setPaying(false);
  };

  return (
    <Button
      loading={paying}
      onClick={pay}
      type={'primary'}
      data-testid="btn-pay-premium"
      {...props}
    />
  );
};

export default PayButton;
