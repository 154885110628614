import { PhotoStatus, usePhoto } from 'src/components/Piece/Photo/PhotoContext';
import Spinner from 'src/components/common/designSystem/Spinner';
import { Capacitor } from '@capacitor/core';
import GalleryButton from 'src/components/Piece/Photo/GalleryButton';

const PhotoPreview = () => {
  const { currentPhoto, previewRef, status } = usePhoto();

  const showCurrentPhoto =
    currentPhoto &&
    status !== PhotoStatus.CAMERA_READY &&
    status !== PhotoStatus.NONE;

  return (
    <div
      className={`flex-1 flex flex-row w-full overflow-hidden relative ${Capacitor.isNativePlatform() ? '' : 'bg-primary-darkest'}`}
    >
      {showCurrentPhoto ? (
        <div
          className={
            'absolute inset-0 flex justify-center items-center overflow-hidden bg-primary-darkest px-4 z-10'
          }
        >
          <div
            className={
              'rounded-2xl flex justify-center items-center overflow-hidden max-h-full max-w-full'
            }
          >
            <img src={currentPhoto} alt={'current photo'} />
          </div>
        </div>
      ) : null}

      <div className={'absolute z-10 left-9 bottom-5'}>
        <GalleryButton />
      </div>

      <div className={'h-full w-4 bg-primary-darkest'} />
      <div
        id="camera-preview"
        className={
          'flex-1 h-full relative rounded-2xl outline-primary-darkest outline-8 outline flex justify-center items-center'
        }
        ref={previewRef}
      >
        {status === PhotoStatus.TAKING_PHOTO ? (
          <div
            className={
              'absolute inset-0 flex justify-center items-center bg-primary-darkest'
            }
          >
            <Spinner type={'white'} size={'large'} />
          </div>
        ) : null}
        {status === PhotoStatus.NONE ? (
          <Spinner type={'primary'} size={'large'} />
        ) : null}
      </div>
      <div className={'h-full w-4 bg-primary-darkest'} />
    </div>
  );
};

export default PhotoPreview;
