import QuizXP from 'src/components/Quiz/QuizXP';
import { useTranslation } from 'react-i18next';
import Badge from 'src/components/common/designSystem/Badge';

const QuizResultXP = ({ xp, boost }: { xp: number; boost?: number }) => {
  const { t } = useTranslation();

  if (boost && boost > 1) {
    return (
      <div className={'relative'} data-testid="quiz-result-xp">
        <Badge text={`Boost x${boost}`} />
        <div className={'relative rounded-lg overflow-hidden p-1'}>
          <div className={'rounded-lg animated-border'}>
            <div
              className={'relative bg-white z-10 overflow-hidden rounded-md'}
            >
              <div
                className={
                  'flex flex-row justify-between w-full h-full items-center relative bg-white overflow-hidden rounded-md p-4 px-6'
                }
              >
                <div className={'text-base font-bold uppercase'}>
                  {t('quiz.result.xp')}
                </div>
                <QuizXP xp={xp} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      data-testid="quiz-result-xp"
      className={
        'w-full items-center bg-gradient-to-r from-paywall-darkest-from/40 to-paywall-darkest-to/40 rounded-xl p-1'
      }
    >
      <div
        className={
          'flex flex-row justify-between w-full h-full items-center bg-white rounded-lg p-4 px-6'
        }
      >
        <div className={'text-base font-bold uppercase'}>
          {t('quiz.result.xp')}
        </div>
        <QuizXP xp={xp} />
      </div>
    </div>
  );
};

export default QuizResultXP;
