import { useTranslation } from 'react-i18next';
import ButtonGroup from 'src/components/common/designSystem/ButtonGroup';
import { LessonSubject, PieceType } from 'src/types/lesson.types';

const LessonPieceTitle = ({
  type,
  onChange,
  subject,
}: {
  type: PieceType;
  subject: LessonSubject;
  onChange: (type: PieceType) => void;
}) => {
  const { t } = useTranslation();

  if (
    [
      LessonSubject.english,
      LessonSubject.german,
      LessonSubject.spanish,
      LessonSubject.french,
    ].includes(subject)
  ) {
    return (
      <div className={'font-extrabold text-base'}>
        {t('lessons.photo.title')}
      </div>
    );
  }

  return (
    <div className={'flex justify-center items-center h-10'}>
      <ButtonGroup
        options={[
          {
            label: t('lessons.newPiece.photo'),
            value: PieceType.IMAGE,
          },
          {
            label: t('lessons.newPiece.audio'),
            value: PieceType.AUDIO,
          },
        ]}
        selectedOption={type}
        onChange={onChange}
        size={'middle'}
      />
    </div>
  );
};

export default LessonPieceTitle;
