import { Trans, useTranslation } from 'react-i18next';
import Button from 'src/components/common/designSystem/Button';
import { useNavigate } from 'react-router-dom';
import PaywallCGU from 'src/components/Paywall/PaywallCGU';
import { openWhatsAppSupport } from 'src/modules/support/support';
import AnnualOffer from 'src/components/Paywall/Offers/AnnualOffer';
import IconMdiChevronLeft from '~icons/mdi/chevron-left';
import { RevenueCatOffering } from 'src/modules/purchase/purchase.constants';
import { useCurrentUser } from 'src/queries/user';
import AmbassadorCodeButton from 'src/components/Paywall/AmbassadorCode/AmbassadorCodeButton';
import OfferSelector from 'src/components/Paywall/Offers/OfferSelector';
import PaywallDescriptiveTimeline from 'src/components/Paywall/PaywallScreenPay/PaywallDescriptiveTimeline';
import { SafeArea } from 'antd-mobile';
import PaywallCancelExplanation from 'src/components/Paywall/PaywallScreenPay/PaywallCancelExplanation';

const PaywallScreenPayFull = ({
  showMensualOffer,
  onValidateAmbassadorCode,
  onPay,
  paying,
  selectedOffer,
  onSelectAnnualOffer,
  onSelectMonthlyOffer,
}: {
  showMensualOffer: boolean;
  onValidateAmbassadorCode: () => void;
  onPay: () => void;
  paying: boolean;
  selectedOffer: RevenueCatOffering.ANNUAL | RevenueCatOffering.MONTHLY;
  onSelectAnnualOffer: () => void;
  onSelectMonthlyOffer: () => void;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: user } = useCurrentUser();

  if (!user) {
    return null;
  }

  return (
    <div className={'flex flex-col p-5 gap-6'}>
      <div className={'flex flex-row justify-start items-center'}>
        <div
          className={'flex-1 flex justify-start items-center'}
          onClick={() => navigate(-1)}
        >
          <IconMdiChevronLeft fontSize={30} />
        </div>
      </div>

      <div
        className={
          'font-extrabold text-xl text-structural-darkest text-left text-balance'
        }
      >
        <Trans i18nKey="paywall.pay.title">
          <span
            className={
              'bg-gradient-to-r bg-clip-text text-transparent from-paywall-darkest-from to-paywall-darkest-to'
            }
          >
            Essai gratuit
          </span>{' '}
          de Baobab
        </Trans>
      </div>

      <PaywallDescriptiveTimeline />

      {showMensualOffer ? (
        <OfferSelector
          selectedOffer={selectedOffer}
          onSelectAnnualOffer={onSelectAnnualOffer}
          onSelectMonthlyOffer={onSelectMonthlyOffer}
        />
      ) : (
        <AnnualOffer />
      )}

      <PaywallCancelExplanation />

      {showMensualOffer ? null : (
        <AmbassadorCodeButton onValidate={onValidateAmbassadorCode} />
      )}

      <Button
        data-testid="btn-contact-us"
        type={'link'}
        size={'small'}
        onClick={openWhatsAppSupport}
      >
        {t(`paywall.pay.${user.user_type}.contactUs`)}
      </Button>

      <PaywallCGU />

      <div className={'w-full h-20'} />

      <div
        className={
          'flex flex-col gap-2 bg-white p-5 fixed bottom-0 left-0 right-0'
        }
      >
        <Button
          data-testid={'btn-pay-trial'}
          type={'primary'}
          onClick={onPay}
          loading={paying}
        >
          {t('paywall.pay.button')}
        </Button>

        <SafeArea position="bottom" />
      </div>
    </div>
  );
};

export default PaywallScreenPayFull;
