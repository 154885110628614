import axios from 'axios';
import {
  type QueryClient,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { type Page } from 'src/types';
import { lessonIsProcessing } from 'src/utils/lessonsUtils';
import { logAnalyticsEvent } from 'src/modules/analytics/Amplitude';
import { Lesson, LessonToCreate } from 'src/types/lesson.types';
import { Question } from 'src/types/quiz.types';

export const useGetLessons = ({
  toRevise,
  subjectMatters,
  enabled,
}: {
  toRevise?: boolean;
  subjectMatters?: string[];
  enabled?: boolean;
} = {}) => {
  return useInfiniteQuery<Page<Lesson>>({
    queryKey: ['lessons', { toRevise, subjectMatters }],
    queryFn: async ({ queryKey, pageParam = 1 }) => {
      const { toRevise: toReviseKey, subjectMatters: subjectMattersKey } =
        queryKey[1] as { toRevise?: boolean; subjectMatters?: string[] };
      const { data } = await axios.get('/api/lessons', {
        params: {
          page: pageParam,
          to_revise: toReviseKey ? true : null,
          subject_matters: subjectMattersKey ?? null,
        },
      });
      return data;
    },
    enabled,
    getNextPageParam: lastPage => lastPage.page + 1,
  });
};

export const invalidateGetLessonsQueries = async (queryClient: QueryClient) => {
  // Invalidate indexes for all lessons
  await queryClient.resetQueries({
    predicate: query =>
      query.queryKey[0] === 'lessons' && typeof query.queryKey[1] !== 'number',
  });
};

export const useGetLesson = (
  lessonId?: number | string,
  { enabled = true }: { enabled?: boolean } = {},
) => {
  return useQuery<Lesson>({
    queryKey: ['lessons', Number(lessonId)],
    enabled: !!lessonId && enabled,
    refetchInterval: lessonData => {
      const isProcessing = lessonIsProcessing(lessonData);
      return isProcessing ? 5000 : false;
    },
  });
};

export const useCreateLesson = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      lesson: Pick<LessonToCreate, 'title' | 'subject_matter'> & {
        is_test?: boolean;
      },
    ) => {
      const { data } = await axios.post<Lesson>('/api/lessons', lesson);
      return data;
    },
    onSuccess: newLesson => {
      queryClient.setQueriesData(['lessons', newLesson.id], newLesson);
      invalidateGetLessonsQueries(queryClient);
      logAnalyticsEvent('lesson_created', {
        lessonId: newLesson.id,
        lessonSubject: newLesson.subject_matter,
        lessonTitle: newLesson.title,
      });
    },
  });
};

export const useUpdateLesson = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (lesson: Partial<Lesson>) => {
      const { data } = await axios.put(`/api/lessons/${lesson.id}`, lesson);
      return data;
    },
    onSuccess: lesson => {
      invalidateGetLessonsQueries(queryClient);
      const lessonData: Lesson | undefined = queryClient.getQueryData([
        'lessons',
        Number(lesson.id),
      ]);
      if (!lessonData) return;

      queryClient.setQueryData(['lessons', Number(lesson.id)], lesson);
    },
  });
};

export const useDeleteLesson = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (lessonId: number) => {
      await axios.delete(`/api/lessons/${lessonId}`);
    },
    onSuccess: async () => {
      await invalidateGetLessonsQueries(queryClient);
    },
  });
};

export const useStartLessonAnalysis = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (lessonId: number) => {
      await axios.put(`/api/lessons/${lessonId}/start_analysis`);
    },
    onSuccess: (_data, lessonId) => {
      queryClient.invalidateQueries(['lessons', lessonId]);
    },
  });
};

export type QuestionsPayload = {
  created_at: string;
  id: number;
  lesson_id: number;
  lesson_smartbit_id: number;
  question_payload: Omit<Question, 'id'>;
  user_flag: string;
};

export const useUpdateQuestion = () => {
  return useMutation({
    mutationFn: async ({
      lessonId,
      question,
    }: {
      lessonId: number;
      question: Partial<QuestionsPayload> & { id: number };
    }) => {
      const { data } = await axios.put(
        `/api/lessons/${lessonId}/questions/${question.id}`,
        question,
      );
      return data as QuestionsPayload;
    },
  });
};

export const useGetChildLessons = ({ childId }: { childId: number }) => {
  return useInfiniteQuery<Page<Lesson>>({
    queryKey: ['lessons', { childId }],
    queryFn: async ({ pageParam = 1 }) => {
      const { data } = await axios.get(`/api/users/${childId}/lessons`, {
        params: {
          page: pageParam,
        },
      });
      return data;
    },
    getNextPageParam: lastPage => lastPage.page + 1,
  });
};
