import LessonLink from 'src/components/LessonDetail/LessonLink';
import { useTranslation } from 'react-i18next';
import { useLesson } from 'src/components/LessonDetail/LessonContext';
import { LessonSubject } from 'src/types/lesson.types';
import BaoQuizResultStars from 'src/components/Quiz/Result/BaoQuizResultStars';

const LessonBaoQuiz = () => {
  return <div className={'w-9 h-9 text-4xl'}>🤪</div>;
};
const LessonBaocabulaireQuizButton = () => {
  const { t } = useTranslation();
  const { lesson } = useLesson();

  if (
    ![
      LessonSubject.english,
      LessonSubject.german,
      LessonSubject.spanish,
    ].includes(lesson.subject_matter) ||
    !lesson.latest_baocabulaire_quiz_id
  ) {
    return null;
  }

  const maxScoreNormalQuiz = lesson.latest_quiz?.max_grade;

  const maxScore = lesson.latest_baocabulaire_quiz?.max_grade;

  const disabled = !maxScoreNormalQuiz || maxScoreNormalQuiz < 80;

  return (
    <LessonLink
      title={
        <div className={'flex flex-row justify-between items-center gap-2'}>
          <div>{t('lessons.details.baocabulaire_quiz')}</div>
          {maxScore ? (
            <div className={'flex flex-row gap-1 h-6 pr-2'}>
              <BaoQuizResultStars grade={maxScore} />
            </div>
          ) : null}
        </div>
      }
      link={`/lessons/${lesson.id}/quizzes/${lesson.latest_baocabulaire_quiz_id}`}
      IconComponent={LessonBaoQuiz}
      data-testid={'link-baocabulaire-quiz'}
      disabled={disabled}
      disabledDescription={t('lessons.details.bao_quiz_disabled')}
    />
  );
};

export default LessonBaocabulaireQuizButton;
