export enum RevenueCatPlacement {
  ONBOARDING_PARENT = 'onboarding_parent',
  ONBOARDING_CHILD = 'onboarding_child',
  ONBOARDING_PARENT_WITH_AMBASSADOR_CODE = 'onboarding_parent_with_ambassador_code',
  ONBOARDING_PARENT_TOO_EXPENSIVE = 'onboarding_parent_too_expensive',
  PAYWALL_PANEL = 'paywall_panel',
}

export const MONTHLY_ENTITLEMENT_ID = 'premium';
export const MONTHLY_ENTITLEMENT_ID_WITHOUT_TRIAL = 'premium_without_trial';
export const SEMI_ANNUAL_ENTITLEMENT_ID = 'premium_semi_annual';
export const ANNUAL_ENTITLEMENT_ID = 'premium_annual';
export const ANNUAL_ENTITLEMENT_ID_WITHOUT_TRIAL =
  'premium_annual_without_trial';
