import { Trans, useTranslation } from 'react-i18next';
import Button from 'src/components/common/designSystem/Button';
import { useNavigate } from 'react-router-dom';
import PaywallCGU from 'src/components/Paywall/PaywallCGU';
import { openWhatsAppSupport } from 'src/modules/support/support';
import IconMdiChevronLeft from '~icons/mdi/chevron-left';
import { RevenueCatPlacement } from 'src/modules/purchase/purchase.constants';
import { useCurrentUser } from 'src/queries/user';
import AmbassadorCodeButton from 'src/components/Paywall/AmbassadorCode/AmbassadorCodeButton';
import OfferSelector from 'src/components/Paywall/Offers/OfferSelector';
import PaywallDescriptiveTimeline from 'src/components/Paywall/PaywallScreenPay/PaywallDescriptiveTimeline';
import PaywallCancelExplanation from 'src/components/Paywall/PaywallScreenPay/PaywallCancelExplanation';
import { useState } from 'react';
import { PurchasesPackage } from '@revenuecat/purchases-capacitor';
import { useNotification } from 'src/contexts/NotificationContext';
import Footer from 'src/components/common/designSystem/Footer';
import PaywallReviews from 'src/components/Paywall/PaywallScreenPay/PaywallReviews';
import ShowDeviceInfoButton from 'src/components/Paywall/ShowDeviceInfo';

const PaywallScreenPayFull = ({
  onValidateAmbassadorCode,
  ambassadorCodeValid,
  onPay,
  paying,
  placement,
}: {
  onValidateAmbassadorCode: () => void;
  ambassadorCodeValid: boolean;
  onPay: (purchasesPackage: PurchasesPackage) => void;
  paying: boolean;
  placement?: RevenueCatPlacement;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: user } = useCurrentUser();
  const [selectedPurchasePackage, setSelectedPurchasePackage] =
    useState<PurchasesPackage>();
  const { showError } = useNotification();

  if (!user) {
    return null;
  }

  const handlePay = async () => {
    if (!selectedPurchasePackage) {
      showError({
        message: t('paywall.noPackageSelected'),
      });
      return;
    }
    onPay(selectedPurchasePackage);
  };

  return (
    <>
      <div className={'flex flex-col p-5 gap-6 overflow-auto'}>
        <div className={'flex flex-row justify-start items-center'}>
          <div
            className={'flex-1 flex justify-start items-center'}
            onClick={() => navigate(-1)}
          >
            <IconMdiChevronLeft fontSize={30} />
          </div>
        </div>

        <div
          className={
            'font-extrabold text-xl text-structural-darkest text-left text-balance'
          }
        >
          <Trans i18nKey="paywall.pay.title">
            <span
              className={
                'bg-gradient-to-r bg-clip-text text-transparent from-paywall-darkest-from to-paywall-darkest-to'
              }
            >
              Essai gratuit
            </span>{' '}
            de Baobab
          </Trans>
        </div>

        <div className={'flex flex-col gap-10'}>
          <PaywallDescriptiveTimeline
            purchasesPackage={selectedPurchasePackage}
          />

          <OfferSelector
            placement={placement}
            onSelectPackage={setSelectedPurchasePackage}
            selectedPackage={selectedPurchasePackage}
          />

          <PaywallCancelExplanation />

          <PaywallReviews />

          <div className={'flex flex-col gap-4'}>
            {ambassadorCodeValid ? null : (
              <AmbassadorCodeButton onValidate={onValidateAmbassadorCode} />
            )}

            <Button
              data-testid="btn-contact-us"
              type={'link'}
              size={'small'}
              onClick={openWhatsAppSupport}
            >
              {t(`paywall.pay.${user.user_type}.contactUs`)}
            </Button>

            <PaywallCGU />
            <ShowDeviceInfoButton />
          </div>
        </div>
      </div>
      <Footer>
        <Button
          data-testid={'btn-pay-trial'}
          type={'primary'}
          onClick={handlePay}
          loading={paying}
        >
          {t('paywall.pay.button')}
        </Button>
      </Footer>
    </>
  );
};

export default PaywallScreenPayFull;
