import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUpdateUser } from 'src/queries/users';
import { useNotification } from 'src/contexts/NotificationContext';
import OnboardingTitle from 'src/components/Onboarding/OnboardingTitle';
import Spinner from 'src/components/common/designSystem/Spinner';
import RadioList from 'src/components/common/designSystem/RadioList';
import { reportError } from 'src/modules/logs/Sentry';
import { SchoolGroup, SchoolLevel } from 'src/types/user.types';

const ChildSchoolLevelStep = ({
  childId,
  onFinish,
  isMe,
  schoolGroup,
}: {
  childId: number;
  onFinish: () => void;
  isMe?: boolean;
  schoolGroup: SchoolGroup;
}) => {
  const { t } = useTranslation();
  const { showError } = useNotification();
  const tNamespace = isMe
    ? 'onboarding.child.school_level'
    : 'onboarding.parent.child.school_level';

  const { mutateAsync: updateChild, isLoading: isUpdating } = useUpdateUser();
  const [selectedLevel, setSelectedLevel] = useState<SchoolLevel>();

  const save = async (level: string) => {
    try {
      const schoolLevel = level as SchoolLevel;
      setSelectedLevel(schoolLevel);
      await updateChild({
        id: childId,
        school_level: schoolLevel,
      });
      onFinish();
    } catch (error) {
      reportError('Fail to update child school level', error);
      showError({
        error,
        message: t(`${tNamespace}.fail`),
      });
    }
  };

  const getSchoolLevelOptions = () => {
    switch (schoolGroup) {
      case SchoolGroup.Primaire:
        return (
          <RadioList
            selected={selectedLevel}
            onSelect={save}
            options={[
              {
                type: 'value',
                value: SchoolLevel.CM1,
                label: t('users.schoolLevelCM1'),
              },
              {
                type: 'value',
                value: SchoolLevel.CM2,
                label: t('users.schoolLevelCM2'),
              },
            ]}
          />
        );
      case SchoolGroup.College:
        return (
          <RadioList
            selected={selectedLevel}
            onSelect={save}
            options={[
              {
                type: 'value',
                value: SchoolLevel.Sixieme,
                label: t('users.schoolLevel6'),
              },
              {
                type: 'value',
                value: SchoolLevel.Cinquieme,
                label: t('users.schoolLevel5'),
              },
              {
                type: 'value',
                value: SchoolLevel.Quatrieme,
                label: t('users.schoolLevel4'),
              },
              {
                type: 'value',
                value: SchoolLevel.Troisieme,
                label: t('users.schoolLevel3'),
              },
            ]}
          />
        );
      case SchoolGroup.Lycee:
        return (
          <RadioList
            selected={selectedLevel}
            onSelect={save}
            options={[
              {
                type: 'value',
                value: SchoolLevel.Seconde,
                label: t('users.schoolLevel2nde'),
              },
              {
                type: 'value',
                value: SchoolLevel.Premiere,
                label: t('users.schoolLevel1ere'),
              },
              {
                type: 'value',
                value: SchoolLevel.Terminale,
                label: t('users.schoolLevelTerm'),
              },
            ]}
          />
        );
    }
  };

  return (
    <div className="w-full bg-transparent flex-1 flex flex-col justify-between gap-3">
      <OnboardingTitle title={t(`${tNamespace}.title`)} />
      {getSchoolLevelOptions()}
      <div className={'h-16'}>
        {isUpdating ? <Spinner size="large" /> : null}
      </div>
    </div>
  );
};

export default ChildSchoolLevelStep;
