import { Navigate, useParams } from 'react-router-dom';
import { useGetLesson } from 'src/queries/lessons';
import Spinner from 'src/components/common/designSystem/Spinner';
import AddLessonPiece from 'src/components/Piece/AddLessonPiece';

const AddPieces = () => {
  const { lessonId } = useParams();
  const { data: lesson, isLoading } = useGetLesson(lessonId);

  if (isLoading) {
    return <Spinner className="w-full h-full" />;
  }

  if (!lesson) {
    return <Navigate to="404" />;
  }

  return <AddLessonPiece lesson={lesson} />;
};

export default AddPieces;
