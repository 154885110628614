import { useGetChildLessons } from 'src/queries/lessons';
import LessonParentCard from 'src/components/LessonsLists/LessonParentCard';
import Spinner from 'src/components/common/designSystem/Spinner';
import LessonsParentEmpty from 'src/components/LessonsLists/LessonsParentEmpty';
import { User } from 'src/types/user.types';

const ChildLessons = ({ child }: { child: User }) => {
  const { data: lessonsData, isLoading: lessonsLoading } = useGetChildLessons({
    childId: child.id,
  });

  if (lessonsLoading) {
    return <Spinner size="xl" className="w-full h-full" />;
  }

  if (!lessonsData) {
    return <LessonsParentEmpty child={child} />;
  }

  const allLessons = lessonsData.pages.map(page => page.items).flat();

  if (!allLessons.length) {
    return <LessonsParentEmpty child={child} />;
  }

  const lessonOrderByEvaluation = allLessons.sort((lessonA, lessonB) => {
    if (!lessonA.evaluation_date) return 1;
    if (!lessonB.evaluation_date) return -1;
    return (
      new Date(lessonA.evaluation_date).getTime() -
      new Date(lessonB.evaluation_date).getTime()
    );
  });

  return (
    <div className="flex flex-col gap-5">
      {lessonOrderByEvaluation.map(lesson => (
        <LessonParentCard lesson={lesson} child={child} key={lesson.id} />
      ))}
    </div>
  );
};

export default ChildLessons;
