import { useTranslation } from 'react-i18next';
import Spinner from 'src/components/common/designSystem/Spinner';
import BackArrowHeader from 'src/components/Header/BackArrowHeader';
import { SafeArea } from 'antd-mobile';

const LessonDetailProcessing = () => {
  const { t } = useTranslation();

  return (
    <>
      <SafeArea position="top" />
      <div className="w-full h-full flex flex-col items-start justify-between text-center">
        <BackArrowHeader />
        <div className={'flex flex-col items-center justify-center w-full'}>
          <Spinner size="xl" />
          <div className={'mt-4 text-balance'}>{t('lessons.processing')}</div>
        </div>
        <div />
      </div>
      <SafeArea position="bottom" />
    </>
  );
};

export default LessonDetailProcessing;
