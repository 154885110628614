import OneSignal, {
  NotificationWillDisplayEvent,
} from 'onesignal-cordova-plugin';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { App } from '@capacitor/app';
import { Capacitor, PluginListenerHandle } from '@capacitor/core';
import { reportError } from 'src/modules/logs/Sentry';
import {
  useGetUnreadNotifications,
  useReadNotification,
} from 'src/queries/notifications';
import { InAppNotification } from 'src/types/notification.types';

interface PushNotificationContextType {
  unreadNotifications: InAppNotification[];
  handleNotificationRead: (notificationId: number) => Promise<void>;
}

const PushNotificationContext =
  createContext<PushNotificationContextType | null>(null);

const PushNotificationProvider = ({ children }: PropsWithChildren) => {
  const [isAppActive, setIsAppActive] = useState<boolean>(true);
  const { data: notifications, refetch: refetchUnreadNotifications } =
    useGetUnreadNotifications({
      refetchInterval: 5000,
      notifyOnChangeProps: ['data'],
    });
  const { mutateAsync: readNotification } = useReadNotification();
  const [unreadNotifications, setUnreadNotifications] = useState<
    InAppNotification[]
  >([]);

  useEffect(() => {
    if (notifications) {
      setUnreadNotifications(
        notifications?.pages.flatMap(page =>
          page.items.filter(
            notification => !notification.shown_at && !notification.read_at,
          ),
        ) ?? [],
      );
    } else {
      setUnreadNotifications([]);
    }
  }, [notifications]);

  const handleForegroundWillDisplay = useCallback(
    (event: NotificationWillDisplayEvent) => {
      if (isAppActive) {
        const notification = event.getNotification();
        const { additionalData } = notification;
        if (additionalData?.hasOwnProperty('type')) {
          event.preventDefault();
          refetchUnreadNotifications().catch(error => {
            reportError('Failed to refetch unread notifications', error);
          });
        }
      }
    },
    [isAppActive],
  );

  useEffect(() => {
    if (!Capacitor.isNativePlatform()) {
      return;
    }

    let listener: PluginListenerHandle | undefined;
    App.addListener('appStateChange', state => {
      setIsAppActive(state.isActive);
    }).then(_listener => {
      listener = _listener;
    });

    return () => {
      if (listener) {
        listener.remove();
      }
    };
  }, []);

  useEffect(() => {
    if (!Capacitor.isNativePlatform()) {
      return;
    }

    OneSignal.Notifications.addEventListener(
      'foregroundWillDisplay',
      handleForegroundWillDisplay,
    );

    return () => {
      OneSignal.Notifications.removeEventListener(
        'foregroundWillDisplay',
        handleForegroundWillDisplay,
      );
    };
  }, [handleForegroundWillDisplay]);

  const handleNotificationRead = useCallback(
    async (notificationId: number) => {
      setUnreadNotifications(unreadNotifications =>
        unreadNotifications.filter(
          notification => notification.id !== notificationId,
        ),
      );
      await readNotification(notificationId);
      await refetchUnreadNotifications();
    },
    [readNotification, refetchUnreadNotifications],
  );

  return (
    <PushNotificationContext.Provider
      value={{
        unreadNotifications,
        handleNotificationRead,
      }}
    >
      {children}
    </PushNotificationContext.Provider>
  );
};

export const usePushNotification = () => {
  const pushNotification = useContext(PushNotificationContext);
  if (!pushNotification) {
    throw new Error(
      'usePushNotification must be used within a PushNotificationContext',
    );
  }
  return pushNotification;
};

export default PushNotificationProvider;
