import { useEffect } from 'react';
import { usePushNotification } from 'src/contexts/PushNotificationContext';
import { InAppNotificationType } from 'src/types/notification.types';
import { useGetXPBooster } from 'src/queries/quiz/xp_boosters';
import { reportError } from 'src/modules/logs/Sentry';
import XPBoosterProgress from 'src/components/Streak/header/XPBoosterProgress';

const XPBoosterHeader = () => {
  const { unreadNotifications } = usePushNotification();
  const { data: xpBooster, refetch: refetchXPBooster } = useGetXPBooster();

  const handleXPBoosterNotification = async () => {
    try {
      await refetchXPBooster();
    } catch (error) {
      reportError('Failed to handle XP booster notification', error);
    }
  };

  useEffect(() => {
    if (unreadNotifications.length) {
      const unreadBoostXPNotification = unreadNotifications.find(
        notification =>
          notification.type === InAppNotificationType.XP_BOOSTER_ACTIVATED,
      );
      if (unreadBoostXPNotification) {
        handleXPBoosterNotification();
      }
    }
  }, [unreadNotifications]);

  if (!xpBooster) {
    return null;
  }
  return <XPBoosterProgress xpBooster={xpBooster} />;
};

export default XPBoosterHeader;
