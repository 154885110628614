import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import LayoutWithHeader from 'src/layouts/LayoutWithHeader';
import StreakHeaderCounter from 'src/components/Streak/header/StreakHeaderCounter';
import Footer from 'src/components/common/designSystem/Footer';

import PollCard from 'src/components/Poll/PollCard';
import InAppNotifications from 'src/components/Notifications/InAppNotifications';
import MainTabBar from 'src/components/Layout/MainTabBar';
import { Outlet } from 'react-router-dom';
import Header from 'src/components/Header/Header';
import LessonsList from 'src/components/LessonsLists/LessonsList';
import XPHeaderCounter from 'src/components/Streak/header/XPHeaderCounter';
import ContactSupportPanel from 'src/components/Contact/ContactSupportPanel';
import DiscoverChallengeCard from 'src/components/Challenge/DiscoverChallengeCard/DiscoverChallengeCard';
import TrialEndingToast from 'src/components/Paywall/TrialEndingToast';

const Container = ({ children }: { children: ReactNode }) => (
  <LayoutWithHeader
    className={'!py-0'}
    header={
      <Header
        title={
          <div className={'flex flex-row gap-2'}>
            <StreakHeaderCounter />
            <XPHeaderCounter />
          </div>
        }
      />
    }
  >
    {children}
  </LayoutWithHeader>
);

export const HomeTab = () => {
  const { t } = useTranslation();

  return (
    <div className={'flex flex-col flex-grow gap-8 mb-4'}>
      <PollCard />
      <DiscoverChallengeCard />
      <TrialEndingToast />
      <div>
        <div className={'text-2xl font-bold text-structural-darkest my-4'}>
          {t('home.reviews')}
        </div>
        <LessonsList />
      </div>
      <ContactSupportPanel />
    </div>
  );
};

const Home = () => {
  return (
    <div className={'relative w-full h-full'}>
      <Container>
        <Outlet />
        <Footer>
          <MainTabBar />
        </Footer>
      </Container>
      <InAppNotifications />
    </div>
  );
};

export default Home;
