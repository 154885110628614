export default {
  mySubjects: 'Mes matières',
  addLesson: 'Ajoute une nouvelle révision',
  geography: {
    label: 'Géographie',
  },
  history: {
    label: 'Histoire',
  },
  english: {
    label: 'Anglais',
  },
  spanish: {
    label: 'Espagnol',
  },
  german: {
    label: 'Allemand',
  },
  italian: {
    label: 'Italien',
  },
  mathematics: {
    label: 'Maths',
  },
  sciences: {
    label: 'SVT',
  },
  physics: {
    label: 'Physique Chimie',
  },
  french: {
    label: 'Français',
  },
  technology: {
    label: 'Technologie',
  },
};
