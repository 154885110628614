import QuizScore from 'src/components/Quiz/QuizScore';
import { Lesson } from 'src/types/lesson.types';
import { useGetChildQuizRuns } from 'src/queries/quiz/runs';
import { AutoFloatingPanel } from 'src/components/common/AutoFloatingPanel';
import Spinner from 'src/components/common/designSystem/Spinner';
import { QuizRun } from 'src/types/quiz.types';
import { User } from 'src/types/user.types';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

const LessonParentPanel = ({
  lesson,
  child,
  isOpen,
  onClose,
}: {
  lesson: Lesson;
  child: User;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <AutoFloatingPanel
      isOpen={isOpen}
      onClose={onClose}
      data-testid={'lesson-parent-panel'}
    >
      <div className={'flex flex-col gap-4 p-4'}>
        <div className={'text-balance font-bold text-base'}>
          {t('settings.users.lessons.quiz.runs.panel.title', {
            firstname: child.firstname,
          })}
        </div>
        <LessonParentPanelContent child={child} lesson={lesson} />
      </div>
    </AutoFloatingPanel>
  );
};

const LessonParentPanelContent = ({
  lesson,
  child,
}: {
  lesson: Lesson;
  child: User;
}) => {
  const { isLoading, data: quizRunsPages } = useGetChildQuizRuns(
    lesson.id,
    child.id,
  );

  if (isLoading) {
    return <Spinner size={'xl'} className="w-full h-full" />;
  }

  if (!quizRunsPages) {
    return null;
  }

  const quizRuns = quizRunsPages.pages.flatMap(page => page.items);

  return (
    <div className={'flex flex-col gap-4'}>
      {quizRuns.map((run: QuizRun) => (
        <div
          key={run.id}
          className={'flex flex-row items-center justify-between gap-2'}
        >
          <div>{dayjs(run.finished_at).format('DD MMM YYYY - HH:mm')}</div>
          <QuizScore score={run.grade} />
        </div>
      ))}
    </div>
  );
};

export default LessonParentPanel;
