import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AutoFloatingPanel } from 'src/components/common/AutoFloatingPanel';
import UserSwitch from 'src/components/Layout/UserSwitch';
import BuildInfoPanel from 'src/components/BuildInfo/BuildInfoPanel';
import PaywallMenuPanel from 'src/components/Paywall/PaywallMenuPanel';
import GameSettingsPanel from 'src/components/Profile/GameSettingsPanel';
import { useCurrentUser } from 'src/queries/user';
import { useGetUsers } from 'src/queries/users';
import UsersListIcon from 'src/images/users-list.svg?react';
import SubscriptionIcon from 'src/images/subscription.svg?react';
import InfoIcon from 'src/images/info.svg?react';
import SendIcon from 'src/images/send.svg?react';
import ShareIcon from 'src/images/share.svg?react';
import LogoutIcon from 'src/images/logout.svg?react';
import DeleteIcon from 'src/images/delete.svg?react';
import TimeIcon from 'src/images/time.svg?react';

import useDeleteMyAccount from 'src/hooks/useDeleteMyAccount';
import useLogout from 'src/hooks/useLogout';
import ProfileItem from 'src/components/Profile/ProfileItem';
import { openSupportMessenger } from 'src/modules/support/support';
import InviteParentMenuItem from 'src/components/Invitation/InviteParentMenuItem';
import useInviteFriend from 'src/components/Invitation/useInviteFriend';
import { UserType } from 'src/types/user.types';

const ProfileMenu = () => {
  const { t } = useTranslation();
  const { data: currentUser } = useCurrentUser();
  const { data: users } = useGetUsers();
  const { logout } = useLogout();
  const { deleteMyAccount } = useDeleteMyAccount();
  const { invite } = useInviteFriend();

  const [
    {
      isUserSwitchPanelOpen,
      isBuildPanelOpen,
      isPaywallPanelOpen,
      isGameSettingsPanelOpen,
    },
    setPanelState,
  ] = useState({
    isUserSwitchPanelOpen: false,
    isBuildPanelOpen: false,
    isPaywallPanelOpen: false,
    isGameSettingsPanelOpen: false,
  });
  const handleOpenUserSwitch = () => {
    setPanelState({
      isUserSwitchPanelOpen: true,
      isBuildPanelOpen: false,
      isPaywallPanelOpen: false,
      isGameSettingsPanelOpen: false,
    });
  };
  const closePanel = () => {
    setPanelState({
      isUserSwitchPanelOpen: false,
      isBuildPanelOpen: false,
      isPaywallPanelOpen: false,
      isGameSettingsPanelOpen: false,
    });
  };
  const handleOpenBuildPanel = () => {
    setPanelState({
      isUserSwitchPanelOpen: false,
      isBuildPanelOpen: true,
      isPaywallPanelOpen: false,
      isGameSettingsPanelOpen: false,
    });
  };
  const handleOpenPaywallPanel = () => {
    setPanelState({
      isUserSwitchPanelOpen: false,
      isBuildPanelOpen: false,
      isPaywallPanelOpen: true,
      isGameSettingsPanelOpen: false,
    });
  };
  const handleOpenGameSettingsPanel = () => {
    setPanelState({
      isUserSwitchPanelOpen: false,
      isBuildPanelOpen: false,
      isPaywallPanelOpen: false,
      isGameSettingsPanelOpen: true,
    });
  };

  const switchUserDisabled =
    (users?.filter(user => user.id !== currentUser?.id && user.firstname)
      .length ?? 0) === 0;

  return (
    <>
      <div
        className={
          'flex flex-col gap-6 text-left text-base text-structural-darkest'
        }
      >
        {!switchUserDisabled ? (
          <ProfileItem
            title={t('profile.switchUser')}
            onClick={handleOpenUserSwitch}
            data-testid="btn-switch-user"
            Icon={<UsersListIcon className={'fill-structural-darkest w-6'} />}
          />
        ) : null}

        <InviteParentMenuItem />
        <ProfileItem
          title={t('profile.shareApp')}
          onClick={() => {
            if (currentUser?.user_type === UserType.parent) {
              invite(t('invitations.card.messageParent'));
            } else {
              invite();
            }
          }}
          data-testid="btn-share-app"
          Icon={<ShareIcon className={'fill-structural-darkest w-6'} />}
        />

        <ProfileItem
          title={t('profile.subscription')}
          onClick={handleOpenPaywallPanel}
          data-testid="btn-subscription"
          Icon={<SubscriptionIcon className={'fill-structural-darkest w-6'} />}
        />
        {currentUser?.user_type === UserType.child && (
          <>
            <div className={'w-full px-6'}>
              <div className={'bg-structural-lighter h-[1px] w-full'} />
            </div>
            <ProfileItem
              title={t('profile.gameSettings.timer.title')}
              onClick={handleOpenGameSettingsPanel}
              data-testid="btn-game-settings"
              Icon={<TimeIcon className={'fill-structural-darkest w-6'} />}
            />
          </>
        )}
        <div className={'w-full px-6'}>
          <div className={'bg-structural-lighter h-[1px] w-full'} />
        </div>
        <ProfileItem
          title={t('profile.buildVersion')}
          onClick={handleOpenBuildPanel}
          data-testid="btn-build-info"
          Icon={<InfoIcon className={'fill-structural-darkest w-6'} />}
        />
        <ProfileItem
          title={t('profile.contactUs')}
          onClick={async () => {
            await openSupportMessenger();
          }}
          data-testid="btn-contact-us"
          Icon={<SendIcon className={'fill-structural-darkest w-6'} />}
        />
        <ProfileItem
          title={t('profile.logout')}
          onClick={logout}
          data-testid="btn-logout"
          Icon={<LogoutIcon className={'fill-structural-darkest w-6'} />}
        />
        <ProfileItem
          color={'red-darkest'}
          title={t('profile.deleteAccount')}
          onClick={deleteMyAccount}
          data-testid="btn-delete-account"
          Icon={<DeleteIcon className={`fill-red-darkest w-6`} />}
        />
      </div>
      <AutoFloatingPanel isOpen={isUserSwitchPanelOpen} onClose={closePanel}>
        <UserSwitch onSwitch={closePanel} />
      </AutoFloatingPanel>
      <BuildInfoPanel isOpen={isBuildPanelOpen} onClose={closePanel} />
      <PaywallMenuPanel isOpen={isPaywallPanelOpen} onClose={closePanel} />
      <GameSettingsPanel
        onClose={closePanel}
        isOpen={isGameSettingsPanelOpen}
      />
    </>
  );
};

export default ProfileMenu;
