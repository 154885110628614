import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from 'src/queries/user';
import { GameDifficulty } from 'src/types/user.types';
import RadioList from 'src/components/common/designSystem/RadioList';
import Button from 'src/components/common/designSystem/Button';
import { useUpdateGameDifficulty } from 'src/queries/game_settings';
import { AutoFloatingPanel } from '../common/AutoFloatingPanel';
import { useNotification } from 'src/contexts/NotificationContext';
import { reportError } from 'src/modules/logs/Sentry';

const GameSettingsPanel = ({
  onClose,
  isOpen,
}: {
  onClose: () => void;
  isOpen: boolean;
}) => {
  const { t } = useTranslation();
  const { data: currentUser } = useCurrentUser();
  const { mutateAsync: updateGameDifficulty, isLoading } =
    useUpdateGameDifficulty();
  const { showError } = useNotification();

  // Default to MEDIUM if not set
  const [selectedDifficulty, setSelectedDifficulty] = useState<GameDifficulty>(
    currentUser?.settings?.game_difficulty || GameDifficulty.MEDIUM,
  );

  const handleSave = async () => {
    try {
      await updateGameDifficulty(selectedDifficulty);
      onClose();
    } catch (error) {
      reportError('Failed to update game difficulty', error);
      showError({
        message: t('profile.gameSettings.timer.error'),
        error,
      });
    }
  };

  return (
    <AutoFloatingPanel isOpen={isOpen} onClose={onClose}>
      <div className="p-4 flex flex-col gap-4">
        <h2 className="text-xl font-bold text-left text-balance">
          {t('profile.gameSettings.timer.title')}
        </h2>
        <p className="text-left">
          {t('profile.gameSettings.timer.description')}
        </p>

        <RadioList
          selected={selectedDifficulty}
          onSelect={setSelectedDifficulty}
          options={[
            {
              type: 'value',
              value: GameDifficulty.NO_TIMER,
              label: t('profile.gameSettings.timer.noTimer'),
            },
            {
              type: 'value',
              value: GameDifficulty.EASY,
              label: t('profile.gameSettings.timer.easy'),
            },
            {
              type: 'value',
              value: GameDifficulty.MEDIUM,
              label: t('profile.gameSettings.timer.medium'),
            },
            {
              type: 'value',
              value: GameDifficulty.HARD,
              label: t('profile.gameSettings.timer.hard'),
            },
          ]}
        />

        <Button
          type="primary"
          onClick={handleSave}
          disabled={isLoading}
          loading={isLoading}
          data-testid="btn-save-game-settings"
        >
          {t('global.save')}
        </Button>
      </div>
    </AutoFloatingPanel>
  );
};

export default GameSettingsPanel;
