import lottie, { AnimationConfig } from 'lottie-web';

type LottieVariableConfig =
  | {
      type: 'position';
      value: number[];
    }
  | {
      type: 'opacity';
      value: number;
    }
  | {
      type: 'scale';
      value: number[];
    }
  | {
      type: 'rotation';
      value: number;
    }
  | {
      type: 'text';
      value: string;
    }
  | {
      type: 'number';
      value: number;
    };

export const loadLottieAnimation = (
  element: HTMLElement,
  animationData: any,
  config: Omit<AnimationConfig<'svg'>, 'container' | 'renderer'> & {
    variables?: Record<string, LottieVariableConfig[]>;
  },
) => {
  const { variables, ...lottieConfig } = config;

  const elementRect = element.parentElement?.getBoundingClientRect();
  const elementWidth = elementRect?.width || 0;
  const elementHeight = elementRect?.height || 0;

  if (variables) {
    const layers = animationData.layers;
    for (const key in variables) {
      for (const layer of layers) {
        if (layer.nm === key) {
          const variableConfigs = variables[key];
          for (const variableConfig of variableConfigs) {
            if (variableConfig.type === 'position') {
              layer.ks.p.k = variableConfig.value;
            } else if (variableConfig.type === 'opacity') {
              layer.ks.o.k = variableConfig.value;
            } else if (variableConfig.type === 'scale') {
              layer.ks.s.k = variableConfig.value;
            } else if (variableConfig.type === 'rotation') {
              layer.ks.r.k = variableConfig.value;
            } else if (variableConfig.type === 'text') {
              if (layer.t.d.k.length > 0) {
                layer.t.d.k[0].s.t = variableConfig.value;
              }
            } else if (variableConfig.type === 'number') {
              if (Array.isArray(layer.ef) && layer.ef.length > 0) {
                if (
                  Array.isArray(layer.ef[0].ef) &&
                  layer.ef[0].ef.length > 0
                ) {
                  layer.ef[0].ef[0].v.k = variableConfig.value;
                }
              }
            }
          }
        }
      }
    }
  }

  const animation = lottie.loadAnimation({
    container: element,
    renderer: 'svg',
    animationData,
    autoplay: false,
    rendererSettings: {
      viewBoxSize: `${elementWidth} ${elementHeight}`,
      preserveAspectRatio: 'xMidYMid meet',
      imagePreserveAspectRatio: 'xMidYMid meet',
      className: 'lottie-animation',
    },
    ...lottieConfig,
  });
  animation.hide();

  return animation;
};
