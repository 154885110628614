export default {
  schoolGroupPrimaire: 'Primaire',
  schoolGroupCollege: 'Collège',
  schoolGroupLycee: 'Lycée',
  // Primaire
  schoolLevelCP: 'CP',
  schoolLevelCE1: 'CE1',
  schoolLevelCE2: 'CE2',
  schoolLevelCM1: 'CM1',
  schoolLevelCM2: 'CM2',
  // College
  schoolLevel6: '6ème',
  schoolLevel5: '5ème',
  schoolLevel4: '4ème',
  schoolLevel3: '3ème',
  // Lycee
  schoolLevel2nde: 'Seconde',
  schoolLevel1ere: 'Première',
  schoolLevelTerm: 'Terminale',
  schoolLevelOther: 'Autre',
  userTypes: {
    parent: 'Compte parent',
    child: 'Compte enfant',
  },
  family: {
    mother: 'Maman',
    father: 'Papa',
    boy: 'Garçon',
    girl: 'Fille',
    other: 'N/A',
    otherLabel: 'Ne pas le communiquer',
  },
  objectives: {
    better_organisation: '💪 Améliorer mon organisation',
    better_grades: '🎉 Réussir mes évaluations',
    saving_time: '⌛️ Gagner du temps',
    first_place: '🥇 Être 1er.e de ma classe',
    other: '💬 Autre',

    easy_learning: '🚀 Faciliter l’apprentissage de mon enfant',
    more_autonomy: '🎯 Le rendre plus autonome dans ses devoirs',
    child_motivation: '🎮 Rendre les révisions motivantes',
    improve_rates: '🏆 L’aider à améliorer ses notes',
    child_confidence: '🤝 Lui donner confiance en lui',
    reduce_time: '⏳ Gagner du temps sur les devoirs',
  },
  deleteMyAccount: {
    title: 'Es-tu sûr.e de vouloir supprimer ton compte ?',
    description: 'Si tu supprimes ton compte, tu perdras toutes tes leçons',
    confirm: 'Supprimer',
  },
  logout: {
    title: 'Es-tu sûr.e de vouloir te déconnecter ?',
    confirm: 'Déconnexion',
  },
};
