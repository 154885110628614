import { RevenueCatOffering } from 'src/modules/purchase/purchase.constants';
import AnnualOffer from 'src/components/Paywall/Offers/AnnualOffer';
import MonthlyOffer from 'src/components/Paywall/Offers/MonthlyOffer';
import { useTranslation } from 'react-i18next';

const OfferSelector = ({
  onSelectMonthlyOffer,
  onSelectAnnualOffer,
  selectedOffer,
}: {
  selectedOffer: RevenueCatOffering;
  onSelectMonthlyOffer: () => void;
  onSelectAnnualOffer: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <div className={'flex flex-col gap-2 w-full'}>
      <div className={'cursor-pointer relative'} onClick={onSelectAnnualOffer}>
        <AnnualOffer
          isSelected={
            selectedOffer === RevenueCatOffering.ANNUAL_WITHOUT_TRIAL ||
            selectedOffer === RevenueCatOffering.ANNUAL
          }
          promo={t('paywall.panel.annual.threeMonthsFree')}
        />
      </div>
      <div className={'cursor-pointer relative'} onClick={onSelectMonthlyOffer}>
        <MonthlyOffer
          isSelected={
            selectedOffer === RevenueCatOffering.MONTHLY_WITHOUT_TRIAL ||
            selectedOffer === RevenueCatOffering.MONTHLY
          }
        />
      </div>
    </div>
  );
};

export default OfferSelector;
