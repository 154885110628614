import { createContext, ReactNode, useContext } from 'react';

import { Lesson } from 'src/types/lesson.types';
import { useGetLesson } from 'src/queries/lessons';
import Spinner from 'src/components/common/designSystem/Spinner';
import type { AxiosError } from 'axios';
import { Navigate } from 'react-router-dom';

export const LessonContext = createContext<{ lesson?: Lesson }>({});

export const LessonProvider = ({
  lessonId,
  children,
}: {
  lessonId: number;
  children: ReactNode;
}) => {
  const { data: lesson, isLoading, isError, error } = useGetLesson(lessonId);

  if (isLoading) {
    return <Spinner size={'xl'} className="w-full h-full" />;
  }

  if (isError && (error as AxiosError).status === 404) {
    return <Navigate to="404" />;
  }
  if (!lesson) {
    return <Navigate to="404" />;
  }

  return (
    <LessonContext.Provider value={{ lesson }}>
      {children}
    </LessonContext.Provider>
  );
};

export const useLesson = () => {
  const lessonContext = useContext(LessonContext);
  if (!lessonContext.lesson) {
    throw new Error('No LessonContext.Provider found when calling useLesson');
  }

  return lessonContext as { lesson: Lesson };
};
