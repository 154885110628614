import { AppTrackingTransparency } from 'capacitor-plugin-app-tracking-transparency';
import { reportError } from 'src/modules/logs/Sentry';

export const requestATTPermission = async (): Promise<void> => {
  try {
    await AppTrackingTransparency.requestPermission();
  } catch (error) {
    reportError('Error requesting ATT permission', error);
  }
};
