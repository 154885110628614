import { useInfiniteQuery, useQuery } from 'react-query';
import { Page } from 'src/types';
import axios from 'axios';
import { QuizRun, QuizRunAnswer } from 'src/types/quiz.types';

export const useGetQuizRuns = (lessonId: number | string) =>
  useInfiniteQuery<Page<QuizRun>>({
    queryKey: ['quiz_runs', Number(lessonId)],
    queryFn: async ({ queryKey }) => {
      const { data } = await axios.get(
        `/api/lessons/${queryKey[1] as number}/quiz_runs`,
      );
      return data;
    },
  });

export const useGetChildQuizRuns = (lessonId: number, childId: number) =>
  useInfiniteQuery<Page<QuizRun>>({
    queryKey: ['quiz_runs', lessonId, childId],
    queryFn: async ({ queryKey }) => {
      const { data } = await axios.get(
        `/api/lessons/${queryKey[1]}/quiz_runs/child/${queryKey[2]}`,
      );
      return data;
    },
  });

export const useGetLatestAnswers = (lessonId: number | string) =>
  useQuery<QuizRunAnswer[]>({
    queryKey: ['quiz_wrong_answers', Number(lessonId)],
    queryFn: async ({ queryKey }) => {
      const { data } = await axios.get<QuizRunAnswer[]>(
        `/api/lessons/${queryKey[1] as number}/quiz_runs/latest/answers`,
      );
      return data;
    },
  });

export const useGetLatestQuizRun = (lessonId: number | string) =>
  useQuery<QuizRun>({
    queryKey: ['latest_quiz_run', Number(lessonId)],
    queryFn: async ({ queryKey }) => {
      const { data } = await axios.get<QuizRun>(
        `/api/lessons/${queryKey[1] as number}/quiz_runs/latest`,
      );
      return data;
    },
  });
