import TakePhotoButton from 'src/components/Piece/Photo/TakePhotoButton';
import SendPhotoButton from 'src/components/Piece/Photo/SendPhotoButton';
import ClearPhotoButton from 'src/components/Piece/Photo/ClearPhotoButton';
import PhotoList from 'src/components/Piece/Photo/PhotoList';

const PhotoFooter = () => {
  return (
    <div
      className={
        'flex flex-row justify-between items-center p-4 h-28 gap-3 bg-primary-darkest'
      }
    >
      <PhotoList />
      <div className={'flex flex-row gap-3 justify-center items-center'}>
        <TakePhotoButton />
        <ClearPhotoButton />
        <SendPhotoButton />
      </div>
      <div className={'w-10'} />
    </div>
  );
};

export default PhotoFooter;
