import ChildSchoolGroupStep from 'src/components/Onboarding/ChildSchoolGroupStep';
import { SchoolGroup } from 'src/types/user.types';

const ParentChildSchoolGroupStep = ({
  onSelect,
  isLoading,
}: {
  onSelect: (schoolGroup: SchoolGroup) => void;
  isLoading?: boolean;
}) => {
  return <ChildSchoolGroupStep onSelect={onSelect} isLoading={isLoading} />;
};

export default ParentChildSchoolGroupStep;
