import { useTranslation } from 'react-i18next';
import LockIcon from 'src/images/lock.svg?react';
import NotificationsIcon from 'src/images/notifications.white.svg?react';
import StarIcon from 'src/images/star.svg?react';
import { usePaywall } from 'src/contexts/PaywallContext';
import dayjs from 'dayjs';

const PaywallDescriptiveTimeline = () => {
  const { t } = useTranslation();
  const { nbTrialDays } = usePaywall();

  return (
    <div className={'flex flex-col'}>
      <div className={'flex flex-col relative'}>
        <div
          className={
            'absolute rounded-t-full top-0 bottom-0 left-0 w-6 bg-gradient-to-t from-paywall-darkest-from to-paywall-darkest-to'
          }
        ></div>
        <div className={'flex flex-row relative gap-4 items-stretch'}>
          <div className={'px-1 py-2.5'}>
            <LockIcon className={'stroke-white w-4 h-auto'} />
          </div>
          <div className={'flex flex-col py-2'}>
            <div className={'font-bold text-base'}>
              {t('paywall.timeline.item1.title')}
            </div>
            <div className={'text-sm font-light'}>
              {t('paywall.timeline.item1.description')}
            </div>
          </div>
        </div>
        <div className={'flex flex-row relative gap-4 items-stretch'}>
          <div className={'px-1 py-2.5'}>
            <NotificationsIcon
              className={'fill-white stroke-transparent w-4 h-auto'}
            />
          </div>
          <div className={'flex flex-col py-2'}>
            <div className={'font-bold text-base'}>
              {t('paywall.timeline.item2.title')}
            </div>
            <div className={'text-sm font-light'}>
              {t('paywall.timeline.item2.description')}
            </div>
          </div>
        </div>
      </div>
      <div className={'flex flex-row relative gap-4 items-stretch'}>
        <div className={'flex flex-col relative items-stretch'}>
          <div
            className={
              'absolute top-0 bottom-0 left-0 w-6 rounded-b-full bg-gradient-to-t from-paywall-darkest-from/0 to-paywall-darkest-from/100'
            }
          ></div>
          <div
            className={
              'px-1 py-2.5 bg-paywall-darkest-from rounded-b-full relative'
            }
          >
            <StarIcon className={' stroke-white  w-4 h-auto'} />
          </div>
        </div>
        <div className={'flex flex-col py-2'}>
          <div className={'font-bold text-base'}>
            {t('paywall.timeline.item3.title', { nbTrialDays })}
          </div>
          <div className={'text-sm font-light'}>
            {t('paywall.timeline.item3.description', {
              trialEndDate: dayjs().add(nbTrialDays, 'days').format('DD MMMM'),
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaywallDescriptiveTimeline;
