import { Letter } from 'src/components/Quiz/WordPuzzleQuestion/QuizWordPuzzleQuestion';
import { Haptics } from '@capacitor/haptics';
import { ImpactStyle } from '@capacitor/haptics/dist/esm/definitions';

const commonCssClasses =
  'flex items-center justify-center rounded-lg w-12 h-12 border-2 border-2 border-primary-dark text-primary-darkest cursor-pointer text-2xl text-center font-semibold flex-initial';

const QuizWordPuzzleLetter = ({
  selectedLetters,
  letter,
  onClick,
}: {
  selectedLetters: Letter[];
  letter: Letter;
  onClick?: () => void;
}) => {
  const isSelected = selectedLetters.some(l => l.id === letter.id);
  const handleClick = () => {
    if (isSelected) {
      return;
    }
    if (onClick) {
      Haptics.impact({ style: ImpactStyle.Medium });
      onClick();
    }
  };

  return (
    <div
      onClick={handleClick}
      data-testid={`letter-${letter.letter.toLowerCase()}`}
      aria-disabled={isSelected}
      className={`${commonCssClasses} ${
        isSelected ? 'opacity-50' : onClick ? '' : 'opacity-30'
      } `}
    >
      {letter.letter.toUpperCase()}
    </div>
  );
};

export default QuizWordPuzzleLetter;
