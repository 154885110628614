import { useTranslation } from 'react-i18next';
import { Modal } from 'antd-mobile';

import { useDeleteFamilyUser } from 'src/queries/users';
import { Child } from 'src/types/user.types';
import { reportError } from 'src/modules/logs/Sentry';
import { useNotification } from 'src/contexts/NotificationContext';

const useDeleteChild = ({ child }: { child: Child }) => {
  const { t } = useTranslation();
  const { mutateAsync: deleteChildApi, isLoading } = useDeleteFamilyUser();
  const { showError } = useNotification();

  const confirmDelete = async () => {
    try {
      await deleteChildApi(child.uuid);
    } catch (error) {
      reportError(`Fail to delete child`, error);
      showError({
        message: t('login.errors.defaultError'),
        error,
      });
    }
  };

  const deleteChild = async () => {
    Modal.clear();
    Modal.show({
      closeOnMaskClick: true,
      title: t('profile.parent.children.delete.title'),
      content: (
        <div className={'text-center text-balance'}>
          {t('profile.parent.children.delete.description')}
        </div>
      ),
      closeOnAction: true,
      onAction: ({ key }) => {
        if (key === 'confirm') {
          confirmDelete();
        }
      },
      actions: [
        {
          key: 'confirm',
          text: (
            <span data-testid={'btn-confirm-delete-account'}>
              {t('profile.parent.children.delete.confirm')}
            </span>
          ),
          danger: true,
        },
        {
          key: 'cancel',
          text: t('global.cancel'),
          className: 'text-structural-darkest',
        },
      ],
    });
  };

  return { deleteChild, isLoading };
};

export default useDeleteChild;
