import QuizWordPuzzleSelectedLetters from './QuizWordPuzzleSelectedLetters';
import { WordPuzzleQuestion } from 'src/types/quiz.types';
import { Letter } from 'src/components/Quiz/WordPuzzleQuestion/QuizWordPuzzleQuestion';

const QuizWordPuzzlePhrase = ({
  question,
  selectedLetters,
  onClickLetter,
}: {
  question: WordPuzzleQuestion;
  selectedLetters: Letter[];
  onClickLetter: (index: number) => void;
}) => {
  return (
    <div className={'w-full flex items-center text-center justify-center'}>
      <div
        className={
          'w-full break-words text-center align-middle whitespace-normal'
        }
      >
        <QuizWordPuzzleSelectedLetters
          question={question}
          selectedLetters={selectedLetters}
          onClickLetter={onClickLetter}
        />
      </div>
    </div>
  );
};

export default QuizWordPuzzlePhrase;
