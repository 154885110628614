import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import OnboardingTitle from 'src/components/Onboarding/OnboardingTitle';
import Spinner from 'src/components/common/designSystem/Spinner';
import RadioList from 'src/components/common/designSystem/RadioList';
import { SchoolGroup } from 'src/types/user.types';

const ChildSchoolGroupStep = ({
  onSelect,
  isMe,
  isLoading,
}: {
  onSelect: (schoolGroup: SchoolGroup) => void;
  isMe?: boolean;
  isLoading?: boolean;
}) => {
  const { t } = useTranslation();
  const tNamespace = isMe
    ? 'onboarding.child.school_group'
    : 'onboarding.parent.child.school_group';

  const [selectedGroup, setSelectedGroup] = useState<SchoolGroup>();

  const handleSelect = (group: string) => {
    const schoolGroup = group as SchoolGroup;
    setSelectedGroup(schoolGroup);
    onSelect(schoolGroup);
  };

  return (
    <div className="w-full bg-transparent flex-1 flex flex-col justify-between gap-3">
      <OnboardingTitle title={t(`${tNamespace}.title`)} />
      <RadioList
        selected={selectedGroup}
        onSelect={handleSelect}
        options={[
          {
            type: 'value',
            value: SchoolGroup.Primaire,
            label: t('users.schoolGroupPrimaire'),
          },
          {
            type: 'value',
            value: SchoolGroup.College,
            label: t('users.schoolGroupCollege'),
          },
          {
            type: 'value',
            value: SchoolGroup.Lycee,
            label: t('users.schoolGroupLycee'),
          },
        ]}
      />
      <div className={'h-16'}>
        {isLoading ? <Spinner size="large" /> : null}
      </div>
    </div>
  );
};

export default ChildSchoolGroupStep;
