import StoreStarsIcon from 'src/images/store-stars.svg?react';
import LaurierLeftIcon from 'src/images/laurier-left.svg?react';
import LaurierRightIcon from 'src/images/laurier-right.svg?react';

const StoreReviews = () => {
  return (
    <div className={'flex flex-row gap-1'}>
      <LaurierLeftIcon />
      <div className={'flex flex-col gap-2 justify-center items-center'}>
        <div className={'text-3xl font-extrabold'}>4,7</div>
        <StoreStarsIcon />
        <div className={'text-base font-bold'}>+ de 380 avis</div>
      </div>
      <LaurierRightIcon />
    </div>
  );
};

export default StoreReviews;
