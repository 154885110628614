import { useMutation } from 'react-query';
import axios from 'axios';
import { PromoCode } from 'src/types/promo_code.types';

export const useVerifyAmbassadorCode = () => {
  return useMutation({
    mutationFn: async (code: string) => {
      const { data } = await axios.get<{
        is_valid: boolean;
        promo_codes: PromoCode[];
      }>(`/api/ambassador_codes/${code}`);
      return { isValid: data.is_valid, promoCodes: data.promo_codes };
    },
  });
};
