import { useEffect, useRef, useState } from 'react';
import { Progress } from 'antd';

import { QuestionStatus, useQuiz } from 'src/components/Quiz/QuizContext';
import { Lesson, LessonSubject } from 'src/types/lesson.types';
import { Question, QuestionType } from 'src/types/quiz.types';
import { useCurrentUser } from 'src/queries/user';
import { GameDifficulty } from 'src/types/user.types';

const timeIncrements = 100;

const getTimeAllowed = (question?: Question, lesson?: Lesson) => {
  if (!question) {
    return 0;
  }
  if (question.type === QuestionType.word_puzzle) {
    return 60 * 1000;
  }
  if (question.type === QuestionType.matching) {
    return question.correct.length * 10 * 1000;
  } else if (
    lesson?.subject_matter &&
    (lesson.subject_matter === LessonSubject.english ||
      lesson.subject_matter === LessonSubject.spanish ||
      lesson.subject_matter === LessonSubject.german ||
      lesson.subject_matter === LessonSubject.italian ||
      lesson.subject_matter === LessonSubject.french ||
      lesson.subject_matter === LessonSubject.technology)
  ) {
    return 30 * 1000;
  }
  return 20 * 1000;
};

/**
 * Adjusts the time allowed based on the user's game difficulty setting
 * - NO_TIMER: No timer (returns Infinity)
 * - EASY: 150% of the base time
 * - MEDIUM: 100% of the base time (default)
 * - HARD: 75% of the base time
 */
const adjustTimeByDifficulty = (
  baseTime: number,
  difficulty?: GameDifficulty,
): number => {
  if (!difficulty || difficulty === GameDifficulty.MEDIUM) {
    return baseTime; // Default - no adjustment
  }

  switch (difficulty) {
    case GameDifficulty.NO_TIMER:
      return Infinity; // No timer
    case GameDifficulty.EASY:
      return baseTime * 1.5; // 50% more time
    case GameDifficulty.HARD:
      return baseTime * 0.75; // 25% less time
    default:
      return baseTime;
  }
};

export default function QuizProgressBar() {
  const {
    status,
    onTimeout,
    currentQuestion,
    lesson,
    currentQuestionIndex,
    totalQuestions,
  } = useQuiz();
  const { data: currentUser } = useCurrentUser();
  const baseTimeAllowed = getTimeAllowed(currentQuestion, lesson);

  // Adjust time based on user's difficulty setting
  const timeAllowed = adjustTimeByDifficulty(
    baseTimeAllowed,
    currentUser?.settings?.game_difficulty,
  );

  const isTimerDisabled = timeAllowed === Infinity;
  const [timeLeft, setTimeLeft] = useState(timeAllowed);
  const currentTimeout = useRef<number | null>();
  const timeProgression = isTimerDisabled
    ? 100
    : (timeLeft / timeAllowed) * 100;

  useEffect(() => {
    stopTimeout();
    setTimeLeft(timeAllowed);
  }, [currentQuestion?.id, timeAllowed]);

  const stopTimeout = () => {
    if (!currentTimeout.current) {
      return;
    }
    clearTimeout(currentTimeout.current);
    currentTimeout.current = undefined;
  };

  useEffect(() => {
    // Skip timer if in NO_TIMER mode
    if (isTimerDisabled) {
      return;
    }

    if (status === QuestionStatus.WAITING) {
      if (timeLeft > 0) {
        stopTimeout();
        currentTimeout.current = window.setTimeout(() => {
          setTimeLeft(prev => prev - timeIncrements);
        }, timeIncrements);
      } else if (timeLeft === 0 && currentTimeout.current) {
        onTimeout();
      }
    }
  }, [timeLeft, onTimeout, status, isTimerDisabled]);

  useEffect(() => {
    return () => {
      stopTimeout();
    };
  }, []);

  return (
    <div
      className={'flex flex-col w-full gap-1'}
      data-testid="quiz-progress-bar"
    >
      <div
        className={
          'flex flex-row text-white justify-between font-semibold text-sm'
        }
      >
        <div>
          {currentQuestionIndex + 1}/{totalQuestions}
        </div>
        {!isTimerDisabled && (
          <div className={'text-sm'}>{Math.round(timeLeft / 1000)}s</div>
        )}
      </div>
      {!isTimerDisabled && (
        <Progress
          data-testid="quiz-progress-bar-progress"
          percent={timeProgression}
          showInfo={false}
          rootClassName={'h-1 leading-[0]'}
          size={['default', 4]}
          strokeColor={'white'}
          trailColor={'rgba(255,255,255,0.5)'}
          className="w-full"
        />
      )}
    </div>
  );
}
