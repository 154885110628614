import dayjs from 'dayjs';
import { Trans, useTranslation } from 'react-i18next';
import { usePaywall } from 'src/contexts/PaywallContext';
import { formatPrice } from 'src/utils/price';
import { PurchasesPackage } from '@revenuecat/purchases-capacitor';

const DuePricing = ({
  purchasePackage,
}: {
  purchasePackage?: PurchasesPackage;
}) => {
  const { t } = useTranslation();
  const { promoCodes } = usePaywall();

  if (!purchasePackage) {
    return null;
  }

  const promoToApply = promoCodes.find(promoCode =>
    promoCode.product_ids.includes(purchasePackage.product.identifier),
  );
  const nbTrialDays = purchasePackage.product.introPrice
    ? dayjs.duration(purchasePackage.product.introPrice.period).asDays()
    : 7;

  return (
    <div
      className={
        'flex flex-col gap-2 items-start text-base text-structural-darkest w-full'
      }
    >
      <div className={'flex flex-row justify-between items-center w-full'}>
        <div>
          {t('paywall.pay.paymentDate', {
            date: dayjs().add(nbTrialDays, 'day').format('DD MMMM YYYY'),
          })}
        </div>
        <div>
          {promoToApply
            ? formatPrice(promoToApply.price_eur)
            : purchasePackage.product.priceString}
        </div>
      </div>
      <div
        className={
          'flex flex-row justify-between items-center font-bold w-full'
        }
      >
        <div className={'flex flex-row items-center gap-1'}>
          <Trans
            i18nKey="paywall.pay.paymentToday"
            values={{
              nbTrialDays,
            }}
          >
            Dû aujourd’hui
            <span
              className={
                'text-xs bg-gradient-to-r bg-clip-text text-transparent from-paywall-darkest-from to-paywall-darkest-to'
              }
            >
              (7 jours gratuits)
            </span>
          </Trans>
        </div>
        <div>0 €</div>
      </div>
    </div>
  );
};

export default DuePricing;
