import { usePaywall } from 'src/contexts/PaywallContext';
import {
  PurchasesOfferings,
  PurchasesPackage,
} from '@revenuecat/purchases-capacitor';
import Offer from 'src/components/Paywall/Offers/Offer';
import { useEffect, useState } from 'react';
import { getOfferings } from 'src/modules/purchase/RevenueCat';
import { useNotification } from 'src/contexts/NotificationContext';
import Spinner from 'src/components/common/designSystem/Spinner';
import { reportError } from 'src/modules/logs/Sentry';
import { useTranslation } from 'react-i18next';

const MyOffer = () => {
  const { t } = useTranslation();
  const { activeSubscription } = usePaywall();
  const [offerings, setOfferings] = useState<PurchasesOfferings>();
  const [loadingOfferings, setLoadingOfferings] = useState(true);
  const { showError } = useNotification();

  useEffect(() => {
    const fetchOfferings = async () => {
      try {
        const offerings = await getOfferings();
        setOfferings(offerings);
      } catch (error) {
        reportError('Fail to fetch offerings', error);
        showError({
          error,
          message: t('error.http.default'),
        });
      }
      setLoadingOfferings(false);
    };

    fetchOfferings();
  }, []);

  if (loadingOfferings) {
    return <Spinner />;
  }

  if (!offerings) {
    return null;
  }

  const allPackages: PurchasesPackage[] = Object.values(offerings.all).flatMap(
    offering => offering.availablePackages,
  );
  const activePackage = allPackages.find(
    packageItem =>
      packageItem.product.identifier === activeSubscription?.identifier,
  );

  if (!activePackage) {
    return null;
  }

  return <Offer purchasesPackage={activePackage} isSelected />;
};

export default MyOffer;
