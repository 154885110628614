import { FC, type SVGProps } from 'react';

const PaywallDescription = ({
  title,
  description,
  IconComponent,
  color,
}: {
  title?: string;
  description?: string;
  IconComponent: FC<SVGProps<SVGSVGElement>>;
  color?: string;
}) => {
  return (
    <div className={'flex flex-row justify-center items-center gap-4'}>
      <div>
        <IconComponent
          className={`w-10 h-10 ${color ? `fill-${color}` : ''}`}
        />
      </div>
      <div className={'flex flex-col'}>
        {title ? (
          <div
            className={`font-bold text-base text-${color ?? 'structural-darker'}`}
          >
            {title}
          </div>
        ) : null}
        {description ? (
          <div
            className={`text-${color ?? 'structural-darker'} text-base text-balance`}
          >
            {description}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PaywallDescription;
