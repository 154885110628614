export const getModuleExternalId = (id?: string | number): string => {
  if (!id) {
    return '';
  }

  const isProd = import.meta.env.MODE === 'production';
  if (isProd) {
    return id.toString();
  }
  return `test_${id}`;
};
