import { useTranslation } from 'react-i18next';

const PaywallCancelExplanation = () => {
  const { t } = useTranslation();

  return (
    <div className={'flex flex-col gap-2 p-3 bg-structural-lighter rounded-xl'}>
      <div className={'font-bold text-xs'}>{t('paywall.pay.cancel.title')}</div>
      <div className={'flex flex-col gap-1 text-xs'}>
        <div>{t('paywall.pay.cancel.description1')}</div>
        <div>{t('paywall.pay.cancel.description2')}</div>
      </div>
    </div>
  );
};

export default PaywallCancelExplanation;
