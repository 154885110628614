import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import ArrowLeft from 'src/images/arrow-left.svg?react';
import PaywallMenuPanel from 'src/components/Paywall/PaywallMenuPanel';
import { usePaywall } from 'src/contexts/PaywallContext';
import dayjs from 'dayjs';
import { SafeArea } from 'antd-mobile';

const TrialEndingToast = () => {
  const { t } = useTranslation();
  const [{ showToast, showPanel }, setState] = useState({
    showToast: false,
    showPanel: false,
  });
  const { isPremium, trialEndsAt, isOutOfTrial } = usePaywall();

  useEffect(() => {
    if (!isPremium && dayjs(trialEndsAt) < dayjs().add(3, 'days')) {
      setState({ showToast: true, showPanel: false });
    } else {
      setState({ showToast: false, showPanel: false });
    }
  }, [isPremium, trialEndsAt]);

  const openPanel = () => {
    setState({ showToast: false, showPanel: true });
  };

  return (
    <>
      {showToast ? (
        <div className={'fixed z-50 bottom-20 px-4 left-0'}>
          <div
            onClick={openPanel}
            data-testid={'toast-trial-ending'}
            className={
              'bg-warning-neutral text-sm text-balance cursor-pointer rounded-xl overflow-hidden text-structural-darkest flex flex-row items-center gap-2 py-4 px-6'
            }
          >
            <div className={'flex flex-col gap-1'}>
              <div className={'font-bold'}>
                {t(
                  isOutOfTrial
                    ? 'paywall.trial.ended.title'
                    : 'paywall.trial.ending.title',
                )}
              </div>
              <div>{t('paywall.trial.ending.description')}</div>
            </div>
            <ArrowLeft className={'fill-structural-darkest'} />
          </div>
          <SafeArea position="bottom" />
        </div>
      ) : null}

      <PaywallMenuPanel
        isOpen={showPanel}
        onClose={() => setState({ showToast: false, showPanel: false })}
      />
    </>
  );
};

export default TrialEndingToast;
