import Spinner from 'src/components/common/designSystem/Spinner';
import { usePaywall } from 'src/contexts/PaywallContext';
import { useCallback, useEffect } from 'react';
import { logAnalyticsEvent } from 'src/modules/analytics/Amplitude';
import { useUpdateCurrentUser } from 'src/queries/user';
import { useNotification } from 'src/contexts/NotificationContext';
import { useTranslation } from 'react-i18next';
import PaywallScreenPayChild from 'src/components/Paywall/PaywallScreenPay/PaywallScreenPayChild';
import BlankWhiteLayout from 'src/layouts/BlankWhiteLayout';

const OnboardingChildPaywallPay = () => {
  const { t } = useTranslation();
  const { mutateAsync: saveCurrentUser, isLoading: isUpdating } =
    useUpdateCurrentUser();
  const { showError } = useNotification();
  const { isPremium } = usePaywall();

  useEffect(() => {
    logAnalyticsEvent('paywall_viewed', {
      type: 'onboarding_screen2_child',
    });
  }, []);

  const onContinue = useCallback(async () => {
    try {
      await saveCurrentUser({
        onboarding_completed: true,
      });
      logAnalyticsEvent('trial_started', {
        is_paid: true,
      });
      logAnalyticsEvent('onboarding_complete');
    } catch (error) {
      showError({
        error,
        message: t('error.http.default'),
      });
    }
  }, []);

  useEffect(() => {
    if (isPremium) {
      onContinue();
    }
  }, [isPremium, onContinue]);

  useEffect(() => {
    logAnalyticsEvent('paywall_viewed', {
      type: 'onboarding_screen2_child',
    });
  }, []);

  if (isUpdating) {
    return <Spinner className="w-full h-full" size="large" />;
  }

  return (
    <BlankWhiteLayout data-testid={'onboarding-paywall'}>
      <PaywallScreenPayChild />
    </BlankWhiteLayout>
  );
};

export default OnboardingChildPaywallPay;
