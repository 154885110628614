import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/common/designSystem/Button';
import { Share } from '@capacitor/share';
import { useCreateQuizChallenge } from 'src/queries/quiz';
import { useNotification } from 'src/contexts/NotificationContext';
import { logAnalyticsEvent } from 'src/modules/analytics/Amplitude';
import { Lesson } from 'src/types/lesson.types';
import { isCancelError } from 'src/utils/share';
import { reportError } from 'src/modules/logs/Sentry';
import { AutoFloatingPanel } from 'src/components/common/AutoFloatingPanel';

const QuizShareButton = ({
  quizId,
  lesson,
}: {
  quizId: number;
  lesson: Lesson;
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { mutateAsync: createChallenge, isLoading } = useCreateQuizChallenge();
  const { showError } = useNotification();

  const onClose = () => {
    setIsOpen(false);
  };

  const handleShare = async () => {
    try {
      const { link } = await createChallenge({ quizId });
      const { activityType } = await Share.share({
        title: t('quiz.result.share.message.title', {
          lessonTitle: lesson.title,
        }),
        text: t('quiz.result.share.message.description', {
          lessonTitle: lesson.title,
        }),
        url: link,
      });
      logAnalyticsEvent('challenge_friend', {
        activity_type: activityType,
      });
      onClose();
    } catch (error) {
      if (isCancelError(error)) {
        return;
      }
      reportError('Fail to share challenge link', error);
      showError({
        message: t('quiz.result.share.error'),
        error,
      });
    }
  };

  return (
    <>
      <Button
        type="default"
        data-testid="btn-share-quiz"
        loading={isLoading}
        onClick={() => setIsOpen(true)}
      >
        {t('quiz.result.share.button')}
      </Button>
      <AutoFloatingPanel
        isOpen={isOpen}
        onClose={onClose}
        data-testid={'panel-share-quiz'}
      >
        <div className={'flex items-start flex-col gap-5 p-6 text-left'}>
          <div className={'text-7xl'}>🎮</div>
          <div className={'font-bold text-2xl'}>
            {t('quiz.result.share.panel.title')}
          </div>
          <div className={'text-base mb-2 flex flex-col gap-2'}>
            <div>{t('quiz.result.share.panel.description1')}</div>
            <div>{t('quiz.result.share.panel.description2')}</div>
          </div>
          <div className={'flex flex-col gap-2 w-full'}>
            <Button
              data-testid="btn-confirm-share-quiz"
              type={'primary'}
              onClick={handleShare}
              loading={isLoading}
            >
              {t('quiz.result.share.panel.button')}
            </Button>
          </div>
        </div>
      </AutoFloatingPanel>
    </>
  );
};

export default QuizShareButton;
