import { useEffect, useState } from 'react';
import {
  InAppNotification,
  InAppNotificationType,
} from 'src/types/notification.types';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/common/designSystem/Button';
import TransparentModal from 'src/components/common/designSystem/TransparentModal';
import { MilestoneType } from 'src/types/milestone.types';
import MilestoneCard from 'src/components/Milestones/MilestoneCard';
import { hasDoneOnboardingFirstLesson } from 'src/utils/onboarding';

const InAppMilestoneReachedNotification = ({
  notification,
  onRead,
}: {
  notification: InAppNotification & {
    type: InAppNotificationType.MILESTONE_REACHED;
  };
  onRead: () => void;
}) => {
  const { t } = useTranslation();
  const params = notification.params;
  const type: MilestoneType = params.milestone_key;
  const onboardingDone = hasDoneOnboardingFirstLesson();
  const [isOpened, setIsOpened] = useState(onboardingDone);

  const onClose = () => {
    onRead();
    setIsOpened(false);
  };

  useEffect(() => {
    setIsOpened(onboardingDone);
  }, [notification.id]);

  return (
    <TransparentModal
      isOpen={isOpened}
      onClose={onClose}
      data-testid={'modal-streak'}
      footer={
        <Button
          type={'primary'}
          data-testid={'btn-milestone-continue'}
          onClick={onClose}
        >
          {type === MilestoneType.daily_streaks
            ? t('milestones.streak.continue')
            : t('milestones.continue')}
        </Button>
      }
    >
      <MilestoneCard type={type} count={params.count} />
    </TransparentModal>
  );
};

export default InAppMilestoneReachedNotification;
