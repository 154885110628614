import { Lesson, PieceType } from 'src/types/lesson.types';
import { useCreateLessonPiece } from 'src/queries/lesson_pieces';
import { useStartLessonAnalysis } from 'src/queries/lessons';
import { useState } from 'react';
import { logAnalyticsEvent } from 'src/modules/analytics/Amplitude';
import { useNavigate } from 'react-router-dom';
import PhotoContextProvider from 'src/components/Piece/Photo/PhotoContext';
import BlankLayout from 'src/layouts/BlankLayout';
import { projectColors } from 'src/styles/project-styles';
import { Style } from '@capacitor/status-bar/dist/esm/definitions';
import LessonPieceHeader from 'src/components/Piece/LessonPieceHeader';
import PhotoPreview from 'src/components/Piece/Photo/PhotoPreview';
import PhotoFooter from 'src/components/Piece/Photo/PhotoFooter';
import AnotherPhotoPanel from 'src/components/Piece/Photo/AnotherPhotoPanel';
import VoiceRecorderContextProvider from 'src/components/Piece/Audio/VoiceRecorderContext';
import BlankPrimaryLayout from 'src/layouts/BlankPrimaryLayout';
import LessonDetailVoiceRecorder from 'src/components/Piece/Audio/LessonDetailVoiceRecorder';

const AddLessonPiece = ({ lesson }: { lesson: Lesson }) => {
  const navigate = useNavigate();
  const { mutateAsync: createLessonPiece } = useCreateLessonPiece();
  const { mutateAsync: startAnalysis } = useStartLessonAnalysis();
  const [type, setType] = useState<PieceType>(PieceType.IMAGE);

  const handleCreatePiece = async (
    blob: Blob,
    uri?: string,
    duration?: number,
  ) => {
    if (!lesson || !lesson.id) {
      return;
    }

    const piece = await createLessonPiece({
      lessonId: lesson.id,
      file: blob,
      uri,
      type,
    });
    await startAnalysis(lesson.id);

    if (type === PieceType.IMAGE) {
      logAnalyticsEvent('add_lesson-content', {
        lessonId: lesson.id,
        lessonPieceId: piece.id,
        lessonSubject: lesson.subject_matter,
        lessonTitle: lesson.title,
      });
    } else if (type === PieceType.AUDIO) {
      logAnalyticsEvent('add_lesson-content', {
        lessonId: lesson.id,
        lessonPieceId: piece.id,
        lessonSubject: lesson.subject_matter,
        lessonTitle: lesson.title,
        recordingDuration: duration,
      });
      navigate(`/lessons/${lesson.id}/pieces/${piece.id}/processing`, {
        replace: true,
      });
    }
  };

  if (type === PieceType.IMAGE) {
    return (
      <PhotoContextProvider onPhotoReady={handleCreatePiece}>
        <BlankLayout
          appBackground={'bg-transparent'}
          statusBar={{
            color: projectColors.primary.darkest,
            style: Style.Dark,
          }}
          appBackgroundFooter={'bg-primary-darkest'}
        >
          <div className="w-full h-full flex flex-col justify-between text-white overflow-hidden">
            <div className={'w-full bg-primary-darkest'}>
              <LessonPieceHeader
                type={type}
                subject={lesson.subject_matter}
                onChange={setType}
              />
            </div>
            <PhotoPreview />
            <PhotoFooter />
          </div>
          <AnotherPhotoPanel
            lessonId={lesson.id}
            hasEvaluationDate={Boolean(lesson?.evaluation_date)}
          />
        </BlankLayout>
      </PhotoContextProvider>
    );
  } else if (type === PieceType.AUDIO) {
    return (
      <VoiceRecorderContextProvider
        onRecordReady={(blob, duration, uri) =>
          handleCreatePiece(blob, uri, duration)
        }
      >
        <BlankPrimaryLayout>
          <div className="w-full h-full flex flex-col justify-between text-white">
            <LessonPieceHeader
              type={type}
              subject={lesson.subject_matter}
              onChange={setType}
            />
            <div className={'px-8 pb-8'}>
              <LessonDetailVoiceRecorder />
            </div>
          </div>
        </BlankPrimaryLayout>
      </VoiceRecorderContextProvider>
    );
  }

  return null;
};

export default AddLessonPiece;
