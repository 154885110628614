import Score100Icon from 'src/images/quiz/score100.svg?react';
import Score75Icon from 'src/images/quiz/score75.svg?react';
import Score50Icon from 'src/images/quiz/score50.svg?react';
import Score25Icon from 'src/images/quiz/score25.svg?react';
import Score0Icon from 'src/images/quiz/score0.svg?react';

const QuizScoreIcon = ({ score }: { score: number }) => {
  if (score >= 100) {
    return <Score100Icon />;
  }
  if (score >= 75) {
    return <Score75Icon />;
  }
  if (score >= 50) {
    return <Score50Icon />;
  }
  if (score >= 25) {
    return <Score25Icon />;
  }
  return <Score0Icon />;
};

const QuizScore = ({
  score,
  textColor = 'text-structural-darkest',
}: {
  score: number;
  textColor?: string;
}) => {
  return (
    <div
      data-testid={'quiz-score'}
      className={'flex flex-row items-center justify-center gap-2'}
    >
      <QuizScoreIcon score={score} />
      <div className={`text-base font-extrabold ${textColor} w-8`}>
        {score}%
      </div>
    </div>
  );
};

export default QuizScore;
