export default {
  startAQuiz: 'Démarrer un quiz',
  title: 'Quiz',
  resultTitle: 'Résultat du quiz',
  question: 'Question ',
  validateAnswer: "C'est ma réponse !",
  nextQuestion: 'Question suivante',
  quizOver: "C'est terminé ! 🎉",
  validate: 'Valider',
  goodAnswer: 'Bonne réponse !',
  wrongAnswer: 'Mauvaise réponse 😨',
  timeoutAnswer: 'Le temps est écoulé !',
  noQuestions: 'Aucune question trouvée',
  defaultGuideline: 'Quelle est ta réponse ?',
  flagQuestion: {
    buttonLabel: 'Signaler un problème',
    title: 'Un problème avec cette question ? 🤔',
    backToQuiz: 'Retour au quiz !',
    flagSent: 'Merci pour ton signalement ! On va y jeter un oeil ! 🙏',
  },
  quit: {
    title: 'Es-tu sûr•e de vouloir partir maintenant ?',
    title_boy: 'Es-tu sûr de vouloir partir maintenant ?',
    title_girl: 'Es-tu sûre de vouloir partir maintenant ?',
    title_other: 'Es-tu sûr•e de vouloir partir maintenant ?',
    description: 'Si tu arrêtes ton quiz, tu perdras ta progression.',
    quit: 'Arrêter mon quiz',
    cancel: 'Annuler',
  },
  questionFlagOptions: {
    wrongQuestion: "Problème dans l'énoncé",
    noCorrectAnswer: 'Réponse incorrecte',
    notInTheLesson: 'Pas dans mon cours',
    other: 'Autre',
  },
  result: {
    title: 'Résultats',
    greatWork: 'Bravo {{name}} !',
    greatWorkFeedback: 'Tu as obtenu le score de {{score}}%',
    restart: 'Recommencer le quiz',
    start: 'Commencer un quiz',
    score: 'Score',
    record: 'Mon record',
    xp: 'XP',
    newRecord: '👑 Nouveau record',
    newRecordDescription: 'Tu as battu ton propre record',
    share: {
      button: 'Défier un ami',
      panel: {
        title: 'Défie tes amis ou même ta classe !',
        description1: 'Tout le monde peut jouer !',
        description2: 'Ils feront ton quiz, et on verra qui est le boss ! 🏆',
        button: 'Envoyer le lien !',
      },
      error: 'Impossible de partager le quiz',
      message: {
        title: 'Défie-moi sur {{lessonTitle}} !',
        description:
          'Tu crois que tu peux me battre sur {{lessonTitle}} ? 💪😎',
      },
    },
  },
  failToUpdate: 'Impossible de mettre à jour le score du quiz',
  subjects: {
    start: 'Démarrer !',
    title: 'Teste tes connaissances en {{subject}}',
    selectLesson: 'Choisir une leçon',
    selectLessonDescription: 'Sur quelle leçon veux-tu t’entraîner?',
    noLessons: {
      title: 'Il n’y a pas de quiz !',
      description: 'Pour générer un quiz {{subject}}, ajoute une leçon.',
    },
  },
  openQuestion: {
    score: 'Score',
    bad: 'Pas mal',
    notBad: "C'est bien !",
    good: 'Super 🤩',
    noScore: 'Pas de score',
  },
  bao: {
    onboarding: {
      0: {
        message: 'Hey! Je vais te poser quelques questions sur ta leçon',
        button: 'Suivant',
      },
      1: {
        message: 'Donne moi tes réponses en étant le plus précis possible',
        button: 'Suivant',
      },
      2: {
        message: 'Gagne plein de points d’XP et tente de décrocher le 100% !',
        button: 'C’est parti !',
      },
    },
  },
  korige_les_fotes: {
    onboarding: {
      0: {
        message:
          'J’ai écris quelques phrases mais j’ai fais quelques fautes d’orthographe...',
        button: 'Suivant',
      },
      1: {
        message: 'Peux-tu corriger mes phrases pour que tout soit parfait ?',
        button: 'Suivant',
      },
      2: {
        message: 'Gagne plein de points d’XP et tente de décrocher le 100% !',
        button: 'C’est parti !',
      },
    },
    guideline: 'Corrige les fautes de Bao',
  },
  xp: {
    booster: {
      title: "Booster d'XP activé pour {{time}}",
    },
  },
};
