import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SubjectCard from 'src/components/Subject/SubjectCard';
import { allSubjects } from 'src/utils/constants';
import AddLessonModal from 'src/components/AddLesson/AddLessonModal';
import { LessonSubject, LessonSubjectType } from 'src/types/lesson.types';

export default function SubjectsBoxes() {
  const { t } = useTranslation();
  const [selectedSubject, setSelectedSubject] = useState<LessonSubject>();

  const onSubjectClick = (subject: LessonSubjectType) => {
    setSelectedSubject(subject.key);
  };
  const handleClose = () => {
    setSelectedSubject(undefined);
  };

  return (
    <>
      <div className="w-full mx-auto bg-white rounded-3xl relative">
        <div className="flex flex-col gap-4 p-4">
          <div>
            <div className="text-lg font-bold text-structural-darker">
              {t('subjects.addLesson')}
            </div>
          </div>
          <div className="flex flex-row items-start gap-x-4 no-margin-layout-overflow-x">
            {allSubjects.map(subject => {
              return (
                <SubjectCard
                  key={subject.key}
                  subject={subject.key}
                  onClick={() => {
                    onSubjectClick(subject);
                  }}
                />
              );
            })}
          </div>
        </div>
      </div>
      <AddLessonModal
        isOpen={Boolean(selectedSubject)}
        onClose={handleClose}
        subject={selectedSubject}
      />
    </>
  );
}
