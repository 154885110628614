import { useCurrentUser } from 'src/queries/user';
import { UserType } from 'src/types/user.types';
import ChildEmptyNotificationCard from 'src/components/Notifications/NotificationsList/NotificationCard/EmptyNotificationCard.child';
import ParentEmptyNotificationCard from 'src/components/Notifications/NotificationsList/NotificationCard/EmptyNotificationCard.parent';

const EmptyNotificationCard = () => {
  const { data: user } = useCurrentUser();

  if (!user) {
    return null;
  }

  if (user.user_type === UserType.child) {
    return <ChildEmptyNotificationCard />;
  }
  return <ParentEmptyNotificationCard />;
};

export default EmptyNotificationCard;
