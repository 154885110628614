export enum PaywallScreen2Parent {
  paywall_parent_v1 = 'paywall_parent_v1',
  paywall_parent_v2 = 'paywall_parent_v2',
  paywall_timeline_box = 'paywall_timeline_box',
  paywall_timeline_txt = 'paywall_timeline_txt',
  paywall_timeline_box_reviews = 'paywall_timeline_box_reviews',
  paywall_timeline_txt_reviews = 'paywall_timeline_txt_reviews',
  paywall_unlock = 'paywall_unlock',
}

export type FlagsType = {
  child_trial_store_paywall: boolean;
  display_video_paywall: boolean;
  parent_objective: boolean;
  paywall_screen2_parent: PaywallScreen2Parent;
};
