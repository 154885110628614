import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useCurrentUser } from 'src/queries/user';
import {
  CustomerInfo,
  PurchasesEntitlementInfo,
} from '@revenuecat/purchases-capacitor';
import {
  getActiveSubscription,
  logInAppPurchase,
  logOutInAppPurchase,
  subscribePremium as subscribePremiumApi,
} from 'src/modules/purchase/RevenueCat';
import dayjs from 'dayjs';
import PaywallPanel from 'src/components/Paywall/PaywallPanel';
import { logAnalyticsEvent } from 'src/modules/analytics/Amplitude';
import StartTrialPanel from 'src/components/Paywall/StartTrialPanel';
import { User } from 'src/types/user.types';
import { Location, useLocation } from 'react-router-dom';
import { reportError } from 'src/modules/logs/Sentry';
import { useFetchSubscriptions } from 'src/queries/subscriptions';
import { trackFacebookPixelEvent } from 'src/modules/analytics/FacebookPixel';
import { RevenueCatOffering } from 'src/modules/purchase/purchase.constants';
import { PromoCode } from 'src/types/promo_code.types';
import { uniqueBy } from 'remeda';

type PaywallType = {
  isPremium: boolean;
  nbTrialDays: number;
  isOutOfTrial: boolean;
  trialEndsAt?: string;
  activeSubscription?: PurchasesEntitlementInfo;
  managementURL?: string;
  subscribePremium: (
    offering: RevenueCatOffering,
  ) => Promise<{ customerInfo: CustomerInfo }>;
  showPaywall: () => void;
  hidePaywall: () => void;
  addPromoCodes: (promoCodes: PromoCode[]) => void;
  promoCodes: PromoCode[];
};

const PaywallContext = createContext<PaywallType | null>(null);

const DEFAULT_NB_TRIAL_DAYS = 7;
const BETA_TESTER_NB_TRIAL_DAYS = 30;

const PATHS_WITHOUT_PAYWALL = ['/terms-and-conditions', '/confidentialite'];
const ignorePaywall = (location: Location) => {
  return PATHS_WITHOUT_PAYWALL.some(path => location.pathname.includes(path));
};

const isOutOfTrialFn = (trial_ends_at?: string) => {
  return trial_ends_at ? dayjs(trial_ends_at) < dayjs() : false;
};
const isPremiumFn = (premium_ends_at?: string) => {
  return premium_ends_at ? dayjs(premium_ends_at) > dayjs() : false;
};

const PaywallProvider = ({ children }: PropsWithChildren) => {
  const [state, setState] = useState<{
    isOutOfTrial: boolean;
    isPremium: boolean;
    customerInfo?: CustomerInfo;
  }>({ isOutOfTrial: false, isPremium: false });
  const [isPaywallOpen, setIsPaywallOpen] = useState<boolean>(false);
  const [promoCodes, setPromoCodes] = useState<PromoCode[]>([]);
  const { data: user } = useCurrentUser();
  const { mutateAsync: refetchSubscriptions } = useFetchSubscriptions();

  const nbTrialDays = useRef<number>(DEFAULT_NB_TRIAL_DAYS);
  const location = useLocation();

  const initialize = useCallback(
    async (
      user?: Pick<User, 'id' | 'family_id'> & {
        premiumEndDate?: string;
      },
    ) => {
      if (!user) {
        try {
          await logOutInAppPurchase();
          setState(state => ({
            ...state,
            customerInfo: undefined,
          }));
        } catch (error) {
          reportError('Error login out from revenuecat', error);
        }
        return;
      }

      try {
        const customerInfo = await logInAppPurchase(user);
        if (customerInfo) {
          const latestExpirationDate = customerInfo?.latestExpirationDate;
          const premiumEndDate = user.premiumEndDate;
          if (premiumEndDate !== latestExpirationDate) {
            await refetchSubscriptions();
          }
        }

        setState(state => ({
          ...state,
          customerInfo,
        }));
      } catch (error) {
        reportError('Error login in revenuecat', error);
      }
    },
    [],
  );

  useEffect(() => {
    initialize(
      user?.id && user?.family_id
        ? {
            id: user?.id,
            family_id: user?.family_id,
            premiumEndDate: user?.family.premium_ends_at,
          }
        : undefined,
    );
  }, [initialize, user?.id, user?.family_id, user?.family.premium_ends_at]);

  const subscribePremium = useCallback(
    async (offering: RevenueCatOffering) => {
      const promoCode = promoCodes.find(
        promoCode => promoCode.offering_id === offering,
      );
      const result = await subscribePremiumApi(offering, promoCode);
      const { customerInfo } = result;
      setState(state => ({
        ...state,
        customerInfo,
      }));
      await refetchSubscriptions();
      const isTrial =
        offering === RevenueCatOffering.ANNUAL ||
        offering === RevenueCatOffering.MONTHLY;
      if (isTrial) {
        logAnalyticsEvent('trial_started');
        trackFacebookPixelEvent('StartTrial');
      }
      return result;
    },
    [promoCodes],
  );

  useEffect(() => {
    const isPremium = isPremiumFn(user?.family.premium_ends_at);
    const isOutOfTrial = !isPremium && isOutOfTrialFn(user?.trial_ends_at);
    nbTrialDays.current = DEFAULT_NB_TRIAL_DAYS;

    const isBetaTester =
      user?.created_at && dayjs(user.created_at).isBefore('2024-09-01');
    if (isBetaTester) {
      nbTrialDays.current = BETA_TESTER_NB_TRIAL_DAYS;
    }

    setState(state => ({
      ...state,
      isOutOfTrial,
      isPremium,
    }));
    if (isOutOfTrial && user?.onboarding_completed) {
      setIsPaywallOpen(true);
    } else {
      setIsPaywallOpen(false);
    }
  }, [
    user?.trial_ends_at,
    user?.family.premium_ends_at,
    user?.onboarding_completed,
    user?.created_at,
  ]);

  const showPaywall = () => {
    setIsPaywallOpen(true);
  };
  const hidePaywall = () => {
    setIsPaywallOpen(false);
  };

  const addPromoCodes = (newPromoCodes: PromoCode[]) => {
    setPromoCodes(promoCodes => {
      return uniqueBy(
        [...promoCodes, ...newPromoCodes],
        promoCode => promoCode.uuid,
      );
    });
  };

  return (
    <PaywallContext.Provider
      value={{
        isPremium: state.isPremium,
        nbTrialDays: nbTrialDays.current,
        isOutOfTrial: state.isOutOfTrial,
        trialEndsAt: user?.trial_ends_at,
        activeSubscription: getActiveSubscription(state?.customerInfo),
        managementURL: state?.customerInfo?.managementURL ?? undefined,
        subscribePremium,
        showPaywall,
        hidePaywall,
        addPromoCodes,
        promoCodes,
      }}
    >
      {children}
      <StartTrialPanel />
      <PaywallPanel
        isOpen={isPaywallOpen && !ignorePaywall(location)}
        onClose={() => setIsPaywallOpen(false)}
      />
    </PaywallContext.Provider>
  );
};

export const usePaywall = () => {
  const paywallContext = useContext(PaywallContext);
  if (!paywallContext) {
    throw new Error('usePaywall must be used within a PaywallContext');
  }
  return paywallContext;
};

export default PaywallProvider;
