import Button from './Button';
import type { SizeType } from 'antd/es/config-provider/SizeContext';

const ButtonGroup = <ValueType,>({
  options,
  selectedOption,
  onChange,
  size,
}: {
  options: {
    label: string;
    value: ValueType;
  }[];
  selectedOption: ValueType;
  onChange: (option: ValueType) => void;
  size?: SizeType;
}) => {
  return (
    <div className={'flex flex-row items-center justify-between'}>
      {options.map(option => (
        <Button
          key={String(option.value)}
          data-testid={`btn-${option.value}`}
          size={size}
          className={'min-w-20'}
          onClick={() => onChange(option.value)}
          type={selectedOption === option.value ? 'primary' : 'text'}
        >
          <span className={'text-white'}>{option.label}</span>
        </Button>
      ))}
    </div>
  );
};

export default ButtonGroup;
