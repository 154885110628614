import { LessonSmartbitQuestion } from 'src/types/lesson.types';

export enum QuizType {
  CLASSIC = 'CLASSIC',
  BAO = 'BAO',
  BAOCABULAIRE = 'BAOCABULAIRE',
  KORIGE_LES_FOTES = 'KORIGE_LES_FOTES',
}

export interface Quiz {
  id: number;
  lesson_id: number;
  type: QuizType;
  created_at: string;
  updated_at: string;
  max_grade?: number;
  xp: number;
}

export interface QuizWithQuestions extends Quiz {
  questions: { question: LessonSmartbitQuestion }[];
}

export interface QuizRun {
  id: number;
  user_id: number;
  quiz_id: number;
  grade: number;
  xp_won: number;
  xp_boost_multiplier?: number;
  is_record: boolean;
  started_at?: string;
  finished_at?: string;
  created_at?: string;
  updated_at?: string;
  quiz: Quiz;
}

export interface QuizRunAnswer {
  id: number;
  run_id: number;
  quiz_question_id: number;
  answer: {
    given_answer?: Answer;
    correct_answer:
      | MCQQuestion['correct']
      | MatchingQuestion['correct']
      | FillBlankQuestion['correct']
      | CompleteSentenceQuestion['correct']
      | WordPuzzleQuestion['correct']
      | OpenQuestion['answer'];
  };
  time_to_answer_ms: number;
  is_correct: boolean;
  question: {
    question: LessonSmartbitQuestion;
  };
  created_at: string;
  updated_at: string;
}

export interface FinishedQuizRun {
  id: number;
  date: Date;
  grade: number;
}

export enum QuestionType {
  mcq = 'mcq',
  matching = 'matching',
  fill_blank = 'fill_blank',
  complete_sentence = 'complete_sentence',
  word_puzzle = 'word_puzzle',
  open = 'open',
  korige_les_fotes = 'korige_les_fotes',
}

export type MCQQuestion = {
  type: QuestionType.mcq;
  id: number;
  guideline: string;
  statement: string;
  correct: string[];
  incorrect: string[];
};
export type MatchingQuestion = {
  type: QuestionType.matching;
  id: number;
  guideline: string;
  correct: [string, string][];
};
export type FillBlankQuestion = {
  type: QuestionType.fill_blank;
  id: number;
  guideline: string;
  statement: string;
  correct: string[];
  incorrect: string[][];
};
export type CompleteSentenceQuestion = {
  type: QuestionType.complete_sentence;
  id: number;
  guideline: string;
  statement: string;
  correct: string[];
  incorrect: string[];
};
export type WordPuzzleQuestion = {
  type: QuestionType.word_puzzle;
  id: number;
  question: string;
  correct: string;
};
export type OpenQuestion = {
  type: QuestionType.open;
  id: number;
  question: string;
  answer: string;
};
export type KorigeLesFotesQuestion = {
  type: QuestionType.korige_les_fotes;
  id: number;
  sentence_to_correct: string;
  correct_sentence: string;
};
export type Question =
  | MCQQuestion
  | MatchingQuestion
  | FillBlankQuestion
  | CompleteSentenceQuestion
  | WordPuzzleQuestion
  | OpenQuestion
  | KorigeLesFotesQuestion;
export type Answer = string | string[] | [string, string][];

export type QuizAnswer = {
  quiz_question_id: number;
  run_id: number;
  answer: {
    given_answer?: Answer;
    correct_answer:
      | MCQQuestion['correct']
      | MatchingQuestion['correct']
      | FillBlankQuestion['correct']
      | CompleteSentenceQuestion['correct']
      | WordPuzzleQuestion['correct']
      | OpenQuestion['answer']
      | KorigeLesFotesQuestion['correct_sentence'];
  };
  time_to_answer_ms: number;
  is_correct: boolean;
  score?: number;
  explanation?: string;
};

export type XPBooster = {
  id: number;
  uuid: string;
  start: string;
  end: string;
  boost_multiplier: number;
  created_at: string;
  updated_at: string;
};
