import LayoutWithHeader from 'src/layouts/LayoutWithHeader';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import OnboardingHeader from 'src/components/Onboarding/OnboardingHeader';
import StatusBar from 'src/components/common/StatusBar';
import { Style } from '@capacitor/status-bar/dist/esm/definitions';
import ParentChildSchoolGroupStep from 'src/components/Onboarding/ParentChildSchoolGroupStep';
import { SchoolGroup } from 'src/types/user.types';

const OnboardingParentChildSchoolGroup = () => {
  const navigate = useNavigate();
  const { childId } = useParams();
  const [searchParams] = useSearchParams();

  const onSelect = (schoolGroup: SchoolGroup) => {
    // Store the selected school group in search params for the next step
    const newParams = new URLSearchParams(searchParams);
    newParams.set('schoolGroup', schoolGroup);
    navigate(
      `/onboarding/parent/child/${childId}/school_level?${newParams.toString()}`,
    );
  };

  return (
    <LayoutWithHeader
      header={
        <OnboardingHeader
          step={4}
          from={`/onboarding/parent/child/${childId}/gender?${searchParams.toString()}`}
        />
      }
      statusBar={<StatusBar color="#FFFFFF" style={Style.Light} />}
      className={'bg-white'}
    >
      <ParentChildSchoolGroupStep onSelect={onSelect} />
    </LayoutWithHeader>
  );
};

export default OnboardingParentChildSchoolGroup;
