import OneSignal, { OSNotificationPermission } from 'onesignal-cordova-plugin';
import { Capacitor } from '@capacitor/core';
import { User } from 'src/types/user.types';
import { getModuleExternalId } from 'src/utils/external-id';

export const initOneSignal = (): void => {
  if (!Capacitor.isNativePlatform()) {
    return;
  }

  OneSignal.Debug.setLogLevel(6);
  OneSignal.initialize(import.meta.env.VITE_ONESIGNAL_APP_ID);
};

export const requestOneSignalPermission = async () => {
  if (!Capacitor.isNativePlatform()) {
    return;
  }

  return OneSignal.Notifications.requestPermission(true);
};

export const isNotificationNotDetermined = async () => {
  if (!Capacitor.isNativePlatform()) {
    return true;
  }

  return (
    (await OneSignal.Notifications.permissionNative()) ===
    OSNotificationPermission.NotDetermined
  );
};

export const loginOneSignal = (user: User): void => {
  if (!Capacitor.isNativePlatform()) {
    return;
  }

  OneSignal.login(getModuleExternalId(user.id));
  if (user.email) {
    OneSignal.User.addEmail(user.email);
  }
  OneSignal.User.addTags({ type: user.user_type });
};
