import { useNavigate } from 'react-router-dom';
import { logAnalyticsEvent } from 'src/modules/analytics/Amplitude';
import Button from 'src/components/common/designSystem/Button';
import { useTranslation } from 'react-i18next';

const AddChildButton = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onClick = async () => {
    logAnalyticsEvent('add_child_button_clicked');
    navigate(`/child/firstname`);
  };

  return (
    <Button type={'primary'} onClick={onClick} data-testid={'btn-add-child'}>
      <span>{t('settings.users.addUser')}</span>
    </Button>
  );
};

export default AddChildButton;
