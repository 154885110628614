import LayoutWithHeader from 'src/layouts/LayoutWithHeader';
import {
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import OnboardingHeader from 'src/components/Onboarding/OnboardingHeader';
import StatusBar from 'src/components/common/StatusBar';
import { Style } from '@capacitor/status-bar/dist/esm/definitions';
import ParentChildSchoolLevelStep from 'src/components/Onboarding/ParentChildSchoolLevelStep';
import { SchoolGroup } from 'src/types/user.types';

const OnboardingParentChildSchoolLevel = () => {
  const navigate = useNavigate();
  const { childId } = useParams();
  const [searchParams] = useSearchParams();
  const onNext = () => {
    navigate(
      `/onboarding/parent/child/${childId}/school_level/device?${searchParams.toString()}`,
    );
  };

  return (
    <LayoutWithHeader
      header={
        <OnboardingHeader
          step={5}
          from={`/onboarding/parent/child/${childId}/school_group?${searchParams.toString()}`}
        />
      }
      statusBar={<StatusBar color="#FFFFFF" style={Style.Light} />}
      className={'bg-white'}
    >
      <ParentChildSchoolLevelStep
        childId={Number(childId)}
        onFinish={onNext}
        schoolGroup={
          (searchParams.get('schoolGroup') as SchoolGroup) ||
          SchoolGroup.College
        }
      />
      <Outlet />
    </LayoutWithHeader>
  );
};

export default OnboardingParentChildSchoolLevel;
