import PrimaryCheckIcon from 'src/components/common/PrimaryCheckIcon';
import { useTranslation } from 'react-i18next';

const ParentSellArguments = () => {
  const { t } = useTranslation();

  return (
    <div className={'flex flex-col gap-2 items-start'}>
      <div className={'flex flex-row items-center justify-start gap-3'}>
        <div>
          <PrimaryCheckIcon />
        </div>
        <div className={'text-balance text-base text-structural-darkest'}>
          {t(`paywall.item1.title`)}
        </div>
      </div>
      <div className={'flex flex-row items-center justify-start gap-3'}>
        <div>
          <PrimaryCheckIcon />
        </div>
        <div className={'text-balance text-base text-structural-darkest'}>
          {t(`paywall.item2.title`)}
        </div>
      </div>
      <div className={'flex flex-row items-center justify-start gap-3'}>
        <div>
          <PrimaryCheckIcon />
        </div>
        <div className={'text-balance text-base text-structural-darkest'}>
          {t(`paywall.item3.title`)}
        </div>
      </div>
    </div>
  );
};

export default ParentSellArguments;
