import LayoutWithHeader from 'src/layouts/LayoutWithHeader';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import OnboardingHeader from 'src/components/Onboarding/OnboardingHeader';
import StatusBar from 'src/components/common/StatusBar';
import { Style } from '@capacitor/status-bar/dist/esm/definitions';
import ChildSchoolGroupStep from 'src/components/Onboarding/ChildSchoolGroupStep';
import { useCurrentUser } from 'src/queries/user';
import { SchoolGroup, UserType } from 'src/types/user.types';

const OnboardingChildSchoolGroup = () => {
  const navigate = useNavigate();
  const { data: child } = useCurrentUser();
  const [searchParams] = useSearchParams();

  const onSelect = (schoolGroup: SchoolGroup) => {
    // Store the selected school group in search params for the next step
    const newParams = new URLSearchParams(searchParams);
    newParams.set('schoolGroup', schoolGroup);
    navigate(`/onboarding/child/school_level?${newParams.toString()}`);
  };

  if (!child || !child.id || child.user_type !== UserType.child) {
    return <Navigate to={`/onboarding?${searchParams.toString()}`} replace />;
  }

  return (
    <LayoutWithHeader
      header={
        <OnboardingHeader
          step={3}
          from={`/onboarding/child/gender?${searchParams.toString()}`}
        />
      }
      statusBar={<StatusBar color="#FFFFFF" style={Style.Light} />}
      className={'bg-white'}
    >
      <ChildSchoolGroupStep onSelect={onSelect} isMe />
    </LayoutWithHeader>
  );
};

export default OnboardingChildSchoolGroup;
