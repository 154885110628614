import { useEffect, useState } from 'react';
import { useLesson } from 'src/components/LessonDetail/LessonContext';
import { AutoFloatingPanel } from 'src/components/common/AutoFloatingPanel';
import LessonCreateParentChallengePanel from 'src/components/LessonDetail/LessonChallengeParent/LessonCreateParentChallengePanel';
import LessonShareParentChallengePanel from 'src/components/LessonDetail/LessonChallengeParent/LessonShareParentChallengePanel';
import { useGetUsers } from 'src/queries/users';
import { UserType } from 'src/types/user.types';
import { logAnalyticsEvent } from 'src/modules/analytics/Amplitude';

const LessonChallengeParent = ({
  title,
  'data-testid': dataTestId,
}: {
  title: string;
  'data-testid'?: string;
}) => {
  const { lesson } = useLesson();
  const [isPanelOpen, setIsPanelOpen] = useState<boolean>(false);
  const [challengeLink, setChallengeLink] = useState<string>();
  const quizId = lesson.latest_quiz_id;
  const { data: users, isFetched } = useGetUsers();
  const parent = users?.find(user => user.user_type === UserType.parent);
  const isShown = Boolean(quizId && parent);

  useEffect(() => {
    if (isFetched) {
      logAnalyticsEvent('lesson_page_viewed', {
        can_challenge_parent: isShown,
      });
    }
  }, [isShown, isFetched]);

  const onClick = () => {
    setIsPanelOpen(true);
  };

  const onClose = () => {
    setIsPanelOpen(false);
  };

  if (!isShown || !parent) {
    return null;
  }

  return (
    <>
      <div data-testid={dataTestId} onClick={onClick}>
        <div
          className={
            'p-4 bg-white w-full rounded-l flex flex-row gap-3 items-center'
          }
        >
          <div className={'w-10 h-10 text-3xl'}>💪</div>
          <div
            className={'font-bold flex-grow text-base text-structural-darkest'}
          >
            {title}
          </div>
          <div className={'text-2xl text-primary-darkest'}>{'>'}</div>
        </div>
      </div>
      <AutoFloatingPanel
        isOpen={isPanelOpen}
        onClose={onClose}
        data-testid={'panel-challenge-parent'}
      >
        {challengeLink ? (
          <LessonShareParentChallengePanel
            lesson={lesson}
            parent={parent}
            challengeLink={challengeLink}
            onClose={onClose}
          />
        ) : (
          <LessonCreateParentChallengePanel
            lesson={lesson}
            onCreated={link => setChallengeLink(link)}
          />
        )}
      </AutoFloatingPanel>
    </>
  );
};

export default LessonChallengeParent;
