import QuizScore from 'src/components/Quiz/QuizScore';
import { useTranslation } from 'react-i18next';
import { useLesson } from 'src/components/LessonDetail/LessonContext';

const LessonBestScore = () => {
  const { t } = useTranslation();
  const { lesson } = useLesson();

  const maxScore = lesson.latest_quiz?.max_grade;

  if (!maxScore && maxScore !== 0) {
    return null;
  }

  return (
    <div
      data-testid={'lesson-score'}
      className={
        'w-full items-center bg-gradient-to-r from-paywall-darkest-from/40 to-paywall-darkest-to/40 rounded-xl p-1'
      }
    >
      <div
        className={
          'flex flex-row justify-between w-full h-full items-center bg-white rounded-lg p-4 px-6'
        }
      >
        <div className={'text-base font-bold uppercase'}>
          {t('quiz.result.record')}
        </div>
        <QuizScore score={maxScore} />
      </div>
    </div>
  );
};

export default LessonBestScore;
