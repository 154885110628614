import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import Spinner from 'src/components/common/designSystem/Spinner';
import QuizProvider from 'src/components/Quiz/QuizContext';
import Quiz from 'src/components/Quiz/Quiz';
import { useNotification } from 'src/contexts/NotificationContext';
import { useFinishQuiz, useGetQuizQuestion } from 'src/queries/quiz';
import LayoutWithHeader from 'src/layouts/LayoutWithHeader';
import AuthentificatedHeader from 'src/components/Header/AuthentificatedHeader';
import { useGetChallenge } from 'src/queries/challenge';
import { reportError } from 'src/modules/logs/Sentry';

export default function ChallengeLessonQuiz() {
  const navigate = useNavigate();
  const { lessonId, quizId, runId, challengeId } = useParams();
  const { showError } = useNotification();
  const { t } = useTranslation();

  const { data, isLoading: isLoadingQuestions } = useGetQuizQuestion(
    Number(quizId),
  );
  const { data: challenge, isLoading: isLoadingChallenge } = useGetChallenge(
    Number(challengeId),
  );
  const questions = data?.questions;
  const quiz = data?.quiz;

  const { mutateAsync: finishQuizQuery, isLoading: isFinishingQuiz } =
    useFinishQuiz();

  const onQuizEnd = async () => {
    if (!lessonId || !runId || !quiz) {
      return;
    }
    try {
      await finishQuizQuery({
        quizId: Number(quizId),
        quizRunId: Number(runId),
        lessonId: Number(lessonId),
        quizType: quiz.type,
      });
      navigate(
        `/challenges/${challengeId}/lessons/${lessonId}/quizzes/${quizId}/runs/${runId}/result`,
        { replace: true },
      );
    } catch (error) {
      reportError('Fail to finish quiz challenge', error);
      showError({
        error,
        message: t('quiz.failToUpdate'),
      });
    }
  };

  useEffect(() => {
    if (questions && !questions.length) {
      showError({
        message: t('quiz.noQuestions'),
        error: undefined,
      });
    }
  }, [questions, showError, t]);

  if (isFinishingQuiz || isLoadingQuestions || isLoadingChallenge)
    return (
      <LayoutWithHeader header={<AuthentificatedHeader backArrow />}>
        <Spinner className="w-full h-full" />
      </LayoutWithHeader>
    );

  if (
    !lessonId ||
    !quiz ||
    !questions ||
    !challenge ||
    !questions.length ||
    !runId
  ) {
    return <Navigate to="404" />;
  }

  return (
    <QuizProvider
      lesson={challenge.quiz.lesson}
      quizID={Number(quizId)}
      quizRunID={Number(runId)}
      quizType={quiz.type}
      onQuizEnd={onQuizEnd}
      questionsList={questions}
    >
      <Quiz />
    </QuizProvider>
  );
}
