import { QuestionStatus, useQuiz } from 'src/components/Quiz/QuizContext';
import QuizWordPuzzleSelectedLetter from './QuizWordPuzzleSelectedLetter';
import { WordPuzzleQuestion } from 'src/types/quiz.types';
import { Letter } from 'src/components/Quiz/WordPuzzleQuestion/QuizWordPuzzleQuestion';
import QuizWordPuzzleEmptySelectedLetter from 'src/components/Quiz/WordPuzzleQuestion/QuizWordPuzzleEmptySelectedLetter';
import QuizWordPuzzleSpaceSelectedLetter from 'src/components/Quiz/WordPuzzleQuestion/QuizWordPuzzleSpaceSelectedLetter';

const QuizWordPuzzleSelectedLetters = ({
  selectedLetters,
  question,
  onClickLetter,
}: {
  question: WordPuzzleQuestion;
  selectedLetters: Letter[];
  onClickLetter: (index: number) => void;
}) => {
  const { status } = useQuiz();

  return Array.from({ length: question.correct.length }).map((_, index) => {
    if (question.correct[index] === ' ') {
      return <QuizWordPuzzleSpaceSelectedLetter key={index} />;
    }
    const showResult =
      status !== QuestionStatus.WAITING && status !== QuestionStatus.PAUSED;
    if (selectedLetters.length > index && selectedLetters[index].id !== -1) {
      return (
        <QuizWordPuzzleSelectedLetter
          key={index}
          letter={selectedLetters[index]}
          onClick={
            status === QuestionStatus.WAITING
              ? () => {
                  onClickLetter(index);
                }
              : undefined
          }
          isCorrect={
            question.correct[index].toLowerCase() ===
            selectedLetters[index].letter.toLowerCase()
          }
          showResult={showResult}
        />
      );
    }
    return (
      <QuizWordPuzzleEmptySelectedLetter
        key={index}
        letter={question.correct[index]}
        showResult={showResult}
      />
    );
  });
};

export default QuizWordPuzzleSelectedLetters;
