import { PhotoStatus, usePhoto } from 'src/components/Piece/Photo/PhotoContext';
import GalleryIcon from 'src/images/gallery.svg?react';

const GalleryButton = () => {
  const { status, openGallery } = usePhoto();

  if (status !== PhotoStatus.CAMERA_READY && status !== PhotoStatus.NONE) {
    return null;
  }

  return (
    <div
      className={
        'w-16 h-16 flex justify-center items-center bg-black bg-opacity-70 rounded-full cursor-pointer'
      }
      data-testid="btn-open-gallery"
      onClick={openGallery}
    >
      <GalleryIcon className={'stroke-white w-6 h-6'} />
    </div>
  );
};

export default GalleryButton;
