import dayjs from 'dayjs';
import { Trans, useTranslation } from 'react-i18next';
import { usePaywall } from 'src/contexts/PaywallContext';
import { ANNUAL_OFFERS } from 'src/modules/purchase/purchase.constants';
import { formatPrice } from 'src/utils/price';

const DuePricing = () => {
  const { nbTrialDays } = usePaywall();
  const { t } = useTranslation();
  const { promoCodes } = usePaywall();

  const promoToApply = promoCodes.find(promoCode =>
    ANNUAL_OFFERS.includes(promoCode.offering_id),
  );

  return (
    <div
      className={
        'flex flex-col gap-2 items-start text-base text-structural-darkest w-full'
      }
    >
      <div className={'flex flex-row justify-between items-center w-full'}>
        <div>
          {t('paywall.pay.paymentDate', {
            date: dayjs().add(nbTrialDays, 'day').format('DD MMMM YYYY'),
          })}
        </div>
        <div>
          {promoToApply ? formatPrice(promoToApply.price_eur) : '65.99€'}
        </div>
      </div>
      <div
        className={
          'flex flex-row justify-between items-center font-bold w-full'
        }
      >
        <div className={'flex flex-row items-center gap-1'}>
          <Trans i18nKey="paywall.pay.paymentToday" values={{ nbTrialDays }}>
            Dû aujourd’hui
            <span
              className={
                'text-xs bg-gradient-to-r bg-clip-text text-transparent from-paywall-darkest-from to-paywall-darkest-to'
              }
            >
              (7 jours gratuits)
            </span>
          </Trans>
        </div>
        <div>0 €</div>
      </div>
    </div>
  );
};

export default DuePricing;
