import { useTranslation } from 'react-i18next';
import Spinner from 'src/components/common/designSystem/Spinner';
import Title from 'src/components/common/designSystem/Title';
import Card from 'src/components/common/designSystem/Card';
import { useLesson } from 'src/components/LessonDetail/LessonContext';
import LessonWithoutQuizCard from 'src/components/LessonDetail/LessonWithoutQuizCard';
import LessonImprovement from 'src/components/LessonDetail/LessonImprovements/LessonImprovement';
import { useGetLatestAnswers } from 'src/queries/quiz/runs';

export default function LessonImprovements() {
  const { t } = useTranslation();
  const { lesson } = useLesson();
  const lessonId = lesson.id;

  const { data: answers, isLoading: isLoadingAnswers } =
    useGetLatestAnswers(lessonId);

  if (isLoadingAnswers) {
    return <Spinner size={'xl'} className="w-full h-full" />;
  }

  if (!answers?.length) {
    return <LessonWithoutQuizCard />;
  }

  const wrongAnswers = answers.filter(({ is_correct }) => !is_correct);

  if (!wrongAnswers.length)
    return (
      <div className={'h-full flex justify-center items-center'}>
        <Card className={'text-center gap-2 p-6'}>
          <div className={'text-5xl'}>😎</div>
          <div>
            <div className={'font-extrabold text-base'}>
              {t('lessons.improvements.noErrors.title')}
            </div>
            <div className={'text-base text-balance'}>
              {t('lessons.improvements.noErrors.description')}
            </div>
          </div>
        </Card>
      </div>
    );

  return (
    <>
      <Title className={'!text-left'}>
        {t('lessons.improvements.errors', { count: wrongAnswers.length })}
      </Title>
      <Card className={'!items-start gap-4'}>
        {wrongAnswers.map(
          ({
            id,
            answer,
            question: {
              question: { question_payload },
            },
          }) => (
            <LessonImprovement
              key={id}
              question={question_payload}
              answer={answer.given_answer}
            />
          ),
        )}
      </Card>
    </>
  );
}
