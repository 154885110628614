import { Lesson } from 'src/types/lesson.types';
import { useTranslation } from 'react-i18next';
import { useCreateQuizChallenge } from 'src/queries/quiz';
import { useNotification } from 'src/contexts/NotificationContext';
import { reportError } from 'src/modules/logs/Sentry';
import Button from 'src/components/common/designSystem/Button';
import LessonParentChallengeLowScorePanel from 'src/components/LessonDetail/LessonChallengeParent/LessonParentChallengeLowScorePanel';
import { logAnalyticsEvent } from 'src/modules/analytics/Amplitude';

const LessonCreateParentChallengePanel = ({
  lesson,
  onCreated,
}: {
  lesson: Lesson;
  onCreated: (challengeLink: string) => void;
}) => {
  const { t } = useTranslation();
  const { mutateAsync: createChallenge, isLoading } = useCreateQuizChallenge();
  const { showError } = useNotification();
  const quizId = lesson.latest_quiz_id;

  const maxScore = lesson.latest_quiz?.max_grade;

  if (!maxScore || maxScore < 80) {
    return <LessonParentChallengeLowScorePanel />;
  }

  const onChallenge = async () => {
    if (!quizId) {
      return;
    }
    try {
      const { link } = await createChallenge({
        quizId,
        notify_parent: true,
      });
      onCreated(link);
      logAnalyticsEvent('parent_challenged');
    } catch (error) {
      reportError('Fail to challenge his parent', error);
      showError({
        message: t('quiz.result.share.error'),
        error,
      });
    }
  };

  return (
    <div className={'flex items-start flex-col gap-5 p-6 text-left'}>
      <div className={'text-7xl'}>🤩</div>
      <div className={'font-bold text-balance text-2xl'}>
        {t('challenge.parent.panel.title')}
      </div>
      <div className={'text-base mb-2 flex flex-col gap-2'}>
        <div>{t('challenge.parent.panel.description1')}</div>
        <div>{t('challenge.parent.panel.description2')}</div>
        <div>{t('challenge.parent.panel.description3')}</div>
      </div>
      <div className={'flex flex-col gap-2 w-full'}>
        <Button
          data-testid="btn-challenge-parent"
          type={'primary'}
          loading={isLoading}
          onClick={onChallenge}
        >
          {t('challenge.parent.panel.button')}
        </Button>
      </div>
    </div>
  );
};

export default LessonCreateParentChallengePanel;
