import { useState } from 'react';
import Flame from 'src/images/Flame.svg?react';
import StreaksDetails from './StreaksDetails';
import { useGetMilestones } from 'src/queries/milestones';

const StreakHeaderCounter = () => {
  const { data: milestones } = useGetMilestones();
  const [detailsOpen, setDetailsOpen] = useState(false);

  return (
    <div>
      <div
        data-testid={'btn-streak-counter'}
        onClick={() => {
          setDetailsOpen(true);
        }}
        className={
          'flex flex-row justify-center min-w-16 items-center w-full gap-1 bg-structural-light bg-opacity-20 rounded-3xl px-2 py-1.5'
        }
      >
        <div>
          <Flame className={'w-auto h-5'} />
        </div>
        <div className={'text-base font-extrabold text-structural-darkest'}>
          {milestones?.daily_streaks ?? 0}
        </div>
      </div>
      <StreaksDetails
        isOpen={detailsOpen}
        onClose={() => {
          setDetailsOpen(false);
        }}
      />
    </div>
  );
};

export default StreakHeaderCounter;
