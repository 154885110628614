export default {
  title: 'Paramètres',
  switchUser: "Changer d'utilisateur",
  subscription: 'Abonnement',
  buildVersion: "Version de l'application",
  contactUs: 'Nous contacter',
  shareApp: "Partager l'application",
  logout: 'Déconnexion',
  deleteAccount: 'Supprimer mon compte',
  gameSettings: {
    timer: {
      title: 'Réglage du minuteur',
      description: 'Ajuste la durée du minuteur',
      noTimer: 'Sans minuteur',
      easy: 'Facile',
      medium: 'Moyen',
      hard: 'Difficile',
      error: 'Impossible de modifier le réglage du minuteur',
    },
  },
  child: {
    title: 'Salut {{firstName}} !',
    subtitle: 'Ta performance',
    xp: 'XP',
    challenges: 'Duels gagnés',
    ask: 'Une question ? Une remarque ?',
    contactUs: 'Nous contacter',
    streaks: {
      title: 'Série de révisions',
    },
  },
  parent: {
    title: 'Votre famille',
    account: {
      title: 'Mon compte',
    },
    children: {
      title_one: 'Mon enfant',
      title_other: 'Mes enfants',
      firstname: {
        button: 'Modifier le prénom',
        title: 'Modifier le prénom de votre enfant',
        placeholder: 'Prénom',
        fail: "Impossible de modifier le prénom de l'enfant",
        submit: 'Enregistrer',
      },
      delete: {
        button: 'Supprimer ce compte',
        title: 'Etes-vous sûr(e) de vouloir supprimer ce compte ?',
        description:
          'Toutes les leçons et historique seront supprimés ! Cette action est irréversible.',
        confirm: 'Supprimer',
      },
    },
  },
};
