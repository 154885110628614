import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { type ButtonProps } from 'antd';
import { useNavigate } from 'react-router-dom';

import { AutoFloatingPanel } from 'src/components/common/AutoFloatingPanel';
import AddLessonSelectSubject from 'src/components/AddLesson/AddLessonSelectSubject';
import AddLessonTitle from 'src/components/AddLesson/AddLessonTitle';
import { useCreateLesson } from 'src/queries/lessons';
import { getAddLessonPieceURL } from 'src/utils/lessonsUtils';
import { useNotification } from 'src/contexts/NotificationContext';
import { Lesson, LessonSubject } from 'src/types/lesson.types';
import { reportError } from 'src/modules/logs/Sentry';

export default function AddLessonModal({
  isOpen,
  onClose,
  onLessonCreated,
  subject,
}: {
  isOpen: boolean;
  onClose: () => void;
  onLessonCreated?: (lesson: Lesson) => Promise<void>;
  subject?: LessonSubject;
} & ButtonProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedSubject, setSelectedSubject] = useState<
    LessonSubject | undefined
  >(subject);

  const { mutateAsync, isLoading } = useCreateLesson();
  const { showError } = useNotification();

  const selectSubject = async (subject: LessonSubject) => {
    setSelectedSubject(subject);
  };

  const onSubmit = async (title: string) => {
    if (!selectedSubject) {
      return;
    }

    try {
      const newLesson = await mutateAsync({
        title,
        subject_matter: selectedSubject,
      });
      await onLessonCreated?.(newLesson);
      navigate(getAddLessonPieceURL(newLesson.id));
    } catch (error) {
      reportError('Fail to create lesson', error);
      showError({
        error,
        message: t('lessons.new.error'),
      });
    }
  };

  useEffect(() => {
    setSelectedSubject(subject);
  }, [subject]);

  return (
    <AutoFloatingPanel
      isOpen={isOpen}
      onClose={onClose}
      data-testid={'modal-add-lesson'}
      aria-labelledby="customized-dialog-title"
    >
      <div className={'p-4 text-structural-darkest'}>
        {selectedSubject ? (
          <AddLessonTitle
            onBack={subject ? undefined : () => setSelectedSubject(undefined)}
            onSubmitTitle={onSubmit}
            isLoading={isLoading}
            subject={selectedSubject}
          />
        ) : (
          <AddLessonSelectSubject onSubjectSelected={selectSubject} />
        )}
      </div>
    </AutoFloatingPanel>
  );
}
