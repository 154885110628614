import { useCurrentUser } from 'src/queries/user';
import QuizXPIcon from 'src/images/quiz/xp.svg?react';
import { useEffect, useState } from 'react';
import {
  getLocalStorageXPWon,
  resetLocalStorageXPWon,
} from 'src/utils/usersUtils';
import './XPHeaderCounter.scss';
import XPHeaderCounterAnimation from 'src/components/Streak/header/XPHeaderCounterAnimation';
import CounterAnimation from 'src/components/common/animations/Counter';

const XPHeaderCounter = () => {
  const { data: user } = useCurrentUser();
  const [newXP, setNewXP] = useState(getLocalStorageXPWon());
  const [counterElement, setCounterElement] = useState<HTMLDivElement | null>(
    null,
  );

  useEffect(() => {
    if (user?.xp !== undefined) {
      resetLocalStorageXPWon();
      setTimeout(() => {
        setNewXP(0);
      }, 3000);
    }
  }, [user]);

  if (user?.xp === undefined) {
    return null;
  }

  const xp = user.xp || 0;

  return (
    <>
      <div
        className={
          'flex flex-row overflow-visible items-center justify-center min-w-16 gap-1 bg-structural-light bg-opacity-20 rounded-3xl px-2 py-1.5'
        }
        data-testid={'xp-header-counter'}
        ref={ref => {
          setCounterElement(ref);
        }}
      >
        <QuizXPIcon className={'h-5 w-auto'} />
        <div
          className={
            'text-base relative font-extrabold text-structural-darkest'
          }
        >
          <div>
            <CounterAnimation
              value={xp}
              initialValue={xp - newXP}
              duration={500}
              offsetStartAnimation={1100}
            />
          </div>
        </div>
      </div>

      <XPHeaderCounterAnimation counterElement={counterElement} newXP={newXP} />
    </>
  );
};

export default XPHeaderCounter;
