import { useEffect, useState } from 'react';
import {
  InAppNotification,
  InAppNotificationType,
} from 'src/types/notification.types';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/common/designSystem/Button';
import TransparentModal from 'src/components/common/designSystem/TransparentModal';
import QuizXPIcon from 'src/images/quiz/xp.svg?react';
import { hasDoneOnboardingFirstLesson } from 'src/utils/onboarding';
import MilestoneAnimation from 'src/components/Milestones/MilestoneAnimation';
import dayjs from 'dayjs';

const InAppXPBoosterNotification = ({
  notification,
  onRead,
}: {
  notification: InAppNotification & {
    type: InAppNotificationType.XP_BOOSTER_ACTIVATED;
  };
  onRead: () => void;
}) => {
  const { t } = useTranslation();
  const params = notification.params;
  const end = dayjs(params.end, { utc: true });
  const onboardingDone = hasDoneOnboardingFirstLesson();
  const [isOpened, setIsOpened] = useState(onboardingDone);

  const onClose = () => {
    onRead();
    setIsOpened(false);
  };

  useEffect(() => {
    const endsIn = end.diff(dayjs(), 'millisecond');
    const timeout = setTimeout(() => {
      onClose();
    }, endsIn);

    return () => {
      clearTimeout(timeout);
    };
  }, [end]);

  const endIn = end.from(dayjs(), true);

  return (
    <TransparentModal
      isOpen={isOpened}
      onClose={onClose}
      data-testid={'modal-xp-booster'}
      footer={
        <Button
          type={'primary'}
          data-testid={'btn-xp-booster-lets-go'}
          onClick={onClose}
        >
          {t('global.letsGo')}
        </Button>
      }
    >
      <div className={'flex flex-col gap-8 w-full justify-center items-center'}>
        <div className={'relative'}>
          <MilestoneAnimation />
          <div className={'flex flex-col items-center justify-center gap-4'}>
            <QuizXPIcon className={'h-28 w-auto'} />
            <div className={'text-white font-bold text-8xl'}>
              x{params.boost_multiplier}
            </div>
          </div>
        </div>
        <div
          className={
            'text-white text-xl font-bold whitespace-pre-wrap text-center px-8 text-balance'
          }
        >
          {t('quiz.xp.booster.title', { time: endIn })}
        </div>
      </div>
    </TransparentModal>
  );
};

export default InAppXPBoosterNotification;
