import { useTranslation } from 'react-i18next';
import Button from 'src/components/common/designSystem/Button';
import { lessonIsProcessing } from 'src/utils/lessonsUtils';
import { useLesson } from 'src/components/LessonDetail/LessonContext';
import { useNavigate } from 'react-router-dom';

const LessonStartQuiz = () => {
  const { lesson } = useLesson();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isProcessing = lessonIsProcessing(lesson);

  const handleQuizClick = async () => {
    if (!lesson.latest_quiz) {
      return;
    }
    navigate(`/lessons/${lesson.id}/quizzes/${lesson.latest_quiz.id}`);
  };

  if (isProcessing || !lesson.lesson_pieces.length) {
    return null;
  }

  return (
    <Button
      data-testid="btn-start-quiz"
      size="large"
      type="primary"
      disabled={!lesson.latest_quiz}
      onClick={handleQuizClick}
    >
      {t('lessons.startAQuiz')}
    </Button>
  );
};

export default LessonStartQuiz;
