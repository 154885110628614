import { useTranslation } from 'react-i18next';

const ParentEmptyNotificationCard = () => {
  const { t } = useTranslation();
  return (
    <div data-testid={`notification-empty`}>
      <div className={'flex flex-row bg-white rounded-xl p-4 gap-4'}>
        <div className={'flex flex-col w-full'}>
          <div
            className={
              'text-base font-bold text-balance text-structural-darkest'
            }
          >
            {t('notifications.empty.parent.title')}
          </div>
          <div className={'flex flex-row justify-between items-center'}>
            <div
              className={
                'font-semibold line-clamp-2 text-ellipsis overflow-hidden text-balance text-structural-neutral'
              }
            >
              {t('notifications.empty.parent.description')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParentEmptyNotificationCard;
