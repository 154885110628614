import { useTranslation } from 'react-i18next';
import { useGetUsers } from 'src/queries/users';
import { Child, UserType } from 'src/types/user.types';
import Spinner from 'src/components/common/designSystem/Spinner';
import ProfileChildCard from 'src/components/Parent/ProfileChildCard';

const ProfileChildren = () => {
  const { t } = useTranslation();
  const { data: users, isLoading } = useGetUsers();

  if (isLoading) {
    return <Spinner />;
  }

  if (!users || !users.length) {
    return null;
  }

  const children = users.filter(
    user => user.firstname?.length && user.user_type === UserType.child,
  ) as Child[];

  if (!children.length) {
    return null;
  }

  return (
    <div className={'flex flex-col gap-2'}>
      <div className={'text-xl font-bold'}>
        {t('profile.parent.children.title', { count: children?.length })}
      </div>
      {children.map((child: Child) => (
        <ProfileChildCard key={child.id} child={child} />
      ))}
    </div>
  );
};

export default ProfileChildren;
