import Button from 'src/components/common/designSystem/Button';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import AmbassadorCodePanel from 'src/components/Paywall/AmbassadorCode/AmbassadorCodePanel';

const AmbassadorCodeButton = ({ onValidate }: { onValidate: () => void }) => {
  const { t } = useTranslation();
  const [isOpenCodePanel, setOpenCodePanel] = useState(false);

  const openCodePanel = () => {
    setOpenCodePanel(true);
  };
  const closeCodePanel = () => {
    setOpenCodePanel(false);
  };
  const onCodeVerified = () => {
    closeCodePanel();
    onValidate();
  };

  return (
    <>
      <Button
        type={'link'}
        size={'small'}
        className={'!w-auto !p-0'}
        onClick={openCodePanel}
        data-testid="btn-ambassador-code"
      >
        {t('paywall.pay.ambassador_code.button')}
      </Button>
      <AmbassadorCodePanel
        isOpen={isOpenCodePanel}
        onClose={closeCodePanel}
        onCodeVerified={onCodeVerified}
      />
    </>
  );
};

export default AmbassadorCodeButton;
