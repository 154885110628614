import { useTranslation } from 'react-i18next';
import { useVoiceRecorder } from 'src/components/Piece/Audio/VoiceRecorderContext';
import { VoiceVisualizer } from 'react-voice-visualizer';

const VoiceRecordVisualizer = () => {
  const { controls } = useVoiceRecorder();
  const { t } = useTranslation();

  if (
    !controls.isRecordingInProgress &&
    !controls.isProcessingRecordedAudio &&
    !controls.recordedBlob
  ) {
    return (
      <div className={'text-xl font-bold text-center text-balance'}>
        {t('lessons.audio.description')}
      </div>
    );
  }

  return (
    <VoiceVisualizer
      controls={controls}
      mainContainerClassName="lesson-voice-recorder w-full"
      controlButtonsClassName="w-24 h-12 min-w-24 min-h-12"
      height={100}
      isAudioProcessingTextShown={false}
      barWidth={10}
      speed={1}
      gap={1}
      mainBarColor={'white'}
      secondaryBarColor={'white'}
      isDefaultUIShown={false}
      isControlPanelShown={false}
    />
  );
};

export default VoiceRecordVisualizer;
