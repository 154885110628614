import { useVoiceRecorder } from 'src/components/Piece/Audio/VoiceRecorderContext';
import MicroIcon from 'src/images/micro.svg?react';

const ResumeVoiceRecordButton = () => {
  const {
    isHandlingBlob,
    controls: {
      isPausedRecording,
      togglePauseResume,
      isProcessingRecordedAudio,
    },
  } = useVoiceRecorder();

  if (!isPausedRecording || isHandlingBlob || isProcessingRecordedAudio) {
    return null;
  }

  return (
    <div
      className="flex items-center justify-center bg-white rounded-full text-structural-darkest text-2xl h-14 w-14 cursor-pointer"
      onClick={togglePauseResume}
    >
      <MicroIcon className={'fill-structural-darkest'} />
    </div>
  );
};

export default ResumeVoiceRecordButton;
