import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { GameDifficulty } from 'src/types/user.types';

export const useUpdateGameDifficulty = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (difficulty: GameDifficulty) => {
      const { data } = await axios.put('/api/user/settings/game_difficulty', {
        game_difficulty: difficulty,
      });
      console.log('data', data);
      return data;
    },
    onSuccess: async () => {
      // Invalidate the user query to refresh user data
      await queryClient.invalidateQueries(['user']);
    },
  });
};
