import LayoutWithHeader from 'src/layouts/LayoutWithHeader';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ObjectiveStep from 'src/components/Onboarding/ObjectiveStep';
import OnboardingHeader from 'src/components/Onboarding/OnboardingHeader';
import StatusBar from 'src/components/common/StatusBar';
import { Style } from '@capacitor/status-bar/dist/esm/definitions';

const OnboardingParentObjective = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const onFinish = async () => {
    navigate(`/onboarding/parent/firstname?${searchParams.toString()}`);
  };

  return (
    <LayoutWithHeader
      header={<OnboardingHeader step={1} />}
      statusBar={<StatusBar color="#FFFFFF" style={Style.Light} />}
      className={'bg-white'}
    >
      <ObjectiveStep onFinish={onFinish} />
    </LayoutWithHeader>
  );
};

export default OnboardingParentObjective;
