import { useTranslation } from 'react-i18next';
import { useGenerateFriendInvitationLink } from 'src/queries/invitations';
import { useNotification } from 'src/contexts/NotificationContext';
import { Share } from '@capacitor/share';
import { Clipboard } from '@capacitor/clipboard';
import { useCallback } from 'react';
import { isCancelError } from 'src/utils/share';
import { logAnalyticsEvent } from 'src/modules/analytics/Amplitude';
import { reportError } from 'src/modules/logs/Sentry';

const useInviteFriend = () => {
  const { t } = useTranslation();
  const { mutateAsync: generateLink, isLoading } =
    useGenerateFriendInvitationLink();
  const { showError, showSuccess } = useNotification();

  const invite = useCallback(
    async (message?: string) => {
      if (isLoading) {
        return;
      }
      try {
        const link = await generateLink();
        if (await Share.canShare()) {
          await Share.share({
            title: message ?? t('invitations.card.message'),
            text: message ?? t('invitations.card.message'),
            url: link,
          });
        } else {
          await Clipboard.write({
            string: link,
          });
          showSuccess({
            message: t('invitations.card.copied'),
          });
        }
        logAnalyticsEvent('viral_invite_sent');
      } catch (error) {
        if (isCancelError(error)) {
          return;
        }

        reportError('Fail to invite a friend', error);
        showError({
          message: t('invitations.card.error'),
          error,
        });
      }
    },
    [generateLink, showError, showSuccess, t, isLoading],
  );

  return { invite, isLoading };
};

export default useInviteFriend;
