import { CapacitorCrisp } from '@capgo/capacitor-crisp';
import { User } from 'src/types/user.types';
import { Capacitor } from '@capacitor/core';
import { reportError } from 'src/modules/logs/Sentry';
import { getModuleExternalId } from 'src/utils/external-id';

export const initCrisp = async () => {
  try {
    await CapacitorCrisp.configure({
      websiteID: import.meta.env.VITE_CRISP_WEBSITE_ID,
    });
  } catch (error) {
    reportError('Error while initializing Crisp', error);
  }
};

export const initUserToCrispSupport = async (user?: User) => {
  if (!user) {
    try {
      await CapacitorCrisp.reset();
    } catch (error) {
      reportError('Error while logging out from Crisp', error);
    }
    return;
  }

  try {
    await Promise.all([
      Capacitor.getPlatform() === 'android'
        ? undefined
        : CapacitorCrisp.setTokenID({
            tokenID: getModuleExternalId(user.id),
          }),
      CapacitorCrisp.setUser({
        nickname: user.firstname,
        email: user.email,
      }),
      CapacitorCrisp.setString({
        key: 'user_id',
        value: getModuleExternalId(user.id),
      }),
      CapacitorCrisp.setInt({
        key: 'family_id',
        value: user.family_id!,
      }),
      CapacitorCrisp.setString({
        key: 'user_type',
        value: user.user_type,
      }),
      CapacitorCrisp.setSegment({
        segment: user.user_type,
      }),
    ]);
  } catch (error) {
    reportError('Error while logging in Crisp', error);
  }
};

export const openCrispMessenger = async () => {
  try {
    await CapacitorCrisp.openMessenger();
  } catch (error) {
    reportError('Error while opening Crisp', error);
  }
};
