import { useTranslation } from 'react-i18next';

import { getSubjectInfo } from 'src/utils/subject';
import { Challenge } from 'src/types/challenge.types';
import ArrowLeft from 'src/images/arrow-left.svg?react';
import { Link } from 'react-router-dom';

const ChildChallengeCard = ({ challenge }: { challenge: Challenge }) => {
  const { t } = useTranslation();
  const lesson = challenge.quiz.lesson;
  const subject = getSubjectInfo(lesson.subject_matter);

  return (
    <Link
      to={`/challenges/${challenge.id}`}
      className={
        'flex flex-row items-center justify-between px-5 py-3.5 w-full gap-4 cursor-pointer bg-white rounded-3xl border border-primary-neutral'
      }
      data-testid={`card-challenge-${challenge.id}`}
    >
      <div className={'flex flex-row items-center gap-4'}>
        <div>
          <div
            className={
              'w-14 h-14 relative overflow-hidden bg-primary-neutral rounded-2xl flex items-center justify-center p-3'
            }
          >
            <subject.IconSmallComponent className={'w-full h-full'} />
          </div>
        </div>
        <div className={'flex flex-col flex-grow overflow-hidden'}>
          <div
            className={
              'text-base overflow-ellipsis line-clamp-2 w-full overflow-hidden font-bold text-structural-darkest'
            }
          >
            {lesson.title}
          </div>
          <div className={'text-structural-dark'}>{t(subject.labelKey)}</div>
        </div>
      </div>
      <div>
        <ArrowLeft />
      </div>
    </Link>
  );
};

export default ChildChallengeCard;
