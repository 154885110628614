import { AutoFloatingPanel } from 'src/components/common/AutoFloatingPanel';
import { Form } from 'antd';
import Input from 'src/components/common/designSystem/Input';
import Button from 'src/components/common/designSystem/Button';
import { reportError } from 'src/modules/logs/Sentry';
import { useTranslation } from 'react-i18next';
import { useNotification } from 'src/contexts/NotificationContext';
import { useVerifyAmbassadorCode } from 'src/queries/ambassador_codes/ambassador_codes';
import { usePaywall } from 'src/contexts/PaywallContext';

const AmbassadorCodePanel = ({
  isOpen,
  onClose,
  onCodeVerified,
}: {
  isOpen: boolean;
  onClose: () => void;
  onCodeVerified: () => void;
}) => {
  const { t } = useTranslation();
  const { mutateAsync: verifyCode, isLoading: isVerifying } =
    useVerifyAmbassadorCode();
  const { addPromoCodes } = usePaywall();
  const { showError } = useNotification();

  const checkCode = async ({ code }: { code: string }) => {
    try {
      const { isValid, promoCodes } = await verifyCode(code);
      if (isValid) {
        if (promoCodes.length > 0) {
          addPromoCodes(promoCodes);
        }
        onCodeVerified();
        return;
      }

      showError({
        message: t(`paywall.pay.ambassador_code.panel.invalid`),
      });
    } catch (error) {
      reportError('Fail to verify ambassador code', error);
      showError({
        error,
        message: t(`paywall.pay.ambassador_code.panel.fail`),
      });
    }
  };

  return (
    <AutoFloatingPanel
      isOpen={isOpen}
      onClose={onClose}
      data-testid={'ambassador-code-panel'}
      priority={1}
    >
      <Form
        name="ambassadorCodeForm"
        onFinish={checkCode}
        className="w-full bg-transparent flex-1 flex flex-col gap-3 p-4"
        disabled={isVerifying}
      >
        <div className={'text-balance font-bold text-base'}>
          {t('paywall.pay.ambassador_code.panel.title')}
        </div>
        <Form.Item
          name="code"
          className="w-full text-center"
          rules={[
            {
              required: true,
              message: t('global.validation.fieldRequired'),
            },
          ]}
        >
          <Input
            data-testid="inp-ambassador-code"
            autoFocus={isOpen}
            type="text"
            placeholder={t(`paywall.pay.ambassador_code.panel.placeholder`)}
          />
        </Form.Item>
        <Button
          data-testid="btn-verify"
          type="primary"
          htmlType="submit"
          disabled={isVerifying}
          loading={isVerifying}
          className="w-full"
        >
          {t('paywall.pay.ambassador_code.panel.button')}
        </Button>
      </Form>
    </AutoFloatingPanel>
  );
};

export default AmbassadorCodePanel;
