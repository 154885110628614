const QuizWordPuzzleEmptySelectedLetter = ({
  showResult,
  letter,
}: {
  showResult?: boolean;
  letter: string;
}) => {
  return (
    <span
      className={`ml-1 my-1 inline-block align-middle rounded-lg border-2 ${
        showResult
          ? 'bg-red-lightest border-red-lightest text-red-darkest'
          : 'bg-primary-lighter border-primary-lighter'
      } `}
    >
      <div
        className={
          'w-8 h-8 flex items-center justify-center text-xl font-semibold '
        }
      >
        {showResult ? letter.toUpperCase() : null}
      </div>
    </span>
  );
};

export default QuizWordPuzzleEmptySelectedLetter;
