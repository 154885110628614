import ProfileButton from 'src/components/Profile/ProfileButton';

const ParentHeader = ({ title }: { title: string }) => {
  return (
    <div className={'flex flex-row justify-between items-center'}>
      <div className={'text-2xl font-bold'}>{title}</div>
      <ProfileButton />
    </div>
  );
};

export default ParentHeader;
