import { Trans, useTranslation } from 'react-i18next';
import Badge from 'src/components/common/designSystem/Badge';
import { formatPrice, getNbTrialDays } from 'src/utils/price';
import { PurchasesPackage } from '@revenuecat/purchases-capacitor';
import { usePaywall } from 'src/contexts/PaywallContext';

const OfferTxt = ({
  promo,
  onClick,
  purchasesPackage,
}: {
  promo?: string;
  onClick?: (purchasesPackage: PurchasesPackage) => void;
  purchasesPackage: PurchasesPackage;
}) => {
  const { t } = useTranslation();
  const { promoCodes } = usePaywall();

  const promoToApply = promoCodes.find(promoCode =>
    promoCode.product_ids.includes(purchasesPackage.product.identifier),
  );
  const nbTrialDays = purchasesPackage?.product.introPrice
    ? getNbTrialDays(purchasesPackage.product.introPrice.period)
    : 7;

  return (
    <div
      className={'relative cursor-pointer w-full'}
      onClick={onClick ? () => onClick(purchasesPackage) : undefined}
    >
      {promoToApply ? (
        <Badge
          text={t(`paywall.panel.${purchasesPackage.packageType}.promo`, {
            promo: promoToApply.discount,
          })}
        />
      ) : promo ? (
        <Badge text={promo} />
      ) : null}
      <div
        data-testid={`radio-${purchasesPackage.packageType}`}
        className={
          'flex flex-row justify-between w-full h-full items-center bg-white rounded-lg p-4 px-4 gap-2'
        }
      >
        <div className={'flex flex-row gap-4 items-center justify-center'}>
          <div className={'flex flex-col'}>
            <div className={'text-lg leading-none text-structural-darker'}>
              <Trans
                i18nKey={`paywall.panel.${purchasesPackage.packageType}.txt`}
                values={{
                  nbTrialDays,
                  price: promoToApply
                    ? formatPrice(promoToApply.price_eur)
                    : purchasesPackage.product.priceString,
                  priceMonth: promoToApply
                    ? formatPrice(promoToApply.price_eur / 12)
                    : purchasesPackage.product.pricePerMonthString,
                }}
              >
                Accès illimité pendant 7 jours, puis <b>65,99€ par an</b>{' '}
                (5,49€/mois)
              </Trans>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferTxt;
