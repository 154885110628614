import { useTranslation } from 'react-i18next';
import LessonStartQuiz from 'src/components/LessonDetail/LessonStartQuiz';

const LessonParentChallengeLowScorePanel = () => {
  const { t } = useTranslation();

  return (
    <div className={'flex items-start flex-col gap-5 p-6 text-left'}>
      <div className={'text-7xl'}>💪</div>
      <div className={'font-bold text-balance text-2xl'}>
        {t('challenge.parent.lowScore.title')}
      </div>
      <div className={'text-balance text-base mb-2 flex flex-col gap-2'}>
        <div>{t('challenge.parent.lowScore.description')}</div>
      </div>
      <div className={'flex flex-col gap-2 w-full'}>
        <LessonStartQuiz />
      </div>
    </div>
  );
};

export default LessonParentChallengeLowScorePanel;
