import { ReactNode } from 'react';
import { Style } from '@capacitor/status-bar/dist/esm/definitions';
import { projectColors } from 'src/styles/project-styles';
import BlankLayout from 'src/layouts/BlankLayout';
import BackgroundPattern from 'src/components/common/BackgroundPattern';
import { Capacitor } from '@capacitor/core';

const BlankPrimaryLayout = ({
  children,
  appBackgroundFooter,
  invertGradient,
  'data-testid': dataTestid,
}: {
  children: ReactNode;
  appBackgroundFooter?: string;
  invertGradient?: boolean;
  'data-testid'?: string;
}) => {
  return (
    <BlankLayout
      appBackground={`${invertGradient ? 'bg-gradient-to-b' : 'bg-gradient-to-t'} from-paywall-darkest-from to-paywall-darkest-to`}
      statusBar={{
        color:
          Capacitor.getPlatform() === 'ios'
            ? 'transparent'
            : projectColors.paywall.darkest.to,
        style: Style.Dark,
      }}
      appBackgroundFooter={appBackgroundFooter ?? 'transparent'}
      data-testid={dataTestid}
    >
      <div
        className={
          'flex z-10 flex-col items-center justify-between w-full h-full'
        }
      >
        {children}
      </div>
      <BackgroundPattern />
    </BlankLayout>
  );
};

export default BlankPrimaryLayout;
