import { useTranslation } from 'react-i18next';
import Button from 'src/components/common/designSystem/Button';
import { useNavigate } from 'react-router-dom';
import PaywallCGU from 'src/components/Paywall/PaywallCGU';
import { openWhatsAppSupport } from 'src/modules/support/support';
import IconMdiChevronLeft from '~icons/mdi/chevron-left';
import { RevenueCatPlacement } from 'src/modules/purchase/purchase.constants';
import { useCurrentUser } from 'src/queries/user';
import AmbassadorCodeButton from 'src/components/Paywall/AmbassadorCode/AmbassadorCodeButton';
import OfferSelector from 'src/components/Paywall/Offers/OfferSelector';
import GameControllerIcon from 'src/images/paywall/game-controller.svg?react';
import ThumbUpIcon from 'src/images/paywall/thumb-up.svg?react';
import StarIcon from 'src/images/paywall/star.svg?react';
import { useState } from 'react';
import { PurchasesPackage } from '@revenuecat/purchases-capacitor';
import { useNotification } from 'src/contexts/NotificationContext';
import Footer from 'src/components/common/designSystem/Footer';
import PaywallDescription from 'src/components/Paywall/PaywallDescription';
import ShowDeviceInfoButton from 'src/components/Paywall/ShowDeviceInfo';

const PaywallScreenPayUnlock = ({
  onValidateAmbassadorCode,
  ambassadorCodeValid,
  onPay,
  paying,
  placement,
}: {
  onValidateAmbassadorCode: () => void;
  ambassadorCodeValid: boolean;
  onPay: (purchasesPackage: PurchasesPackage) => void;
  paying: boolean;
  placement?: RevenueCatPlacement;
}) => {
  const { t } = useTranslation();
  const { data: user } = useCurrentUser();
  const navigate = useNavigate();
  const [selectedPurchasePackage, setSelectedPurchasePackage] =
    useState<PurchasesPackage>();
  const { showError } = useNotification();

  if (!user) {
    return null;
  }

  const handlePay = async () => {
    if (!selectedPurchasePackage) {
      showError({
        message: t('paywall.noPackageSelected'),
      });
      return;
    }
    onPay(selectedPurchasePackage);
  };

  return (
    <>
      <div className={'flex flex-col p-5 gap-6'}>
        <div className={'flex flex-row justify-start items-center'}>
          <div
            className={'flex-1 flex justify-start items-center'}
            onClick={() => navigate(-1)}
          >
            <IconMdiChevronLeft color={'white'} fontSize={30} />
          </div>
        </div>

        <div className={'flex flex-col gap-2'}>
          <div className={'text-base text-white text-left text-balance'}>
            {t('paywall.pay.unlock.title')}
          </div>
          <div
            className={'font-bold text-xl text-white text-left text-balance'}
          >
            {t('paywall.pay.unlock.subtitle')}
          </div>
        </div>

        <div className={'flex flex-col gap-10 text-white'}>
          <div className={'flex flex-col gap-8 items-start'}>
            <PaywallDescription
              description={t(`paywall.pay.unlock.item1.description`)}
              IconComponent={StarIcon}
              color={'white'}
            />
            <PaywallDescription
              description={t(`paywall.pay.unlock.item2.description`)}
              IconComponent={GameControllerIcon}
              color={'white'}
            />
            <PaywallDescription
              description={t(`paywall.pay.unlock.item3.description`)}
              IconComponent={ThumbUpIcon}
              color={'white'}
            />
          </div>

          <div className={'flex flex-col gap-4'}>
            {ambassadorCodeValid ? null : (
              <AmbassadorCodeButton
                onValidate={onValidateAmbassadorCode}
                className={'text-white'}
              />
            )}

            <Button
              data-testid="btn-contact-us"
              type={'link'}
              size={'small'}
              className={'text-white'}
              onClick={openWhatsAppSupport}
            >
              {t(`paywall.pay.${user.user_type}.contactUs`)}
            </Button>
          </div>
        </div>
      </div>
      <Footer className={'rounded-t-3xl'}>
        <div className={'flex flex-col gap-6 px-5 pt-5'}>
          <OfferSelector
            placement={placement}
            onSelectPackage={setSelectedPurchasePackage}
            selectedPackage={selectedPurchasePackage}
          />

          <Button
            data-testid={'btn-pay-trial'}
            type={'primary'}
            onClick={handlePay}
            loading={paying}
          >
            {t('paywall.pay.button')}
          </Button>

          <PaywallCGU />
          <ShowDeviceInfoButton />
        </div>
      </Footer>
    </>
  );
};

export default PaywallScreenPayUnlock;
