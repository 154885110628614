import dayjs from 'dayjs';

export const formatPrice = (price: number) => {
  return new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
  }).format(price);
};

export const getNbTrialDays = (introPrice: string) => {
  return dayjs.duration(introPrice).asDays();
};
