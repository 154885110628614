import React, { useLayoutEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { registerSW } from 'virtual:pwa-register';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import { App as AntApp, ConfigProvider } from 'antd';
import frFR from 'antd/locale/fr_FR';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { initSentry, reportError } from 'src/modules/logs/Sentry';

import './App.css';
import App from './App';
import { initTranslation } from 'src/modules/i18n';
import { queryClient } from 'src/queries/base';
import AuthContextProvider from 'src/contexts/AuthContext';
import { antdTheme } from 'src/styles/theme';

import 'unfonts.css';
import 'src/styles/main.scss';
import NotificationContextProvider from './contexts/NotificationContext';
import { initAnalytics } from 'src/modules/analytics/Amplitude';
import { initAutoUpdater } from 'src/modules/auto-updater';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Capacitor } from '@capacitor/core';
import FacebookPixel from 'src/modules/analytics/FacebookPixel';
import '@capgo/camera-preview';
import { initAxios } from 'src/modules/axios';
import { SplashScreen } from '@capacitor/splash-screen';
import { CapacitorUpdater } from '@capgo/capacitor-updater';
import InstallApp from 'src/pages/install';
import TermsAndConsitionsPage from 'src/pages/terms-and-conditions';
import ConfidentialitePage from 'src/pages/confidentialite';
import { initInAppPurchase } from 'src/modules/purchase/RevenueCat';
import { initSupport } from 'src/modules/support/support';
import DebugShakeListener from 'src/components/ShakeListener/DebugShakeListener';
import TikTokPixel from 'src/modules/analytics/TikTokPixel';
import { initPushNotifications } from 'src/modules/push-notifications/push-notifications';

const RootAppNative = () => {
  const [isReady, setIsReady] = useState(false);
  const shouldUpdateNativeApp = useRef(false);

  const initApp = async () => {
    setIsReady(false);
    try {
      await SplashScreen.show({
        autoHide: false,
      });

      const autoUpdateResult = await initAutoUpdater();
      shouldUpdateNativeApp.current = autoUpdateResult.shouldUpdateNativeApp;
      await defineCustomElements(window);
      await initTranslation();
      initAxios();
      await initSupport();
      await initPushNotifications();
      await initAnalytics();
      await initInAppPurchase();

      if (Capacitor.getPlatform() === 'android') {
        await StatusBar.setBackgroundColor({ color: '#ffffff' });
        await StatusBar.setStyle({ style: Style.Light });
      }

      // Periodic updates of Service workers, and hourly check
      registerSW({
        onRegistered(r) {
          r &&
            setInterval(
              () => {
                r.update();
              },
              60 * 60 * 1000,
            );
        },
      });

      if (
        import.meta.env.MODE === 'production' ||
        import.meta.env.MODE === 'staging'
      ) {
        initSentry();
      }

      await CapacitorUpdater.notifyAppReady();
    } catch (error) {
      reportError('Error while initializing the app', error);
    }
    setIsReady(true);
    await SplashScreen.hide();
  };

  useLayoutEffect(() => {
    initApp();
  }, []);

  if (!isReady) {
    return null;
  }

  return (
    <React.StrictMode>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <ConfigProvider theme={antdTheme} locale={frFR}>
            <AntApp>
              <NotificationContextProvider>
                <AuthContextProvider>
                  <App shouldUpdateNativeApp={shouldUpdateNativeApp.current} />
                  <FacebookPixel />
                  <TikTokPixel />
                  <DebugShakeListener />
                </AuthContextProvider>
              </NotificationContextProvider>
            </AntApp>
          </ConfigProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
};

const RootAppWeb = () => {
  const [isReady, setIsReady] = useState(false);

  const initApp = async () => {
    setIsReady(false);
    await initTranslation();
    setIsReady(true);
  };
  useLayoutEffect(() => {
    initApp();
  }, []);

  if (!isReady) {
    return null;
  }

  return (
    <React.StrictMode>
      <BrowserRouter>
        <ConfigProvider theme={antdTheme} locale={frFR}>
          <AntApp>
            <Routes>
              <Route path="/" element={<InstallApp />} />
              <Route
                path="/terms-and-conditions"
                element={<TermsAndConsitionsPage />}
              />
              <Route
                path="/confidentialite"
                element={<ConfidentialitePage />}
              />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </AntApp>
        </ConfigProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
};

const Root = () => {
  if (
    Capacitor.isNativePlatform() ||
    import.meta.env.DEV ||
    import.meta.env.VITE_CI
  ) {
    return <RootAppNative />;
  }
  return <RootAppWeb />;
};

const root = ReactDOM.createRoot(document.getElementById('react-app')!);
root.render(<Root />);
