import {
  useGetLessonSmartbits,
  useSeenLessonSmartbits,
} from 'src/queries/lesson_smartbits';
import Spinner from 'src/components/common/designSystem/Spinner';
import { useLesson } from 'src/components/LessonDetail/LessonContext';
import { Swiper, type SwiperRef } from 'antd-mobile';
import { MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import BlankPrimaryLayout from 'src/layouts/BlankPrimaryLayout';
import BlankWhiteLayout from 'src/layouts/BlankWhiteLayout';
import LessonSmartbit from 'src/components/LessonDetail/LessonSmartbits/LessonSmartbit';
import LessonSmartbitsEndScreen from 'src/components/LessonDetail/LessonSmartbits/LessonSmartbitsEndScreen';
import LessonSmartbitsIndicator from 'src/components/LessonDetail/LessonSmartbits/LessonSmartbitsIndicator';
import { addLocalStorageXPWon } from 'src/utils/usersUtils';
import LessonEmpty from 'src/components/LessonDetail/LessonEmpty';

import { reportError } from 'src/modules/logs/Sentry';

const LessonDetailSmartbits = () => {
  const { lesson } = useLesson();
  const { data: lessonSmartbits, isLoading: lessonSmartbitsIsLoading } =
    useGetLessonSmartbits(lesson.id);
  const { mutateAsync: seenLessonSmartbitsMutation, isIdle } =
    useSeenLessonSmartbits(lesson.id);
  const swiperRef = useRef<SwiperRef>(null);
  const hasEnded = useRef(false);
  const [currentSmartbitIndex, setCurrentSmartbitIndex] = useState(0);

  const smartbits: { type: string; content: string }[] | undefined =
    lessonSmartbits?.flatMap(smartbit => {
      if (Array.isArray(smartbit.content)) {
        return smartbit.content.map(content => ({
          type: smartbit.smartbit_type,
          content,
        }));
      }
      return {
        type: smartbit.content.type,
        content: smartbit.content.objective,
      };
    });
  const isLastItem = smartbits
    ? currentSmartbitIndex === smartbits.length
    : false;

  const seenLessonSmartbits = useCallback(async () => {
    if (!isIdle) {
      return;
    }
    try {
      const xp = await seenLessonSmartbitsMutation();
      addLocalStorageXPWon(xp);
    } catch (error) {
      reportError('Fail to update lesson smartbit seen', error);
    }
  }, [isIdle, seenLessonSmartbitsMutation]);

  useEffect(() => {
    if (isLastItem) {
      hasEnded.current = true;
      seenLessonSmartbits();
    }
  }, [isLastItem, seenLessonSmartbits]);

  useEffect(() => {
    if (
      hasEnded.current &&
      smartbits &&
      currentSmartbitIndex < smartbits.length
    ) {
      swiperRef.current?.swipeTo(smartbits.length);
    }
  }, [currentSmartbitIndex, smartbits]);

  if (lessonSmartbitsIsLoading) {
    return <Spinner className="w-full h-full" />;
  }

  if (!smartbits || !smartbits.length) {
    return <LessonEmpty />;
  }

  const Layout =
    isLastItem || hasEnded.current ? BlankWhiteLayout : BlankPrimaryLayout;

  const onClickItem = (event: MouseEvent<HTMLDivElement>) => {
    const swiper = swiperRef.current;
    if (swiper) {
      if (isLastItem) {
        return;
      }
      if (event.clientX < window.innerWidth / 2) {
        swiper.swipePrev();
        return;
      }
      swiper.swipeNext();
    }
  };

  const items = smartbits.map((smartbit, index) => (
    <Swiper.Item key={index} onClick={onClickItem} className={'flex'}>
      <LessonSmartbit type={smartbit.type} content={smartbit.content} />
    </Swiper.Item>
  ));
  items.push(
    <Swiper.Item key={'end'} className={'bg-white flex'}>
      <LessonSmartbitsEndScreen total={smartbits.length} />
    </Swiper.Item>,
  );

  return (
    <Layout>
      <Swiper
        className={`w-full h-full`}
        ref={swiperRef}
        onIndexChange={(index: number) => {
          setCurrentSmartbitIndex(index);
        }}
        defaultIndex={currentSmartbitIndex}
        direction="horizontal"
        indicator={(total, current) => (
          <LessonSmartbitsIndicator total={total} current={current} />
        )}
      >
        {...items}
      </Swiper>
    </Layout>
  );
};

export default LessonDetailSmartbits;
