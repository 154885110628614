import { Lesson, LessonSubject } from 'src/types/lesson.types';

export const lessonIsProcessing = (lesson?: Lesson) => {
  if (!lesson) return false;

  return lesson.lesson_pieces?.some(
    piece =>
      !piece.post_process_completed &&
      (piece.transcript_job_id || piece.transcript_job_id),
  );
};

export const getAddLessonPieceURL = (
  lessonId: number,
  subject: LessonSubject,
): string => {
  switch (subject) {
    case LessonSubject.geography:
    case LessonSubject.history:
      return `/lessons/${lessonId}/pieces/audio`;
    case LessonSubject.sciences:
    case LessonSubject.physics:
    case LessonSubject.mathematics:
    case LessonSubject.english:
    case LessonSubject.spanish:
    case LessonSubject.german:
    case LessonSubject.french:
    case LessonSubject.technology:
      return `/lessons/${lessonId}/pieces/photo`;
  }
};
