import LayoutWithHeader from 'src/layouts/LayoutWithHeader';
import { useNavigate, useParams } from 'react-router-dom';
import OnboardingHeader from 'src/components/Onboarding/OnboardingHeader';
import StatusBar from 'src/components/common/StatusBar';
import { Style } from '@capacitor/status-bar/dist/esm/definitions';
import ParentChildSchoolGroupStep from 'src/components/Onboarding/ParentChildSchoolGroupStep';
import { SchoolGroup } from 'src/types/user.types';

const ParentChildSchoolGroup = () => {
  const navigate = useNavigate();
  const { childId } = useParams();

  const onSelect = (schoolGroup: SchoolGroup) => {
    // Store the selected school group in search params for the next step
    const searchParams = new URLSearchParams();
    searchParams.set('schoolGroup', schoolGroup);
    navigate(`/child/${childId}/school_level?${searchParams.toString()}`);
  };

  return (
    <LayoutWithHeader
      header={<OnboardingHeader step={3} />}
      statusBar={<StatusBar color="#FAFAFB" style={Style.Light} />}
      className={'bg-structural-lightest'}
    >
      <ParentChildSchoolGroupStep onSelect={onSelect} />
    </LayoutWithHeader>
  );
};

export default ParentChildSchoolGroup;
