import { useTranslation } from 'react-i18next';
import Button from 'src/components/common/designSystem/Button';

const ErrorPaymentTooExpensive = ({
  onShowMensualOffer,
}: {
  onShowMensualOffer: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <div className={'flex flex-col gap-4'}>
      <div className={'text-2xl font-bold'}>
        {t('paywall.error_payment.too_expensive.title')}
      </div>

      <div className={'text-base mb-2 flex flex-col gap-2'}>
        <div>{t('paywall.error_payment.too_expensive.description1')}</div>
        <div>{t('paywall.error_payment.too_expensive.description2')}</div>
      </div>

      <Button
        type={'primary'}
        onClick={onShowMensualOffer}
        className={'w-full'}
        data-testid={'btn-show-mensual-offer'}
      >
        {t('paywall.error_payment.too_expensive.button')}
      </Button>
    </div>
  );
};

export default ErrorPaymentTooExpensive;
