import StoreStarsIcon from 'src/images/stars-review.svg?react';

const PaywallReview = ({ content }: { content: string }) => {
  return (
    <div className={'flex flex-col gap-2'}>
      <StoreStarsIcon />
      <div className={'text-base text-balance'}>{content}</div>
    </div>
  );
};

export default PaywallReview;
