import axios from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { User, UserToCreate } from 'src/types/user.types';

export const useGetUsers = () => {
  return useQuery<User[]>({
    queryKey: ['users'],
    refetchOnMount: 'always',
  });
};

export const useCreateUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (user: Partial<UserToCreate>): Promise<User> => {
      const { data } = await axios.post('/api/users', user);
      return data;
    },
    onSuccess: async () => {
      await queryClient.refetchQueries(['users']);
    },
  });
};

export const useUpdateUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (user: Partial<User>): Promise<User> => {
      const { data } = await axios.put(`/api/users/${user.id}`, user);
      return data;
    },
    onSuccess: async () => {
      await queryClient.refetchQueries(['users']);
    },
  });
};

export const useDeleteUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (): Promise<void> => {
      const { data } = await axios.delete('/api/user');
      return data;
    },
    onSuccess: () => {
      queryClient.setQueryData(['user'], undefined);
      queryClient.removeQueries();
      queryClient.clear();
    },
  });
};

export const useDeleteFamilyUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (userUuid: string): Promise<void> => {
      await axios.delete(`/api/users/${userUuid}`);
    },
    onSuccess: async () => {
      await queryClient.refetchQueries(['users']);
    },
  });
};
