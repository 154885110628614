import { useTranslation } from 'react-i18next';
import RadioList from 'src/components/common/designSystem/RadioList';

const ErrorPaymentOptions = ({
  selectedAnswer,
  onSelect,
  isMensualOfferShown,
}: {
  selectedAnswer?: string;
  onSelect: (value: string) => void;
  isMensualOfferShown: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <div className={'flex flex-col gap-4'}>
      <div className={'text-2xl font-bold'}>
        {t('paywall.error_payment.options.title')}
      </div>
      <RadioList
        selected={selectedAnswer}
        onSelect={onSelect}
        options={
          isMensualOfferShown
            ? [
                {
                  type: 'value',
                  label: t('paywall.error_payment.options.error'),
                  value: 'error',
                },
                {
                  type: 'value',
                  label: t('paywall.error_payment.options.other'),
                  value: 'other',
                },
              ]
            : [
                {
                  type: 'value',
                  label: t('paywall.error_payment.options.error'),
                  value: 'error',
                },
                {
                  type: 'value',
                  label: t('paywall.error_payment.options.too_expensive'),
                  value: 'too_expensive',
                },
                {
                  type: 'value',
                  label: t('paywall.error_payment.options.other'),
                  value: 'other',
                },
              ]
        }
      />
    </div>
  );
};

export default ErrorPaymentOptions;
