import { Lesson } from 'src/types/lesson.types';
import { useTranslation } from 'react-i18next';
import { useNotification } from 'src/contexts/NotificationContext';
import { reportError } from 'src/modules/logs/Sentry';
import Button from 'src/components/common/designSystem/Button';
import { Share } from '@capacitor/share';
import { logAnalyticsEvent } from 'src/modules/analytics/Amplitude';
import { isCancelError } from 'src/utils/share';
import { User } from 'src/types/user.types';

const LessonShareParentChallengePanel = ({
  lesson,
  challengeLink,
  onClose,
  parent,
}: {
  lesson: Lesson;
  challengeLink: string;
  onClose: () => void;
  parent: User;
}) => {
  const { t } = useTranslation();
  const { showError } = useNotification();

  const handleShare = async () => {
    try {
      const { activityType } = await Share.share({
        title: t('challenge.parent.share.message.title', {
          lessonTitle: lesson.title,
        }),
        text: t('challenge.parent.share.message.title', {
          lessonTitle: lesson.title,
        }),
        url: challengeLink,
      });
      logAnalyticsEvent('parent_challenge_shared', {
        activity_type: activityType,
      });
    } catch (error) {
      if (isCancelError(error)) {
        return;
      }
      reportError('Fail to share parent challenge link', error);
      showError({
        message: t('challenge.parent.share.error'),
        error,
      });
    }
  };

  return (
    <div className={'flex items-start flex-col gap-5 p-6 text-left'}>
      <div className={'text-7xl'}>🎯</div>
      <div className={'font-bold text-balance text-2xl'}>
        {t('challenge.parent.share.title', { parentName: parent.firstname })}
      </div>
      <div className={'text-base mb-2 flex flex-col gap-2'}>
        <div>{t('challenge.parent.share.description1')}</div>
        <div>{t('challenge.parent.share.description2')}</div>
      </div>
      <div className={'flex flex-col gap-2 w-full'}>
        <Button data-testid="btn-skip" type={'default'} onClick={onClose}>
          {t('global.skip')}
        </Button>
        <Button
          data-testid="btn-share-challenge-parent"
          type={'primary'}
          onClick={handleShare}
        >
          {t('challenge.parent.share.button')}
        </Button>
      </div>
    </div>
  );
};

export default LessonShareParentChallengePanel;
