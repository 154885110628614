import { Trans, useTranslation } from 'react-i18next';
import Button from 'src/components/common/designSystem/Button';
import { usePaywall } from 'src/contexts/PaywallContext';
import PrimaryCheckIcon from 'src/components/common/PrimaryCheckIcon';
import { useNavigate } from 'react-router-dom';
import {
  PURCHASES_ERROR_CODE,
  PurchasesPackage,
} from '@revenuecat/purchases-capacitor';
import { useState } from 'react';
import { useNotification } from 'src/contexts/NotificationContext';
import PaywallCGU from 'src/components/Paywall/PaywallCGU';
import { openWhatsAppSupport } from 'src/modules/support/support';
import AskParentPaymentButton from 'src/components/Invitation/AskParentPaymentButton';
import DuePricing from 'src/components/Paywall/DuePricing';
import { reportError } from 'src/modules/logs/Sentry';
import IconMdiChevronLeft from '~icons/mdi/chevron-left';
import { RevenueCatPlacement } from 'src/modules/purchase/purchase.constants';
import { useCurrentUser } from 'src/queries/user';
import OfferSelector from 'src/components/Paywall/Offers/OfferSelector';
import { logAnalyticsEvent } from 'src/modules/analytics/Amplitude';
import { getNbTrialDays } from 'src/utils/price';
import Footer from 'src/components/common/designSystem/Footer';
import ShowDeviceInfoButton from 'src/components/Paywall/ShowDeviceInfo';

const PaywallScreenPayChild = () => {
  const { t } = useTranslation();
  const { subscribePremium } = usePaywall();
  const navigate = useNavigate();
  const [paying, setPaying] = useState(false);
  const [selectedPurchasePackage, setSelectedPurchasePackage] =
    useState<PurchasesPackage>();
  const { showError } = useNotification();
  const { data: user } = useCurrentUser();

  const pay = async () => {
    if (!selectedPurchasePackage) {
      return;
    }
    try {
      setPaying(true);
      await subscribePremium(selectedPurchasePackage);
      logAnalyticsEvent('trial_started', {
        is_paid: true,
      });
    } catch (error: any) {
      if (error.code === PURCHASES_ERROR_CODE.PURCHASE_CANCELLED_ERROR) {
        setPaying(false);
        return;
      }

      reportError('Fail to subscribe premium child with trial', error);
      showError({
        error,
        message: t('paywall.error'),
      });
    }
    setPaying(false);
  };

  if (!user) {
    return null;
  }

  return (
    <div className={'flex flex-col p-5 gap-6'}>
      <div className={'flex flex-row justify-start items-center'}>
        <div
          className={'flex-1 flex justify-start items-center'}
          onClick={() => navigate(-1)}
        >
          <IconMdiChevronLeft fontSize={30} />
        </div>
      </div>

      <div
        className={
          'font-extrabold text-xl text-structural-darkest text-left text-balance'
        }
      >
        <Trans i18nKey="paywall.pay.title">
          <span
            className={
              'bg-gradient-to-r bg-clip-text text-transparent from-paywall-darkest-from to-paywall-darkest-to'
            }
          >
            Essai gratuit
          </span>{' '}
          de Baobab
        </Trans>
      </div>

      <div className={'flex flex-col gap-2 items-start'}>
        <div className={'flex flex-row items-start justify-start gap-3'}>
          <div>
            <PrimaryCheckIcon />
          </div>
          <div className={'text-balance text-base text-structural-darkest'}>
            {t(`paywall.pay.${user.user_type}.item1`, {
              nbTrialDays: selectedPurchasePackage?.product.introPrice
                ? getNbTrialDays(
                    selectedPurchasePackage.product.introPrice.period,
                  )
                : 7,
            })}
          </div>
        </div>
      </div>

      <OfferSelector
        placement={RevenueCatPlacement.ONBOARDING_CHILD}
        onSelectPackage={setSelectedPurchasePackage}
        selectedPackage={selectedPurchasePackage}
      />
      <DuePricing purchasePackage={selectedPurchasePackage} />

      <Button
        data-testid="btn-contact-us"
        type={'link'}
        onClick={openWhatsAppSupport}
      >
        {t(`paywall.pay.${user.user_type}.contactUs`)}
      </Button>

      <PaywallCGU />
      <ShowDeviceInfoButton />

      <Footer>
        <div className={'flex flex-col w-full gap-2'}>
          <AskParentPaymentButton />
          <Button
            data-testid={'btn-pay-trial'}
            type={'primary'}
            onClick={pay}
            loading={paying}
          >
            {t('paywall.pay.button')}
          </Button>
        </div>
      </Footer>
    </div>
  );
};

export default PaywallScreenPayChild;
