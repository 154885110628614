import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { getCurrentUserIdToken } from 'src/modules/firebase';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { useAuth } from 'src/hooks/useAuth';
import { AuthMode } from 'src/contexts/AuthContext';
import { updateLocalStorageUserID } from 'src/utils/usersUtils';
import { User } from 'src/types/user.types';
import { useNavigate } from 'react-router-dom';

export const useSignupUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      newUserEmail,
      anonymous_token,
      is_anonymous,
      referral_code,
    }: {
      newUserEmail: string | null;
      anonymous_token?: string;
      is_anonymous?: boolean;
      referral_code?: string;
    }) => {
      const { data } = await axios.post<User>('/api/sessions/signup', {
        email: newUserEmail,
        firebase_token: await getCurrentUserIdToken(),
        anonymous_token,
        is_anonymous,
        referral_code,
      });
      return data;
    },
    onSuccess: newUser => {
      queryClient.setQueryData(['user'], newUser);
    },
  });
};

export const useOnlogin = () => {
  return useMutation({
    mutationFn: async ({ anonymous_token }: { anonymous_token?: string }) => {
      const { data } = await axios.post<User>('/api/sessions/onlogin', {
        anonymous_token,
      });
      return data;
    },
  });
};

export const useSwitchUser = () => {
  const { setMode } = useAuth();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (userId: number) => {
      const { data: customToken } = await axios.post(
        '/api/sessions/switch',
        null,
        { params: { user_id: userId } },
      );
      return customToken as string;
    },
    onSuccess: async (data, userId) => {
      setMode(AuthMode.LOGIN);
      updateLocalStorageUserID(userId);
      await FirebaseAuthentication.signInWithCustomToken({ token: data });
      await queryClient.refetchQueries('user');
      queryClient.clear();
      navigate('/');
    },
  });
};
