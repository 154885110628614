import { useEffect, useRef, useState } from 'react';
import { Progress } from 'antd';

import { QuestionStatus, useQuiz } from 'src/components/Quiz/QuizContext';
import { Lesson, LessonSubject } from 'src/types/lesson.types';
import { Question, QuestionType } from 'src/types/quiz.types';

const timeIncrements = 100;

const getTimeAllowed = (question?: Question, lesson?: Lesson) => {
  if (!question) {
    return 0;
  }
  if (question.type === QuestionType.matching) {
    return question.correct.length * 10 * 1000;
  } else if (
    lesson?.subject_matter &&
    (lesson.subject_matter === LessonSubject.english ||
      lesson.subject_matter === LessonSubject.spanish ||
      lesson.subject_matter === LessonSubject.german ||
      lesson.subject_matter === LessonSubject.french ||
      lesson.subject_matter === LessonSubject.technology)
  ) {
    return 30 * 1000;
  }
  return 20 * 1000;
};

export default function QuizProgressBar() {
  const {
    status,
    onTimeout,
    currentQuestion,
    lesson,
    currentQuestionIndex,
    totalQuestions,
  } = useQuiz();
  const timeAllowed = getTimeAllowed(currentQuestion, lesson);
  const [timeLeft, setTimeLeft] = useState(timeAllowed);
  const currentTimeout = useRef<number | null>();
  const timeProgression = (timeLeft / timeAllowed) * 100;

  useEffect(() => {
    stopTimeout();
    setTimeLeft(timeAllowed);
  }, [currentQuestion?.id, timeAllowed]);

  const stopTimeout = () => {
    if (!currentTimeout.current) {
      return;
    }
    clearTimeout(currentTimeout.current);
    currentTimeout.current = undefined;
  };

  useEffect(() => {
    if (status === QuestionStatus.WAITING)
      if (timeLeft > 0) {
        stopTimeout();
        currentTimeout.current = window.setTimeout(() => {
          setTimeLeft(prev => prev - timeIncrements);
        }, timeIncrements);
      } else if (timeLeft === 0 && currentTimeout.current) {
        onTimeout();
      }
  }, [timeLeft, onTimeout, status]);

  useEffect(() => {
    return () => {
      stopTimeout();
    };
  }, []);

  return (
    <div className={'flex flex-col w-full gap-1'}>
      <div
        className={
          'flex flex-row text-white justify-between font-semibold text-sm'
        }
      >
        <div>
          {currentQuestionIndex + 1}/{totalQuestions}
        </div>
        <div className={'text-sm'}>{Math.round(timeLeft / 1000)}s</div>
      </div>
      <Progress
        percent={timeProgression}
        showInfo={false}
        rootClassName={'h-1 leading-[0]'}
        size={['default', 4]}
        strokeColor={'white'}
        trailColor={'rgba(255,255,255,0.5)'}
        className="w-full"
      />
    </div>
  );
}
