import { Letter } from 'src/components/Quiz/WordPuzzleQuestion/QuizWordPuzzleQuestion';
import { Haptics } from '@capacitor/haptics';
import { ImpactStyle } from '@capacitor/haptics/dist/esm/definitions';

const QuizWordPuzzleSelectedLetter = ({
  letter,
  onClick,
  isCorrect,
  showResult,
}: {
  letter: Letter;
  isCorrect?: boolean;
  showResult?: boolean;
  onClick?: () => void;
}) => {
  const getClassname = () => {
    if (showResult) {
      if (isCorrect) {
        return 'bg-primary-lighter border-primary-lighter text-primary-darkest';
      }
      return 'bg-red-lightest border-red-lightest text-red-darkest';
    }
    return 'bg-primary-lighter border-primary-lighter text-primary-darkest';
  };
  const handleClick = () => {
    if (onClick) {
      Haptics.impact({ style: ImpactStyle.Medium });
      onClick();
    }
  };

  return (
    <span
      onClick={handleClick}
      className={`ml-1 my-1 inline-block align-middle rounded-lg text-center border-2 ${getClassname()}`}
    >
      <div
        className={
          'w-8 h-8 flex items-center justify-center text-xl font-semibold '
        }
      >
        {letter.letter.toUpperCase()}
      </div>
    </span>
  );
};

export default QuizWordPuzzleSelectedLetter;
