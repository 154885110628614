import { useEffect, useState } from 'react';
import { Haptics } from '@capacitor/haptics';
import { ImpactStyle } from '@capacitor/haptics/dist/esm/definitions';

const CounterAnimation = ({
  value,
  duration,
  initialValue = 0,
  offsetStartAnimation,
}: {
  value: number;
  duration: number;
  offsetStartAnimation?: number;
  initialValue?: number;
}) => {
  const [count, setCount] = useState(initialValue);

  const increment = ({
    value,
    initialValue,
    duration,
  }: {
    value: number;
    initialValue: number;
    duration: number;
  }) => {
    const intervalIncrement = Math.max(
      duration / (value - initialValue),
      duration / 100,
    );
    const countIncrement = Math.ceil(
      (value - initialValue) / (duration / intervalIncrement),
    );
    const interval = setInterval(() => {
      setCount(prevCount => {
        Haptics.impact({ style: ImpactStyle.Light });
        if (prevCount + countIncrement >= value) {
          clearInterval(interval);
          return value;
        }
        return Math.ceil(prevCount + countIncrement);
      });
    }, intervalIncrement);
  };

  useEffect(() => {
    if (initialValue === value) {
      return;
    }
    setCount(initialValue);

    if (offsetStartAnimation) {
      setTimeout(() => {
        increment({
          duration,
          initialValue,
          value,
        });
      }, offsetStartAnimation);
      return;
    }
    increment({
      duration,
      initialValue,
      value,
    });
  }, [initialValue, value, duration, offsetStartAnimation]);

  return <div className="inline-block">{count}</div>;
};

export default CounterAnimation;
