import LayoutWithHeader from 'src/layouts/LayoutWithHeader';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import OnboardingHeader from 'src/components/Onboarding/OnboardingHeader';
import StatusBar from 'src/components/common/StatusBar';
import { Style } from '@capacitor/status-bar/dist/esm/definitions';
import ParentChildGenderStep from 'src/components/Onboarding/ParentChildGenderStep';

const OnboardingParentChildGender = () => {
  const navigate = useNavigate();
  const { childId } = useParams();
  const [searchParams] = useSearchParams();
  const onNext = () => {
    navigate(
      `/onboarding/parent/child/${childId}/school_group?${searchParams.toString()}`,
    );
  };

  return (
    <LayoutWithHeader
      header={
        <OnboardingHeader
          step={3}
          from={`/onboarding/parent/child/firstname?${searchParams.toString()}`}
        />
      }
      statusBar={<StatusBar color="#FFFFFF" style={Style.Light} />}
      className={'bg-white'}
    >
      <ParentChildGenderStep childId={Number(childId)} onFinish={onNext} />
    </LayoutWithHeader>
  );
};

export default OnboardingParentChildGender;
