import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import PaywallScreenDescription from 'src/components/Paywall/PaywallScreenDescription';
import { useEffect } from 'react';
import { logAnalyticsEvent } from 'src/modules/analytics/Amplitude';
import PaywallScreenDescriptionVideo from 'src/components/Paywall/PaywallScreenDescriptionVideo';
import { useFlags } from 'src/contexts/FlagsContext';
import Footer from 'src/components/common/designSystem/Footer';
import Button from 'src/components/common/designSystem/Button';
import { useTranslation } from 'react-i18next';
import BlankWhiteLayout from 'src/layouts/BlankWhiteLayout';

const OnboardingParentChildPaywallDescription = () => {
  const { t } = useTranslation();
  const { childId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { display_video_paywall } = useFlags();

  useEffect(() => {
    logAnalyticsEvent('paywall_viewed', {
      type: display_video_paywall
        ? 'onboarding_video_parent'
        : 'onboarding_screen1_parent',
    });
  }, []);

  if (display_video_paywall) {
    return (
      <PaywallScreenDescriptionVideo
        onContinue={() => {
          navigate(
            `/onboarding/parent/child/${childId}/school_level/device/paywall/pay?${searchParams.toString()}`,
          );
        }}
      />
    );
  }

  return (
    <BlankWhiteLayout data-testid={'onboarding-paywall'}>
      <PaywallScreenDescription />

      <Footer>
        <Button
          data-testid={'btn-start-trial'}
          type={'primary'}
          onClick={() => {
            navigate(
              `/onboarding/parent/child/${childId}/school_level/device/paywall/pay?${searchParams.toString()}`,
            );
          }}
        >
          {t(`paywall.description.parent.button`)}
        </Button>
      </Footer>
    </BlankWhiteLayout>
  );
};

export default OnboardingParentChildPaywallDescription;
