import InAppLessonPieceProcessFailedNotifications from 'src/components/Notifications/InAppNotifications/InAppLessonPieceProcessFailedNotification';
import InAppQuizReadyNotification from 'src/components/Notifications/InAppNotifications/InAppQuizReadyNotification';
import {
  IN_APP_NOTIFICATIONS,
  InAppNotification,
  InAppNotificationType,
} from 'src/types/notification.types';
import InAppReferralRewardNotification from 'src/components/Notifications/InAppNotifications/InAppReferralRewardNotification';
import InAppAskAppReviewNotifications from 'src/components/Notifications/InAppNotifications/InAppAskAppReviewNotification';
import InAppAskOutAppNotificationOptinNotification from 'src/components/Notifications/InAppNotifications/InAppAskOutAppNotificationOptinNotification';
import InAppMilestoneReachedNotification from 'src/components/Notifications/InAppNotifications/InAppMilestoneReachedNotification';
import { usePushNotification } from 'src/contexts/PushNotificationContext';
import { useCallback, useEffect, useState } from 'react';
import InAppXPBoosterNotification from 'src/components/Notifications/InAppNotifications/InAppXPBoosterNotification';

const InAppNotifications = () => {
  const { unreadNotifications, handleNotificationRead } = usePushNotification();
  const [currentNotification, setCurrentNotification] =
    useState<InAppNotification>();

  useEffect(() => {
    if (currentNotification) {
      if (
        unreadNotifications.find(
          notification => notification.id === currentNotification.id,
        )
      ) {
        return;
      }
      setCurrentNotification(undefined);
    }

    if (!unreadNotifications.length) {
      return;
    }
    const unreadNotification = unreadNotifications.find(notification =>
      IN_APP_NOTIFICATIONS.includes(notification.type),
    );
    setCurrentNotification(unreadNotification);
  }, [currentNotification, unreadNotifications]);

  const onRead = useCallback(async () => {
    if (currentNotification) {
      await handleNotificationRead(currentNotification.id);
    }
  }, [currentNotification, handleNotificationRead]);

  if (!currentNotification) {
    return null;
  }

  switch (currentNotification.type) {
    case InAppNotificationType.LESSON_PIECE_PROCESS_FAIL:
      return (
        <InAppLessonPieceProcessFailedNotifications
          notification={currentNotification}
          onRead={onRead}
        />
      );
    case InAppNotificationType.QUIZ_READY:
      return (
        <InAppQuizReadyNotification
          notification={currentNotification}
          onRead={onRead}
        />
      );
    case InAppNotificationType.REFERRAL_REWARD:
      return (
        <InAppReferralRewardNotification
          notification={currentNotification}
          onRead={onRead}
        />
      );
    case InAppNotificationType.ASK_APP_REVIEW:
      return (
        <InAppAskAppReviewNotifications
          notification={currentNotification}
          onRead={onRead}
        />
      );
    case InAppNotificationType.ASK_NOTIFICATION_OPTIN:
      return (
        <InAppAskOutAppNotificationOptinNotification
          notification={currentNotification}
          onRead={onRead}
        />
      );
    case InAppNotificationType.MILESTONE_REACHED:
      return (
        <InAppMilestoneReachedNotification
          notification={currentNotification}
          onRead={onRead}
        />
      );
    case InAppNotificationType.XP_BOOSTER_ACTIVATED:
      return (
        <InAppXPBoosterNotification
          notification={currentNotification}
          onRead={onRead}
        />
      );
    default:
      return null;
  }
};

export default InAppNotifications;
