import ChildSchoolLevelStep from 'src/components/Onboarding/ChildSchoolLevelStep';
import { SchoolGroup } from 'src/types/user.types';

const ParentChildSchoolLevelStep = ({
  childId,
  onFinish,
  schoolGroup,
}: {
  childId: number;
  onFinish: () => void;
  schoolGroup: SchoolGroup;
}) => {
  return (
    <ChildSchoolLevelStep
      childId={childId}
      onFinish={onFinish}
      schoolGroup={schoolGroup}
    />
  );
};

export default ParentChildSchoolLevelStep;
