import { useGetNotifications } from 'src/queries/notifications';
import { NOTIFICATIONS_SHOWN_IN_LIST } from 'src/types/notification.types';
import { useMemo } from 'react';

const useGetNotificationsInList = () => {
  const { data: notifications, isLoading } = useGetNotifications();

  const notificationToShowInList = useMemo(() => {
    if (!notifications) {
      return [];
    }

    return notifications?.pages
      .flatMap(page => page.items)
      ?.filter(
        notification =>
          !notification.disabled &&
          NOTIFICATIONS_SHOWN_IN_LIST.includes(notification.type),
      );
  }, [notifications]);

  return { notifications: notificationToShowInList, isLoading };
};

export default useGetNotificationsInList;
