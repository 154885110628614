import { useGetChallenges } from 'src/queries/challenge';
import { useTranslation } from 'react-i18next';
import { User } from 'src/types/user.types';
import ChildChallengeCard from 'src/components/UserManagement/Challenges/ChildChallengeCard';

const ChildChallenges = ({ child }: { child: User }) => {
  const { t } = useTranslation();
  const { data: challenges } = useGetChallenges();
  const childChallenges = challenges?.filter(
    challenge => challenge.created_by.id === child.id,
  );

  if (!childChallenges?.length) {
    return null;
  }

  return (
    <>
      <div className={'font-bold text-xl'}>
        {t('settings.users.challenges.title', {
          firstname: child.firstname,
        })}
      </div>
      {childChallenges.map(challenge => (
        <ChildChallengeCard challenge={challenge} key={challenge.id} />
      ))}
    </>
  );
};

export default ChildChallenges;
