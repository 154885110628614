import { useTranslation } from 'react-i18next';
import { usePaywall } from 'src/contexts/PaywallContext';
import { MONTHLY_OFFERS } from 'src/modules/purchase/purchase.constants';
import PromoBadge from 'src/components/Paywall/Offers/PromoBadge';
import { formatPrice } from 'src/utils/price';

const MonthlyOffer = ({
  isSelected = true,
  promo,
}: {
  isSelected?: boolean;
  promo?: string;
}) => {
  const { t } = useTranslation();
  const { promoCodes } = usePaywall();

  const promoToApply = promoCodes.find(promoCode =>
    MONTHLY_OFFERS.includes(promoCode.offering_id),
  );

  return (
    <div className={'relative'}>
      {promoToApply ? (
        <PromoBadge
          discount={t('paywall.panel.annual.promo', {
            promo: promoToApply.discount,
          })}
        />
      ) : promo ? (
        <PromoBadge discount={promo} />
      ) : null}
      <div
        data-testid={'radio-monthly'}
        className={`w-full items-center ${isSelected ? 'bg-gradient-to-r' : 'bg-structural-lighter'}  from-paywall-darkest-from to-paywall-darkest-to rounded-xl p-1`}
      >
        <div
          className={
            'flex flex-row justify-between w-full h-full items-center bg-white rounded-lg p-4 px-4 gap-2'
          }
        >
          <div className={'flex flex-row gap-4 items-center justify-center'}>
            <div
              className={`rounded-full flex justify-center items-center w-6 h-6 ${isSelected ? 'bg-gradient-to-r' : 'bg-structural-lighter'} from-paywall-darkest-from to-paywall-darkest-to`}
            >
              <div className={'rounded-full w-2 h-2 bg-white'} />
            </div>
            <div className={'flex flex-col'}>
              <div
                className={
                  'font-extrabold text-lg leading-none text-structural-darker'
                }
              >
                {t('paywall.panel.type')}
              </div>
            </div>
          </div>
          <div className={'flex flex-col text-right'}>
            <div
              className={
                'font-extrabold text-lg leading-none text-structural-darker'
              }
            >
              {promoToApply ? formatPrice(promoToApply.price_eur) : '7.99€'}
            </div>
            <div className={'text-base leading-none text-structural-darker'}>
              {t('paywall.panel.time')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MonthlyOffer;
