import { useTranslation } from 'react-i18next';
import { AutoFloatingPanel } from 'src/components/common/AutoFloatingPanel';
import PayButton from 'src/components/Paywall/PayButton';
import Button from 'src/components/common/designSystem/Button';
import { useEffect, useState } from 'react';
import { logAnalyticsEvent } from 'src/modules/analytics/Amplitude';
import AskParentButton from 'src/components/Paywall/AskParentButton';
import PaywallCGU from 'src/components/Paywall/PaywallCGU';
import { useCurrentUser } from 'src/queries/user';
import { openWhatsAppSupport } from 'src/modules/support/support';
import ParentSellArguments from 'src/components/Paywall/ParentSellArguments';
import OfferSelector from 'src/components/Paywall/Offers/OfferSelector';
import { RevenueCatOffering } from 'src/modules/purchase/purchase.constants';

const PaywallPanel = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const { data: user } = useCurrentUser();
  const [selectedOffer, setSelectedOffer] = useState<
    | RevenueCatOffering.ANNUAL_WITHOUT_TRIAL
    | RevenueCatOffering.MONTHLY_WITHOUT_TRIAL
  >(RevenueCatOffering.ANNUAL_WITHOUT_TRIAL);

  useEffect(() => {
    if (isOpen) {
      logAnalyticsEvent('paywall_viewed', {
        type: 'blocking_trial_ended',
      });
      logAnalyticsEvent('trial_ended');
    }
  }, [isOpen]);

  if (!user) {
    return null;
  }

  return (
    <AutoFloatingPanel
      data-testid={'panel-paywall-locked'}
      isOpen={isOpen}
      onClose={onClose}
      priority={1}
    >
      <div
        className={'flex flex-col items-start justify-center py-4 px-4 gap-4'}
      >
        <div
          className={'flex-1 flex justify-start items-center'}
          onClick={onClose}
          data-testid={'btn-skip-paywall'}
        >
          <IconMdiChevronLeft fontSize={30} />
        </div>

        <div
          className={
            'font-extrabold text-lg text-structural-darker text-left text-balance'
          }
        >
          {t(`paywall.locked.title.${user.user_type}`)}
        </div>

        <ParentSellArguments />
        <OfferSelector
          selectedOffer={selectedOffer}
          onSelectAnnualOffer={() =>
            setSelectedOffer(RevenueCatOffering.ANNUAL_WITHOUT_TRIAL)
          }
          onSelectMonthlyOffer={() =>
            setSelectedOffer(RevenueCatOffering.MONTHLY_WITHOUT_TRIAL)
          }
        />

        <div
          className={'flex flex-col items-center justify-center gap-2 w-full'}
        >
          <AskParentButton />
          <PayButton offering={selectedOffer}>
            {t('paywall.locked.button')}
          </PayButton>
          <Button
            data-testid="btn-contact-us"
            type={'link'}
            onClick={openWhatsAppSupport}
          >
            {t(`paywall.pay.${user.user_type}.contactUs`)}
          </Button>
          <PaywallCGU />
        </div>
      </div>
    </AutoFloatingPanel>
  );
};

export default PaywallPanel;
