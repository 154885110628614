export enum UserType {
  parent = 'parent',
  child = 'child',
}

export enum SchoolGroup {
  Primaire = 'primaire',
  College = 'college',
  Lycee = 'lycee',
}

export enum SchoolLevel {
  // Primaire
  CM1 = 'CM1',
  CM2 = 'CM2',
  // College
  Sixieme = '6ème',
  Cinquieme = '5ème',
  Quatrieme = '4ème',
  Troisieme = '3ème',
  // Lycee
  Seconde = 'Seconde',
  Premiere = 'Première',
  Terminale = 'Terminale',
  // Other
  Other = 'other',
}

export enum UserFamilyMemberType {
  mother = 'mother',
  father = 'father',
  boy = 'boy',
  girl = 'girl',
  other = 'other',
}

export enum GameDifficulty {
  NO_TIMER = 'NO_TIMER',
  EASY = 'EASY',
  MEDIUM = 'MEDIUM',
  HARD = 'HARD',
}

export type UserSettings = {
  game_difficulty?: GameDifficulty;
};

export type Family = {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  premium_ends_at?: string;
  trial_ends_at?: string;
  trial_started_at?: string;
};

export type User = {
  id: number;
  uuid: string;
  family_id: number;
  user_type: UserType;
  firstname?: string;
  lastname?: string;
  date_of_birth?: string;
  school_level: string;
  school_id?: number;
  email?: string;
  phone_number?: string;
  family_member_type: UserFamilyMemberType;
  onboarding_completed: boolean;
  objectives: string[];
  created_at?: string;
  updated_at?: string;
  trial_started_at?: string;
  trial_ends_at?: string;
  xp: number;
  show_invite_card: boolean;
  family: Family;
  settings?: UserSettings;
};

export type UserToCreate = Omit<User, 'id' | 'uuid' | 'family_id'>;

export type Child = Omit<User, 'user_type'> & {
  user_type: UserType.child;
};
