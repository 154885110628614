import { useVoiceRecorder } from 'src/components/Piece/Audio/VoiceRecorderContext';
import Chronometer from 'src/components/common/Chronometer';

const VoiceRecordChronometer = () => {
  const {
    controls: { isPausedRecording, isRecordingInProgress },
  } = useVoiceRecorder();

  return (
    <Chronometer isPlaying={!isPausedRecording && isRecordingInProgress} />
  );
};

export default VoiceRecordChronometer;
