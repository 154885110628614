import 'src/styles/components/LessonDetail/LessonDetailVoiceRecorder.scss';
import VoiceRecordVisualizer from 'src/components/Piece/Audio/VoiceRecordVisualizer';
import SendVoiceRecordButton from 'src/components/Piece/Audio/SendVoiceRecordButton';
import PauseVoiceRecordButton from 'src/components/Piece/Audio/PauseVoiceRecordButton';
import ClearVoiceRecordButton from 'src/components/Piece/Audio/ClearVoiceRecordButton';
import ResumeVoiceRecordButton from 'src/components/Piece/Audio/ResumeVoiceRecordButton';
import StartVoiceRecordButton from 'src/components/Piece/Audio/StartVoiceRecordButton';
import VoiceRecordChronometer from 'src/components/Piece/Audio/VoiceRecordChronometer';

export default function LessonDetailVoiceRecorder() {
  return (
    <div className="w-full flex flex-col items-center">
      <VoiceRecordVisualizer />
      <div className={'mb-2 mt-20'}>
        <VoiceRecordChronometer />
      </div>
      <div className="flex gap-4">
        <StartVoiceRecordButton />
        <PauseVoiceRecordButton />
        <ClearVoiceRecordButton />
        <ResumeVoiceRecordButton />
        <SendVoiceRecordButton />
      </div>
    </div>
  );
}
