import { useEffect, useState } from 'react';
import { AutoFloatingPanel } from 'src/components/common/AutoFloatingPanel';
import ErrorPaymentOptions from 'src/components/Paywall/ErrorPaymentPanel/ErrorPaymentOptions';
import { openWhatsAppSupport } from 'src/modules/support/support';
import ErrorPaymentTooExpensive from 'src/components/Paywall/ErrorPaymentPanel/ErrorPaymentTooExpensive';

const ErrorPaymentPanel = ({
  isOpen,
  onClose,
  onShowMensualOffer,
  isMensualOfferShown,
}: {
  isOpen: boolean;
  onClose: () => void;
  onShowMensualOffer: () => void;
  isMensualOfferShown: boolean;
}) => {
  const [selectedAnswer, setSelectedAnswer] = useState<string>();

  useEffect(() => {
    if (isOpen) {
      setSelectedAnswer(undefined);
    }
  }, [isOpen]);

  const onSelectedAnswer = (value: string) => {
    setSelectedAnswer(value);

    if (value !== 'too_expensive') {
      openWhatsAppSupport();
      onClose();
    }
  };

  return (
    <AutoFloatingPanel
      data-testid={'panel-error-payment'}
      isOpen={isOpen}
      onClose={onClose}
      priority={1}
    >
      <div className={'p-4 text-structural-darkest'}>
        {selectedAnswer === 'too_expensive' ? (
          <ErrorPaymentTooExpensive onShowMensualOffer={onShowMensualOffer} />
        ) : (
          <ErrorPaymentOptions
            selectedAnswer={selectedAnswer}
            onSelect={onSelectedAnswer}
            isMensualOfferShown={isMensualOfferShown}
          />
        )}
      </div>
    </AutoFloatingPanel>
  );
};

export default ErrorPaymentPanel;
