import { User } from 'src/types/user.types';
import BranchIOMetadataPlugin from '../../plugins/BranchIOMetadataPlugin';
import { getAnalyticsDeviceId } from 'src/modules/analytics/Amplitude';
import { BranchDeepLinks } from 'capacitor-branch-deep-links';
import { Capacitor } from '@capacitor/core';
import { getModuleExternalId } from 'src/utils/external-id';

export const loginBranchIO = async (user: User) => {
  if (!Capacitor.isNativePlatform()) {
    return;
  }

  const analyticsDeviceId = getAnalyticsDeviceId();
  await BranchDeepLinks.setIdentity({
    newIdentity: getModuleExternalId(user.id),
  });
  if (analyticsDeviceId) {
    await BranchIOMetadataPlugin.setRequestMetadata({
      metadataKey: '$amplitude_device_id',
      metadataValue: analyticsDeviceId,
    });
  }
  await BranchIOMetadataPlugin.setRequestMetadata({
    metadataKey: '$amplitude_user_id',
    metadataValue: getModuleExternalId(user.id),
  });
};
