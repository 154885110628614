import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useMutation,
  useQueryClient,
} from 'react-query';
import axios from 'axios';
import { type Page } from 'src/types';
import { InAppNotification } from 'src/types/notification.types';

export const useGetNotifications = (
  options: UseInfiniteQueryOptions<Page<InAppNotification>> = {},
) => {
  return useInfiniteQuery<Page<InAppNotification>>({
    queryKey: ['notifications'],
    queryFn: async () => {
      const { data } = await axios.get('/api/notifications');
      return data;
    },
    refetchOnWindowFocus: 'always',
    refetchOnMount: 'always',
    ...options,
  });
};

export const useGetUnreadNotifications = (
  options: UseInfiniteQueryOptions<Page<InAppNotification>> = {},
) => {
  return useInfiniteQuery<Page<InAppNotification>>({
    queryKey: ['unread_notifications'],
    queryFn: async () => {
      const { data } = await axios.get('/api/notifications/unread');
      return data;
    },
    refetchOnWindowFocus: 'always',
    refetchOnMount: 'always',
    ...options,
  });
};

export const useReadNotification = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (notificationId: number) => {
      await axios.patch(`/api/notifications/${notificationId}/read`);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['notifications']);
      },
    },
  );
};

export const useNotificationShown = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (notificationId: number) => {
      await axios.patch(`/api/notifications/${notificationId}/shown`);
    },
    {
      onSuccess: async () => {
        await queryClient.refetchQueries(['unread_notifications']);
      },
    },
  );
};

export const useReadAllNotifications = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (notification_uuids: string[]) => {
      await axios.patch(`/api/notifications/read`, {
        notification_uuids,
      });
    },
    {
      onSuccess: async () => {
        await queryClient.refetchQueries([
          'unread_notifications',
          'notifications',
        ]);
      },
    },
  );
};
