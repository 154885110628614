import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Spinner from 'src/components/common/designSystem/Spinner';
import { useCurrentUser, useUpdateCurrentUser } from 'src/queries/user';
import Footer from 'src/components/common/designSystem/Footer';
import Button from 'src/components/common/designSystem/Button';
import Input from 'src/components/common/designSystem/Input';
import CheckboxList from 'src/components/common/designSystem/CheckboxList';
import { UserType } from 'src/types/user.types';

function ObjectiveStep({ onFinish }: { onFinish: () => void }) {
  const { t } = useTranslation();
  const { data: currentUser, isLoading } = useCurrentUser();
  const isChild = currentUser?.user_type === UserType.child;
  const objectivesOptions = isChild
    ? [
        'better_organisation',
        'better_grades',
        'saving_time',
        'first_place',
        'other',
      ]
    : [
        'easy_learning',
        'more_autonomy',
        'child_motivation',
        'improve_rates',
        'child_confidence',
        'reduce_time',
      ];
  const [selectedObjective, setSelectedObjective] = useState(
    currentUser?.objectives?.filter(objective =>
      objectivesOptions.includes(objective),
    ) ?? [],
  );
  const isOtherSelected = selectedObjective.includes('other');
  const [otherValue, setOtherValue] = useState(
    currentUser?.objectives?.find(
      objective => !objectivesOptions.includes(objective),
    ) ?? '',
  );

  const objectivesIncomplete =
    selectedObjective.length === 0 ||
    (isOtherSelected && otherValue.length === 0);
  const { mutateAsync: saveCurrentUser, isLoading: isUpdating } =
    useUpdateCurrentUser();

  const onNextClick = async () => {
    let objectivesToSet = selectedObjective;
    if (
      isOtherSelected &&
      selectedObjective.filter(
        objective => !objectivesOptions.includes(objective),
      )
    )
      objectivesToSet = [...selectedObjective, otherValue];
    await saveCurrentUser({
      objectives: objectivesToSet,
    });
    onFinish();
  };

  if (isLoading) {
    return <Spinner size="large" className="w-full h-full" />;
  }

  return (
    <>
      <div className="flex-1 flex flex-col justify-center items-start w-full gap-4">
        {isChild ? (
          <div className={'font-extrabold text-base text-center text-balance'}>
            {t('onboarding.child.objective.title')}
          </div>
        ) : (
          <>
            <div className={'font-extrabold text-base text-balance'}>
              {t('onboarding.parent.objective.title')}
            </div>
            <div className={'text-base text-balance'}>
              {t('onboarding.parent.objective.description')}
            </div>
          </>
        )}
        <CheckboxList
          options={objectivesOptions.map(objectiveKey => ({
            label: t(`users.objectives.${objectiveKey}`),
            value: objectiveKey,
          }))}
          selected={selectedObjective}
          onSelect={setSelectedObjective}
        />
        <div className="w-full">
          {isOtherSelected ? (
            <Input
              prefix="💬"
              data-testid="other-objective-input"
              placeholder={
                isChild
                  ? t('onboarding.child.objective.otherInputPlaceholder')
                  : t('onboarding.parent.objective.otherInputPlaceholder')
              }
              value={otherValue}
              onChange={event => {
                setOtherValue(event.target.value);
              }}
            />
          ) : (
            <div className={'h-12 mt-0.5'} />
          )}
        </div>
      </div>
      <Footer>
        <Button
          data-testid="btn-next"
          type="primary"
          loading={isUpdating}
          disabled={objectivesIncomplete}
          onClick={onNextClick}
        >
          {t('onboarding.next')}
        </Button>
      </Footer>
    </>
  );
}

export default ObjectiveStep;
