import { useQuery } from 'react-query';
import axios from 'axios';
import { XPBooster } from 'src/types/quiz.types';

export const useGetXPBooster = () =>
  useQuery<XPBooster>({
    queryKey: ['xp_booster'],
    queryFn: async () => {
      const { data } = await axios.get(`/api/xp_boosters`);
      return data;
    },
  });
