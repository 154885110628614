import { createPortal } from 'react-dom';
import { createRef, useRef, useState } from 'react';
import ReactPlayer from 'react-player/youtube';
import './PaywallScreenDescriptionVideo.scss';
import Button from 'src/components/common/designSystem/Button';
import SkipIcon from 'src/images/skip.svg?react';
import { useTranslation } from 'react-i18next';

const PaywallScreenDescriptionVideo = ({
  onContinue,
}: {
  onContinue: () => void;
}) => {
  const { t } = useTranslation();
  const [isMuted, setIsMuted] = useState(true);
  const [isEnded, setIsEnded] = useState(false);
  const hasEnded = useRef(false);
  const playerRef = createRef<ReactPlayer>();

  const onClick = () => {
    if (isEnded) {
      playerRef.current?.seekTo(0);
    } else {
      setIsMuted(!isMuted);
    }
  };

  return createPortal(
    <div className={'fixed inset-0 z-[2000] bg-black overflow-clip'}>
      <div className={'w-full h-full'} onClick={onClick}>
        <div className={'w-full h-full yt-player'}>
          <ReactPlayer
            ref={playerRef}
            url="https://www.youtube.com/embed/hPsIchxdfpw"
            controls={false}
            muted={isMuted}
            onPlay={() => {
              setIsEnded(false);
            }}
            onEnded={() => {
              hasEnded.current = true;
              setIsEnded(true);
            }}
            width="100%"
            height="100%"
            playing={true}
            config={{
              playerVars: {
                modestbranding: 1,
                autohide: 1,
                autoplay: 1,
                showinfo: 0,
                controls: 0,
                playsinline: 1,
                rel: 0,
                enablejsapi: 1,
              },
            }}
          />
        </div>
      </div>

      {hasEnded.current ? (
        <div className={'absolute bottom-28 w-full px-4'}>
          <Button type={'primary'} onClick={onContinue}>
            {t('global.continue')}
          </Button>
        </div>
      ) : (
        <div className={'absolute bottom-28 w-full flex justify-end'}>
          <div
            onClick={onContinue}
            className={
              'pr-4 py-4 pl-6 text-base text-white font-bold bg-structural-darkest bg-opacity-50 rounded-tl-lg rounded-bl-lg cursor-pointer'
            }
          >
            <span>{t('global.skip')}</span>
            <SkipIcon className={'inline-block ml-1 fill-white h-4'} />
          </div>
        </div>
      )}
    </div>,
    document.body,
  );
};

export default PaywallScreenDescriptionVideo;
