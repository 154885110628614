const PromoBadge = ({ discount }: { discount: string }) => {
  return (
    <div
      className={
        'absolute -top-3 right-4 p-1 px-2 font-bold text-sm rounded-xl bg-gradient-to-t from-paywall-darkest-from to-paywall-darkest-to text-white'
      }
    >
      {discount}
    </div>
  );
};

export default PromoBadge;
