import { useTranslation } from 'react-i18next';
import ParentHeader from 'src/components/Parent/ParentHeader';
import { useCurrentUser } from 'src/queries/user';
import ProfileChildren from 'src/components/Parent/ProfileChildren';
import AddChildButton from 'src/components/UserManagement/AddChildButton';

const ParentProfilePage = () => {
  const { t } = useTranslation();
  const { data: user } = useCurrentUser();

  if (!user) {
    return null;
  }

  return (
    <div className={'flex flex-col gap-12 flex-1'}>
      <ParentHeader title={t('profile.parent.title')} />
      <div className={'flex flex-col gap-2'}>
        <div className={'text-xl font-bold'}>
          {t('profile.parent.account.title')}
        </div>
        <div
          className={'rounded-xl bg-white p-3 text-base'}
        >{`${user.firstname}`}</div>
      </div>

      <ProfileChildren />

      <AddChildButton />
    </div>
  );
};

export default ParentProfilePage;
