import { useTranslation } from 'react-i18next';
import { usePaywall } from 'src/contexts/PaywallContext';
import {
  PURCHASES_ERROR_CODE,
  PurchasesPackage,
} from '@revenuecat/purchases-capacitor';
import { useState } from 'react';
import { useNotification } from 'src/contexts/NotificationContext';
import { reportError } from 'src/modules/logs/Sentry';
import { RevenueCatPlacement } from 'src/modules/purchase/purchase.constants';
import { useFlags } from 'src/contexts/FlagsContext';
import PaywallScreenPayFull from 'src/components/Paywall/PaywallScreenPay/PaywallScreenPayFull';
import { PaywallScreen2Parent } from 'src/types/flags.types';
import { logAnalyticsEvent } from 'src/modules/analytics/Amplitude';
import BlankWhiteLayout from 'src/layouts/BlankWhiteLayout';
import BlankPrimaryLayout from 'src/layouts/BlankPrimaryLayout';
import PaywallScreenPayUnlock from 'src/components/Paywall/PaywallScreenPay/PaywallScreenPayUnlock';
import PaywallScreenPaySimple from 'src/components/Paywall/PaywallScreenPay/PaywallScreenPaySimple';

const PaywallScreenPay = ({
  placement,
  onValidateAmbassadorCode,
  ambassadorCodeValid,
  onPaymentError,
}: {
  placement?: RevenueCatPlacement;
  onValidateAmbassadorCode: () => void;
  ambassadorCodeValid: boolean;
  onPaymentError?: (error: any) => void;
}) => {
  const { t } = useTranslation();
  const { subscribePremium } = usePaywall();
  const [paying, setPaying] = useState(false);
  const { showError } = useNotification();
  const { paywall_screen2_parent } = useFlags();

  const pay = async (purchasesPackage: PurchasesPackage) => {
    try {
      setPaying(true);
      await subscribePremium(purchasesPackage);
      logAnalyticsEvent('trial_started', {
        is_paid: true,
      });
    } catch (error: any) {
      if (onPaymentError) {
        onPaymentError(error);
      }

      if (error.code === PURCHASES_ERROR_CODE.PURCHASE_CANCELLED_ERROR) {
        setPaying(false);
        return;
      }

      reportError('Fail to subscribe premium with trial', error);
      showError({
        error,
        message: t('paywall.error'),
      });
    }
    setPaying(false);
  };

  if (paywall_screen2_parent === PaywallScreen2Parent.paywall_unlock) {
    return (
      <BlankPrimaryLayout data-testid={'onboarding-paywall'}>
        <PaywallScreenPayUnlock
          onValidateAmbassadorCode={onValidateAmbassadorCode}
          onPay={pay}
          paying={paying}
          placement={placement}
          ambassadorCodeValid={ambassadorCodeValid}
        />
      </BlankPrimaryLayout>
    );
  }

  if (paywall_screen2_parent === PaywallScreen2Parent.paywall_parent_v2) {
    return (
      <BlankWhiteLayout data-testid={'onboarding-paywall'}>
        <PaywallScreenPaySimple
          onValidateAmbassadorCode={onValidateAmbassadorCode}
          onPay={pay}
          paying={paying}
          placement={placement}
          ambassadorCodeValid={ambassadorCodeValid}
        />
      </BlankWhiteLayout>
    );
  }
  if (paywall_screen2_parent === PaywallScreen2Parent.paywall_parent_v1) {
    return (
      <BlankWhiteLayout data-testid={'onboarding-paywall'}>
        <PaywallScreenPayFull
          onValidateAmbassadorCode={onValidateAmbassadorCode}
          onPay={pay}
          paying={paying}
          placement={placement}
          ambassadorCodeValid={ambassadorCodeValid}
        />
      </BlankWhiteLayout>
    );
  }

  return (
    <BlankWhiteLayout data-testid={'onboarding-paywall'}>
      <PaywallScreenPayFull
        onValidateAmbassadorCode={onValidateAmbassadorCode}
        onPay={pay}
        paying={paying}
        placement={placement}
        ambassadorCodeValid={ambassadorCodeValid}
      />
    </BlankWhiteLayout>
  );
};

export default PaywallScreenPay;
