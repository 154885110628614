import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TabBar } from 'antd-mobile';
import { useLocation, useNavigate } from 'react-router-dom';
import AddLessonModal from 'src/components/AddLesson/AddLessonModal';
import AddIcon from 'src/images/add.svg?react';
import HomeIcon from 'src/images/home.svg?react';
import LessonsIcon from 'src/images/lessons.svg?react';
import ProfileIcon from 'src/images/profile.svg?react';
import NotificationsIcon from 'src/components/Notifications/NotificationsIcon';

const MainTabBar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const [openAddLessonModal, setOpenAddLessonModal] = useState(false);

  const tabs = [
    {
      key: '/home',
      id: 'home',
      title: t('tabbar.home'),
      Icon: HomeIcon,
    },
    {
      key: '/home/lessons',
      id: 'lessons',
      title: t('tabbar.lessons'),
      Icon: LessonsIcon,
    },
    {
      key: '/add',
      id: 'add_lesson',
      Icon: AddIcon,
    },
    {
      key: '/home/notifications',
      id: 'notifications',
      title: t('tabbar.notifications'),
      Icon: NotificationsIcon,
    },
    {
      key: '/home/profile',
      id: 'profile',
      title: t('tabbar.profile'),
      Icon: ProfileIcon,
    },
  ];

  const setRouteActive = (value: string) => {
    if (value === '/add') {
      setOpenAddLessonModal(true);
      return;
    }
    navigate(value);
  };

  const handleClose = () => {
    setOpenAddLessonModal(false);
  };

  return (
    <div data-testid={'tabbar'} className={'flex-1 w-full'}>
      <TabBar
        className={'flex-1 w-full'}
        activeKey={pathname}
        onChange={value => setRouteActive(value)}
      >
        {tabs.map(item => (
          <TabBar.Item
            key={item.key}
            data-testid={`tab-${item.id}`}
            icon={
              <item.Icon
                className={`h-6 ${pathname === item.key ? 'fill-primary-darkest' : 'fill-white'}`}
              />
            }
            title={
              item.title ? <span>{item.title}</span> : <div className={'h-2'} />
            }
          />
        ))}
      </TabBar>
      <AddLessonModal isOpen={openAddLessonModal} onClose={handleClose} />
    </div>
  );
};

export default MainTabBar;
