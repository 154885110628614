import NotificationsIconSVG from 'src/images/notifications.svg?react';
import useGetUnreadNotificationsInList from 'src/components/Notifications/NotificationsList/useGetUnreadNotificationsInList';

const NotificationsIcon = (props: { className: string }) => {
  const { notifications } = useGetUnreadNotificationsInList();
  return (
    <div className={'relative'}>
      <NotificationsIconSVG className={props.className} />
      {notifications && notifications.length ? (
        <div
          data-testid={'unread-notifications'}
          className={
            'absolute top-0 right-0 rounded-full w-2 h-2 bg-red-darkest'
          }
        />
      ) : null}
    </div>
  );
};

export default NotificationsIcon;
