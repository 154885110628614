const Badge = ({ text }: { text: string }) => {
  return (
    <div
      className={
        'absolute z-20 -top-3 right-4 p-1 px-2 font-bold text-sm rounded-lg bg-gradient-to-t from-paywall-darkest-from to-paywall-darkest-to text-white'
      }
    >
      {text}
    </div>
  );
};

export default Badge;
