import { useTranslation } from 'react-i18next';
import { type ButtonProps } from 'antd';

import { allSubjects } from 'src/utils/constants';
import SubjectListItem from 'src/components/Subject/SubjectListItem';

import { LessonSubject } from 'src/types/lesson.types';

export default function AddLessonSelectSubject({
  onSubjectSelected,
}: {
  onSubjectSelected: (subject: LessonSubject) => Promise<void>;
} & ButtonProps) {
  const { t } = useTranslation();

  return (
    <div className={'flex flex-col gap-2'}>
      <div className={'flex flex-row justify-center items-center w-full'}>
        <div className={'text-base font-semibold'}>
          {t('home.addLesson.chooseASubject')}
        </div>
      </div>
      <div className={'grid grid-cols-2 gap-2 pt-2'}>
        {allSubjects.map(subject => {
          return (
            <SubjectListItem
              subject={subject.key}
              key={subject.key}
              onClick={async () => {
                await onSubjectSelected(subject.key);
              }}
            />
          );
        })}
      </div>
    </div>
  );
}
