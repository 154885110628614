import BuildInfoPanel from 'src/components/BuildInfo/BuildInfoPanel';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const ShowDeviceInfoButton = () => {
  const [isBuildPanelOpen, setIsBuildPanelOpen] = useState(false);
  const { t } = useTranslation();
  const openPanel = () => {
    setIsBuildPanelOpen(true);
  };
  const closePanel = () => {
    setIsBuildPanelOpen(false);
  };

  return (
    <>
      <div
        onClick={openPanel}
        data-testid="btn-show-device-info"
        className={
          'text-xs text-balance font-bold text-center text-structural-darkest'
        }
      >
        {t('paywall.pay.showDeviceInfo')}
      </div>
      <BuildInfoPanel isOpen={isBuildPanelOpen} onClose={closePanel} />
    </>
  );
};

export default ShowDeviceInfoButton;
