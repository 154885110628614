import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import QuizXPIcon from 'src/images/quiz/xp.svg?react';
import { XPBooster } from 'src/types/quiz.types';

const XPBoosterProgress = ({ xpBooster }: { xpBooster: XPBooster }) => {
  const [progress, setProgress] = useState(0);

  const getProgress = (xpBooster: XPBooster) => {
    const start = dayjs(xpBooster.start, { utc: true });
    const end = dayjs(xpBooster.end, { utc: true });
    const totalTime = end.diff(start, 'second');
    const now = dayjs();
    const remainingTime = end.diff(now, 'second');
    return 100 - ((totalTime - remainingTime) / totalTime) * 100;
  };

  useEffect(() => {
    setProgress(getProgress(xpBooster));
    const interval = setInterval(() => {
      setProgress(getProgress(xpBooster));
    }, 1000);
    return () => clearInterval(interval);
  }, [xpBooster]);

  if (progress <= 0) {
    return null;
  }

  return (
    <div
      data-testid="xp-booster-header"
      className={'rounded-3xl animated-border'}
    >
      <div
        className={
          'flex overflow-hidden bg-structural-lightest rounded-3xl relative w-auto h-9'
        }
      >
        <div
          className={'bg-primary-darkest h-full absolute top-0 left-0 bottom-0'}
          style={{
            width: `${progress}%`,
            transition: 'width 1s linear',
          }}
        />
        <div
          className={
            'flex flex-row justify-center items-center gap-1 px-3 font-bold relative'
          }
        >
          <QuizXPIcon className={'h-5 w-auto'} />
          <div className={'relative'}>
            <div className={'text-white relative normal-case'}>
              Boost x{xpBooster.boost_multiplier}
            </div>
          </div>
        </div>
        <div
          className={
            'absolute text-black inset-0 pl-[30px] flex justify-start items-center normal-case'
          }
          style={{
            clipPath: `inset(0 0 0 ${progress}%)`,
            transition: 'clip-path 1s linear',
          }}
        >
          Boost x{xpBooster.boost_multiplier}
        </div>
      </div>
    </div>
  );
};

export default XPBoosterProgress;
