import { AutoFloatingPanel } from 'src/components/common/AutoFloatingPanel';
import { Child } from 'src/types/user.types';
import { useUpdateUser } from 'src/queries/users';
import { Form } from 'antd';
import Input from 'src/components/common/designSystem/Input';
import Button from 'src/components/common/designSystem/Button';
import { reportError } from 'src/modules/logs/Sentry';
import { useTranslation } from 'react-i18next';
import { useNotification } from 'src/contexts/NotificationContext';

const EditChildFirstnamePanel = ({
  child,
  isOpen,
  onClose,
}: {
  child: Child;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const { mutateAsync: saveUser, isLoading: isSaving } = useUpdateUser();
  const { showError } = useNotification();

  const saveFirstname = async ({ firstname }: { firstname: string }) => {
    const childId = child.id;
    try {
      await saveUser({
        id: childId,
        firstname,
      });
      onClose();
    } catch (error) {
      reportError('Fail to update child firstname', error);
      showError({
        error,
        message: t(`profile.parent.children.firstname.fail`),
      });
    }
  };

  return (
    <AutoFloatingPanel
      isOpen={isOpen}
      onClose={onClose}
      data-testid={'edit-child-firstname-panel'}
    >
      <Form
        name="childFirstnameForm"
        onFinish={saveFirstname}
        className="w-full bg-transparent flex-1 flex flex-col gap-3 p-4"
        disabled={isSaving}
        initialValues={{ firstname: child.firstname }}
      >
        <div className={'text-balance font-bold text-base'}>
          {t('profile.parent.children.firstname.title')}
        </div>
        <Form.Item
          name="firstname"
          className="w-full text-center"
          rules={[
            {
              required: true,
              message: t('global.validation.fieldRequired'),
            },
          ]}
        >
          <Input
            data-testid="inp-firstname"
            autoFocus={isOpen}
            placeholder={t(`profile.parent.children.firstname.placeholder`)}
          />
        </Form.Item>
        <Button
          data-testid="btn-save"
          type="primary"
          htmlType="submit"
          disabled={isSaving}
          loading={isSaving}
          className="w-full"
        >
          {t('profile.parent.children.firstname.submit')}
        </Button>
      </Form>
    </AutoFloatingPanel>
  );
};

export default EditChildFirstnamePanel;
