import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { type ButtonProps } from 'antd';
import { useNotification } from 'src/contexts/NotificationContext';
import {
  PURCHASES_ERROR_CODE,
  PurchasesPackage,
} from '@revenuecat/purchases-capacitor';
import { usePaywall } from 'src/contexts/PaywallContext';
import Button from 'src/components/common/designSystem/Button';
import { reportError } from 'src/modules/logs/Sentry';

const PayButton = ({
  className,
  onPay,
  purchasesPackage,
  ...props
}: ButtonProps & {
  onPay?: () => void;
  purchasesPackage?: PurchasesPackage;
}) => {
  const { t } = useTranslation();
  const { isPremium, subscribePremium } = usePaywall();
  const [paying, setPaying] = useState(false);
  const { showError } = useNotification();

  useEffect(() => {
    if (isPremium && onPay) {
      onPay();
    }
  }, [isPremium, onPay]);

  const pay = async () => {
    if (!purchasesPackage) {
      showError({
        message: t('paywall.noPackageSelected'),
      });
      return;
    }

    try {
      setPaying(true);
      await subscribePremium(purchasesPackage);
    } catch (error: any) {
      if (error.code === PURCHASES_ERROR_CODE.PURCHASE_CANCELLED_ERROR) {
        setPaying(false);
        return;
      }

      reportError('Fail to subscribe premium without trial', error);
      showError({
        error,
        message: t('paywall.error'),
      });
    }
    setPaying(false);
  };

  return (
    <Button
      loading={paying}
      onClick={pay}
      disabled={!purchasesPackage}
      type={'primary'}
      data-testid="btn-pay-premium"
      {...props}
    />
  );
};

export default PayButton;
