import { useTranslation } from 'react-i18next';

import { subjects } from 'src/utils/constants';
import Button from 'src/components/common/designSystem/Button';
import AddLessonModal from 'src/components/AddLesson/AddLessonModal';
import { useState } from 'react';
import { LessonSubject } from 'src/types/lesson.types';

const ChildEmptyNotificationCard = () => {
  const { t } = useTranslation();
  const subject = subjects[LessonSubject.history];

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div data-testid={`notification-empty`}>
      <div
        className={'flex flex-row bg-white rounded-xl p-4 gap-4'}
        onClick={handleClickOpen}
      >
        {subject ? (
          <div>
            <div
              className={
                'w-12 h-12 bg-primary-neutral rounded-2xl flex items-center justify-center'
              }
            >
              <subject.IconSmallComponent className={'w-8 h-8'} />
            </div>
          </div>
        ) : null}
        <div className={'flex flex-col w-full'}>
          <div
            className={
              'text-base font-bold text-balance text-structural-darkest'
            }
          >
            {t('notifications.empty.title')}
          </div>
          <div className={'flex flex-row justify-between items-center'}>
            <div
              className={
                'font-semibold line-clamp-2 text-ellipsis overflow-hidden text-balance text-structural-neutral'
              }
            >
              {t('notifications.empty.description')}
            </div>
            <Button type={'default'} size={'small'}>
              {t('global.add')}
            </Button>
          </div>
        </div>
      </div>
      <AddLessonModal isOpen={open} onClose={handleClose} />
    </div>
  );
};

export default ChildEmptyNotificationCard;
