import { Experiment } from '@amplitude/experiment-js-client';
import { CapacitorHttp } from '@capacitor/core';

export const initFeatureFlags = () => {
  if (import.meta.env.VITE_CI) {
    return undefined;
  }

  return Experiment.initializeWithAmplitudeAnalytics(
    import.meta.env.VITE_AMPLITUDE_API_KEY,
    {
      debug: import.meta.env.MODE === 'development',
      httpClient: {
        request: async (
          requestUrl: string,
          method: string,
          headers: Record<string, string>,
          data: string,
          timeoutMillis?: number,
        ) => {
          const response = await CapacitorHttp.request({
            url: requestUrl,
            method,
            headers,
            data,
            readTimeout: timeoutMillis,
          });
          return {
            status: response.status,
            body: JSON.stringify(response.data),
          };
        },
      },
    },
  );
};
