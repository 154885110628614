import { useTranslation } from 'react-i18next';
import NotificationsList from 'src/components/Notifications/NotificationsList';
import ParentHeader from 'src/components/Parent/ParentHeader';

const ParentNotificationsPage = () => {
  const { t } = useTranslation();

  return (
    <div className={'flex flex-col text-structural-darkest'}>
      <ParentHeader title={t('notifications.title')} />
      <NotificationsList />
    </div>
  );
};

export default ParentNotificationsPage;
