export enum RevenueCatOffering {
  MONTHLY = 'default',
  MONTHLY_WITHOUT_TRIAL = 'without_trial',
  ANNUAL = 'premium_annual',
  ANNUAL_WITHOUT_TRIAL = 'premium_annual_without_trial',
}

export const MONTHLY_ENTITLEMENT_ID = 'premium';
export const MONTHLY_ENTITLEMENT_ID_WITHOUT_TRIAL = 'premium_without_trial';
export const ANNUAL_ENTITLEMENT_ID = 'premium_annual';
export const ANNUAL_ENTITLEMENT_ID_WITHOUT_TRIAL =
  'premium_annual_without_trial';

export const ANNUAL_OFFERS = [
  RevenueCatOffering.ANNUAL,
  RevenueCatOffering.ANNUAL_WITHOUT_TRIAL,
];
export const MONTHLY_OFFERS = [
  RevenueCatOffering.MONTHLY,
  RevenueCatOffering.MONTHLY_WITHOUT_TRIAL,
];
