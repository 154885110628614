import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { useVoiceVisualizer } from 'react-voice-visualizer';
import { Controls } from 'react-voice-visualizer/dist/types/types';
import { Capacitor } from '@capacitor/core';
import { Filesystem } from '@capacitor/filesystem';
import convertBlobToBase64 from 'src/utils/blobToBase64';
import { Directory } from '@capacitor/filesystem/dist/esm/definitions';
import { useNotification } from 'src/contexts/NotificationContext';
import { allowSleep, keepAwake } from 'src/modules/keep-awake';
import { Microphone } from '@mozartec/capacitor-microphone';
import { reportError } from 'src/modules/logs/Sentry';
import MicrophoneDisabledPanel from 'src/components/Piece/Audio/MicrophoneDisabledPanel';

export const VoiceRecorderContext = createContext<
  | {
      controls: Controls;
      isHandlingBlob: boolean;
      isRecordReady: boolean;
      stopRecord: () => void;
    }
  | undefined
>(undefined);

const VoiceRecorderContextProvider = ({
  children,
  onRecordReady,
}: PropsWithChildren<{
  onRecordReady?: (blob: Blob, duration: number, uri?: string) => Promise<void>;
}>) => {
  const [isHandlingBlob, setIsHandlingBlob] = useState(false);
  const [isRecordReady, setIsRecordReady] = useState(false);
  const [isMicrophoneDenied, setIsMicrophoneDenied] = useState(false);
  const { showError } = useNotification();
  const { t } = useTranslation();

  const controls = useVoiceVisualizer({
    async onStartRecording() {
      await keepAwake();
    },
    onPausedRecording() {
      allowSleep();
    },
    onStopRecording() {
      allowSleep();
    },
  });

  const stopRecord = useCallback(async () => {
    controls.stopRecording();
  }, [controls]);

  const handleBlobReady = useCallback(async () => {
    const recordedBlob = controls.recordedBlob;
    if (!recordedBlob || !onRecordReady) {
      return;
    }
    setIsHandlingBlob(true);
    try {
      if (Capacitor.isNativePlatform()) {
        await Filesystem.requestPermissions();
        const tmpFile = await Filesystem.writeFile({
          path: 'audio',
          data: await convertBlobToBase64(recordedBlob),
          directory: Directory.Cache,
          recursive: true,
        });
        await onRecordReady(recordedBlob, controls.duration, tmpFile.uri);
      } else {
        await onRecordReady(recordedBlob, controls.duration);
      }
      setIsRecordReady(true);
    } catch (e) {
      reportError('Fail to handle audio blob', e);
      showError({
        message: t('lessons.audio.error'),
        error: e,
      });
    }
    setIsHandlingBlob(false);
  }, [controls.recordedBlob, controls.duration, onRecordReady]);

  useEffect(() => {
    if (controls.recordedBlob && !isHandlingBlob) {
      handleBlobReady();
    }
  }, [controls.recordedBlob]);

  const checkPermissions = useCallback(async () => {
    const permissionStatus = await Microphone.requestPermissions();
    setIsMicrophoneDenied(permissionStatus.microphone === 'denied');
  }, []);

  useEffect(() => {
    checkPermissions();
    return () => {
      allowSleep();
    };
  }, [checkPermissions]);

  return (
    <VoiceRecorderContext.Provider
      value={{ controls, isHandlingBlob, isRecordReady, stopRecord }}
    >
      {children}
      <MicrophoneDisabledPanel isOpen={isMicrophoneDenied} />
    </VoiceRecorderContext.Provider>
  );
};

export const useVoiceRecorder = () => {
  const voiceRecorder = useContext(VoiceRecorderContext);
  if (!voiceRecorder) {
    throw new Error(
      'useVoiceRecorder must be used within a VoiceRecorderContext',
    );
  }
  return voiceRecorder;
};

export default VoiceRecorderContextProvider;
