import { useParams, useSearchParams } from 'react-router-dom';
import useEndOnboardingParentAndSwitchToChildOnboarding from 'src/hooks/useEndOnboardingParentAndSwitchToChildOnboarding';
import Spinner from 'src/components/common/designSystem/Spinner';
import { usePaywall } from 'src/contexts/PaywallContext';
import { useCallback, useEffect, useState } from 'react';
import PaywallScreenPay from 'src/components/Paywall/PaywallScreenPay/PaywallScreenPay';
import { DeviceType } from 'src/components/Onboarding/ParentChildDevice';
import useEndOnboardingParent from 'src/hooks/useEndOnboardingParent';
import { logAnalyticsEvent } from 'src/modules/analytics/Amplitude';
import ErrorPaymentPanel from 'src/components/Paywall/ErrorPaymentPanel/ErrorPaymentPanel';
import { useFlags } from 'src/contexts/FlagsContext';

const OnboardingParentChildPaywallPay = () => {
  const { childId } = useParams();
  const {
    endOnboarding: endOnboardingParentAndSwitchToChildOnboarding,
    isEnding: isEndingOnboardingParentAndSwitchToChildOnboarding,
  } = useEndOnboardingParentAndSwitchToChildOnboarding({
    childId: Number(childId),
  });
  const {
    endOnboarding: endOnboardingParent,
    isEnding: isEndingOnboardingParent,
  } = useEndOnboardingParent();
  const [searchParams] = useSearchParams();
  const { paywall_screen2_parent } = useFlags();
  const [showErrorPaymentPanel, setShowErrorPaymentPanel] = useState(false);
  const deviceType = searchParams.get('deviceType');
  const { isPremium } = usePaywall();
  const [showMensualOffer, setShowMensualOffer] = useState(
    searchParams.get('isAmbassador') === 'true',
  );

  const endOnboarding = useCallback(async () => {
    if (deviceType === DeviceType.other) {
      return endOnboardingParent();
    }
    endOnboardingParentAndSwitchToChildOnboarding();
  }, [
    deviceType,
    endOnboardingParent,
    endOnboardingParentAndSwitchToChildOnboarding,
  ]);

  const onValidateAmbassadorCode = () => {
    logAnalyticsEvent('paywall_viewed', {
      type: 'onboarding_screen2_parent_ambassador',
    });
    searchParams.set('showMensualOffer', 'true');
    setShowMensualOffer(true);
  };

  const onPaymentError = () => {
    setShowErrorPaymentPanel(true);
  };

  const onShowMensualOffer = () => {
    setShowMensualOffer(true);
    setShowErrorPaymentPanel(false);
  };

  useEffect(() => {
    if (isPremium) {
      endOnboarding();
    }
  }, [isPremium, endOnboarding]);

  useEffect(() => {
    logAnalyticsEvent('paywall_viewed', {
      type: 'onboarding_screen2_parent',
      paywall_id: paywall_screen2_parent,
      show_mensual_offer: showMensualOffer,
    });
  }, [showMensualOffer, paywall_screen2_parent]);

  if (
    isEndingOnboardingParentAndSwitchToChildOnboarding ||
    isEndingOnboardingParent
  ) {
    return <Spinner className="w-full h-full" size="large" />;
  }

  return (
    <>
      <PaywallScreenPay
        showMensualOffer={showMensualOffer}
        onValidateAmbassadorCode={onValidateAmbassadorCode}
        onPaymentError={onPaymentError}
      />

      <ErrorPaymentPanel
        isMensualOfferShown={showMensualOffer}
        isOpen={showErrorPaymentPanel}
        onClose={() => setShowErrorPaymentPanel(false)}
        onShowMensualOffer={onShowMensualOffer}
      />
    </>
  );
};

export default OnboardingParentChildPaywallPay;
