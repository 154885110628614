import { useReadAllNotifications } from 'src/queries/notifications';
import Spinner from 'src/components/common/designSystem/Spinner';
import NotificationCard from 'src/components/Notifications/NotificationsList/NotificationCard';
import EmptyNotificationCard from 'src/components/Notifications/NotificationsList/NotificationCard/EmptyNotificationCard';
import { InAppNotification } from 'src/types/notification.types';
import { useEffect, useMemo } from 'react';
import useGetNotificationsInList from 'src/components/Notifications/NotificationsList/useGetNotificationsInList';

const NotificationsList = () => {
  const { notifications, isLoading } = useGetNotificationsInList();
  const { mutateAsync: readAllNotifications } = useReadAllNotifications();

  const unreadNotificationUuids = useMemo(
    () =>
      notifications
        ?.filter(
          notification => !notification.disabled && !notification.read_at,
        )
        .map(notification => notification.uuid),
    [notifications],
  );

  useEffect(() => {
    if (unreadNotificationUuids?.length) {
      readAllNotifications(unreadNotificationUuids);
    }
  }, [readAllNotifications, unreadNotificationUuids]);

  if (isLoading) {
    return <Spinner />;
  }

  if (!notifications?.length) {
    return <EmptyNotificationCard />;
  }

  return (
    <div className={'flex flex-col gap-4'}>
      {notifications
        ?.sort(
          (
            notification1: InAppNotification,
            notification2: InAppNotification,
          ) => {
            return (
              new Date(notification2.created_at).getTime() -
              new Date(notification1.created_at).getTime()
            );
          },
        )
        .map(notification => (
          <NotificationCard key={notification.id} notification={notification} />
        ))}
    </div>
  );
};

export default NotificationsList;
