import { useTranslation } from 'react-i18next';
import Button from 'src/components/common/designSystem/Button';
import { getAddLessonPieceURL } from 'src/utils/lessonsUtils';
import { useLesson } from 'src/components/LessonDetail/LessonContext';
import { useNavigate } from 'react-router-dom';
import { usePaywall } from 'src/contexts/PaywallContext';
import { getSubjectInfo } from 'src/utils/subject';

const AddContentButton = () => {
  const navigate = useNavigate();
  const { lesson } = useLesson();
  const { t } = useTranslation();
  const { isOutOfTrial, showPaywall } = usePaywall();
  const addPieceUrl = getAddLessonPieceURL(lesson.id, lesson.subject_matter);
  const subject = getSubjectInfo(lesson.subject_matter);

  return (
    <Button
      type={'default'}
      size="large"
      data-testid="btn-add-content"
      onClick={() => {
        if (isOutOfTrial && subject.needPremium) {
          showPaywall();
          return;
        }
        navigate(addPieceUrl);
      }}
    >
      {t('lessons.addContent')}
    </Button>
  );
};

export default AddContentButton;
