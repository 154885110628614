import { useCallback } from 'react';
import { useCreateUser, useUpdateUser } from 'src/queries/users';

import { User, UserToCreate } from 'src/types/user.types';

const useUpsertUser = () => {
  const { mutateAsync: createUser, isLoading: isCreating } = useCreateUser();
  const { mutateAsync: saveUser, isLoading: isSaving } = useUpdateUser();
  const upsertUser = useCallback(
    async (
      userToCreate:
        | ({ id?: undefined } & Partial<UserToCreate>)
        | ({ id: number } & Partial<UserToCreate>),
    ): Promise<User> => {
      if (userToCreate.id !== undefined) {
        return await saveUser(userToCreate);
      } else {
        return await createUser(userToCreate);
      }
    },
    [saveUser, createUser],
  );

  return {
    upsertUser,
    isUpserting: isCreating || isSaving,
  };
};

export default useUpsertUser;
