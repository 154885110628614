export default {
  title: '{{firstName}} te lance un défi !',
  you: 'Toi',
  start: 'Démarrer le défi !',
  result: {
    signup: 'Pour voir ton score, inscris-toi !',
    title: {
      success: 'Bravo {{firstName}} !',
      failure: 'Retente ta chance !',
      equal: 'Égalité !',
    },
    subtitle: {
      success: 'Tu as gagné',
      failure: 'Tu as perdu',
      equal: 'C’est une égalité',
    },
  },
  error: {
    challengingHimself: 'Tu ne peux pas te défier toi même ! 😅',
  },
  discover: {
    card: {
      title: 'Prêt à flex ? 💪',
      description: 'Entre dans l’arène des <1>défis<1/> !',
      button: 'Découvrir',
    },
    panel: {
      1: {
        title: 'Les défis : comment ça marche ?',
        description: 'Je t’explique... 😉',
      },
      2: {
        title: 'Choisis un quiz sur une de tes révisions',
        description:
          'Entraine-toi et donne tout pour avoir un super score 🏆 !',
        description2: 'Puis clique sur ”Défier un ami”',
      },
      3: {
        title: 'Envoie le lien à tes amis ou à ta classe !',
        description: 'Tout le monde peut jouer !',
        description2:
          'Ils feront ton quiz, et on verra enfin qui est le boss ! 🏆',
        button: 'Choisir un quiz',
      },
    },
  },
  parent: {
    panel: {
      title: 'Et si tu défiais ton parent ?',
      description1: 'Montre-lui que tu maîtrises ta leçon !',
      description2: 'Envoie ton quiz et vois s’il peut te battre. ',
      description3: 'C’est ton moment pour briller 🌟',
      button: 'Défier mon parent !',
    },
    lowScore: {
      title: 'Continue de t’entrainer !',
      description:
        'Pour pouvoir défier ton parent tu dois avoir au moins 80% sur le quiz de ta leçon !',
    },
    share: {
      title: 'Défi envoyé à {{parentName}} !',
      description1:
        'N’oublie pas de lui dire de faire le quiz dans son application pour voir s’il peut te battre. 💬',
      description2: 'Clique ci-dessous pour lui envoyer un petit rappel !',
      button: 'Envoyer un message',
      message: {
        title:
          'Je t’ai défié dans l’application Baobab sur ma leçon “{{lessonTitle}}”, peux-tu me battre ? 😃',
      },
      error: 'Impossible de partager le quiz',
    },
  },
};
