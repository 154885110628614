import { type ButtonProps } from 'antd';
import TrackedButton from 'src/components/common/designSystem/TrackedButton';
import { Haptics } from '@capacitor/haptics';
import { ImpactStyle } from '@capacitor/haptics/dist/esm/definitions';

const commonClasses = 'rounded-2xl h-auto border-none';
const primaryClasses = '!bg-black !text-white';
const secondaryClasses = '!bg-gray-100 !text-black';
const linkClasses = 'text-structural-darkest underline';
const textClasses = 'text-structural-darkest';

const getClasses = (
  type?: ButtonProps['type'],
  disabled?: boolean,
  size?: ButtonProps['size'],
) => {
  let classes = commonClasses;
  if (type === 'primary') classes += ` ${primaryClasses}`;
  else if (type === 'default') classes += ` ${secondaryClasses}`;
  else if (type === 'link') classes += ` ${linkClasses}`;
  else if (type === 'text') classes += ` ${textClasses}`;
  if (disabled) classes += ' opacity-50 cursor-not-allowed';
  if (size === 'small') classes += ' py-1 w-auto px-4 text-sm font-semibold';
  else if (size === 'middle') {
    classes += ' py-3 w-auto px-4 text-sm font-bold';
  } else classes += ' py-5 px-3 w-full text-base font-bold ';
  return classes;
};

const Button = ({ className, onClick, ...props }: ButtonProps) => {
  return (
    <TrackedButton
      className={
        getClasses(props.type, props.disabled, props.size) + ' ' + className
      }
      onClick={event => {
        Haptics.impact({ style: ImpactStyle.Medium });
        if (onClick) {
          onClick(event);
        }
      }}
      {...props}
    />
  );
};

export default Button;
