import { useMutation, useQuery, useQueryClient } from 'react-query';
import axios from 'axios';
import { logAnalyticsEvent } from 'src/modules/analytics/Amplitude';
import { Question, QuizRun, QuizWithQuestions } from 'src/types/quiz.types';

export const useGetQuizQuestion = (quizId: number) => {
  return useQuery<Question[]>({
    queryKey: ['quiz', quizId],
    queryFn: async () => {
      const { data } = await axios.get<QuizWithQuestions>(
        `/api/quizzes/${quizId}`,
      );
      const questions = data.questions.map(({ question }) => question);
      const question = questions
        .filter(({ user_flag }) => !user_flag)
        .map(({ question_payload, id }) => ({
          ...question_payload,
          id,
        }));
      return question as Question[];
    },
    refetchOnMount: 'always',
    refetchOnWindowFocus: 'always',
  });
};

export const useGetQuizRun = ({
  quizId,
  quizRunId,
}: {
  quizId: number;
  quizRunId: number;
}) => {
  return useQuery<QuizRun>({
    queryKey: ['quizRun', quizId, quizRunId],
    queryFn: async () => {
      const { data } = await axios.get<QuizRun>(
        `/api/quizzes/${quizId}/runs/${quizRunId}`,
      );
      return data;
    },
  });
};

export const useStartQuiz = () => {
  return useMutation({
    mutationFn: async ({
      quizId,
      fromChallengeId,
    }: {
      quizId: number;
      lessonId: number;
      fromChallengeId?: number;
    }) => {
      const { data } = await axios.post<QuizRun>(
        `/api/quizzes/${quizId}/runs/start`,
        {
          from_challenge_id: fromChallengeId,
        },
      );
      return data;
    },
    onSuccess: (quizRunData, { quizId, lessonId }): any => {
      logAnalyticsEvent('quiz_started', {
        lessonId,
        quizId,
        quizRunId: quizRunData.id,
      });
    },
  });
};

export const useFinishQuiz = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      quizId,
      quizRunId,
    }: {
      quizId: number;
      quizRunId: number;
      lessonId: number;
    }) => {
      const { data } = await axios.post<QuizRun>(
        `/api/quizzes/${quizId}/runs/${quizRunId}/finish`,
      );
      return data;
    },
    onSuccess: async (quizRunData, { quizId, lessonId }) => {
      logAnalyticsEvent('quiz_finished', {
        lessonId,
        quizId,
        quizRunId: quizRunData.id,
      });
      await Promise.all([
        queryClient.invalidateQueries(['user']),
        queryClient.invalidateQueries(['lessons']),
        queryClient.invalidateQueries(['challenges']),
      ]);
    },
  });
};

export const useCreateQuizChallenge = () =>
  useMutation({
    mutationFn: async ({
      quizId,
      notify_parent,
    }: {
      quizId: number;
      notify_parent?: boolean;
    }) => {
      const { data } = await axios.post<{ link: string }>(
        `/api/quizzes/${quizId}/challenge`,
        { notify_parent },
      );
      return data;
    },
  });
