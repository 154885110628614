import { useTranslation } from 'react-i18next';
import { getSubjectInfo } from 'src/utils/subject';
import { LessonSubject } from 'src/types/lesson.types';
import { usePaywall } from 'src/contexts/PaywallContext';
import LockIcon from 'src/images/lock.svg?react';

const SubjectListItem = ({
  subject,
  className,
  onClick,
}: {
  subject: LessonSubject;
  className?: string;
  onClick?: () => void;
}) => {
  const { t } = useTranslation();
  const selectedSubject = getSubjectInfo(subject);
  const { isOutOfTrial, showPaywall } = usePaywall();
  const isLocked = isOutOfTrial && selectedSubject.needPremium;

  const handleClick = () => {
    if (!onClick) {
      return;
    }
    if (isLocked) {
      showPaywall();
      return;
    }
    onClick();
  };

  if (!selectedSubject) {
    return null;
  }

  return (
    <div
      data-testid={`item-${subject}`}
      onClick={handleClick}
      className={
        'rounded-lg relative p-3 overflow-clip h-20 bg-secondary-lighter flex items-center justify-space ' +
        className
      }
    >
      <div className={'w-full flex flex-row gap-2 justify-start items-center'}>
        <div className={'w-10 h-10 flex justify-center items-center'}>
          <selectedSubject.IconSmallComponent className={'w-full h-full'} />
        </div>
        <div className="font-extrabold text-secondary-darker text-base capitalize">
          {t(selectedSubject.labelKey)}
        </div>
      </div>
      {selectedSubject.isBeta && (
        <div className="rounded-lg absolute top-1.5 right-1.5 bg-primary-darkest text-white text-xs font-bold p-1">
          Beta
        </div>
      )}

      {isLocked && (
        <div className="absolute inset-0 bg-structural-darkest bg-opacity-70 flex justify-center items-center">
          <LockIcon className="h-auto w-6 stroke-white" />
        </div>
      )}
    </div>
  );
};

export default SubjectListItem;
