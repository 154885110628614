import { LeftOutline } from 'antd-mobile-icons';
import useNavigateBack from 'src/hooks/useNavigateBack';
import { LessonSubject, PieceType } from 'src/types/lesson.types';
import LessonPieceTitle from 'src/components/Piece/LessonPieceTitle';

const LessonPieceHeader = ({
  type,
  onChange,
  subject,
}: {
  type: PieceType;
  subject: LessonSubject;
  onChange: (type: PieceType) => void;
}) => {
  const goBack = useNavigateBack();

  return (
    <div className={'flex flex-row items-center justify-between p-8'}>
      <LeftOutline fontSize={24} data-testid={'btn-back'} onClick={goBack} />
      <LessonPieceTitle type={type} onChange={onChange} subject={subject} />
      <div className={'w-6'} />
    </div>
  );
};

export default LessonPieceHeader;
