import { useTranslation } from 'react-i18next';
import { Empty } from 'antd';
import { useGetLessonSmartbits } from 'src/queries/lesson_smartbits';
import Spinner from 'src/components/common/designSystem/Spinner';
import LessonDetailSmartbit from 'src/components/LessonDetail/LessonSmartbits/old/LessonDetailSmartbit';
import { useLesson } from 'src/components/LessonDetail/LessonContext';
import { LessonSubject, SmartbitContent } from 'src/types/lesson.types';

const OldLessonDetailSmartbits = ({ lessonId }: { lessonId: number }) => {
  const { lesson } = useLesson();
  const { data: lessonSmartbits, isLoading: lessonSmartbitsIsLoading } =
    useGetLessonSmartbits(lessonId);
  const { t } = useTranslation();

  if (lessonSmartbitsIsLoading) return <Spinner className="w-full h-full" />;

  if (!lessonSmartbits) return null;

  const newSmartbitPayload = [
    LessonSubject.english,
    LessonSubject.spanish,
    LessonSubject.german,
    LessonSubject.french,
  ].includes(lesson.subject_matter);
  const smartbitPerCategories = lessonSmartbits.reduce<
    Record<string, string[] | SmartbitContent[]>
  >((result, smartbit) => {
    const category = smartbit.smartbit_type;
    if (!result[category]) result[category] = [];

    if (newSmartbitPayload)
      result[category].push(smartbit.content as SmartbitContent);
    else
      result[category] = result[category].concat(smartbit.content as string[]);

    return result;
  }, {});

  if (lessonSmartbits.length === 0)
    return (
      <Empty
        image={<IconMdiClass className="text-lightGrey" width="80" />}
        description={t('lessons.noSmartbits')}
      />
    );

  return (
    <div className="flex flex-col gap-4">
      {Object.keys(smartbitPerCategories).map(smartbitType => (
        <div className="flex flex-col bg-white rounded-lg" key={smartbitType}>
          <div
            className={'p-3 font-bold text-base capitalize bg-primary-lighter'}
          >
            {smartbitType}
          </div>
          <div className={'p-3 pl-6'}>
            <LessonDetailSmartbit
              newSmartbitPayload={newSmartbitPayload}
              smartbits={smartbitPerCategories[smartbitType]}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default OldLessonDetailSmartbits;
