import { RevenueCatPlacement } from 'src/modules/purchase/purchase.constants';
import { useTranslation } from 'react-i18next';
import Offer from 'src/components/Paywall/Offers/Offer';
import {
  PACKAGE_TYPE,
  PurchasesPackage,
} from '@revenuecat/purchases-capacitor';
import { useEffect, useState } from 'react';
import { useNotification } from 'src/contexts/NotificationContext';
import { reportError } from 'src/modules/logs/Sentry';
import Spinner from 'src/components/common/designSystem/Spinner';
import { getPurchasesPackages } from 'src/modules/purchase/RevenueCat';
import { useFlags } from 'src/contexts/FlagsContext';
import { PaywallScreen2Parent } from 'src/types/flags.types';
import OfferTxt from 'src/components/Paywall/Offers/OfferTxt';

const OfferSelector = ({
  onSelectPackage,
  selectedPackage,
  placement,
}: {
  selectedPackage?: PurchasesPackage;
  onSelectPackage: (packageIdentifier: PurchasesPackage) => void;
  placement?: RevenueCatPlacement;
}) => {
  const { t } = useTranslation();
  const [loadingPackages, setLoadingPackages] = useState(true);
  const [availablePackages, setAvailablePackages] = useState<
    PurchasesPackage[]
  >([]);
  const { showError } = useNotification();
  const { paywall_screen2_parent } = useFlags();

  const loadPackages = async (placement?: RevenueCatPlacement) => {
    setLoadingPackages(true);
    try {
      const packages = await getPurchasesPackages(placement);
      if (packages.length > 0) {
        setAvailablePackages(packages);
        onSelectPackage(packages[0]);
      } else {
        reportError('Error fetching offerings: no offering found');
        showError({
          message: t('paywall.error'),
        });
      }
    } catch (error) {
      reportError('Error fetching offerings', error);
      showError({
        message: t('paywall.error'),
      });
    }
    setLoadingPackages(false);
  };

  useEffect(() => {
    loadPackages(placement);
  }, [placement]);

  const txtOffer = [
    PaywallScreen2Parent.paywall_timeline_txt,
    PaywallScreen2Parent.paywall_timeline_txt_reviews,
  ].includes(paywall_screen2_parent);

  return (
    <div className={'flex flex-col gap-2 w-full'}>
      {loadingPackages ? <Spinner /> : null}
      {availablePackages.length === 1 && txtOffer
        ? availablePackages.map(purchasesPackage => (
            <OfferTxt
              key={purchasesPackage.identifier}
              purchasesPackage={purchasesPackage}
              onClick={onSelectPackage}
            />
          ))
        : availablePackages.map(purchasesPackage => (
            <Offer
              key={purchasesPackage.identifier}
              isSelected={
                selectedPackage?.identifier === purchasesPackage.identifier
              }
              purchasesPackage={purchasesPackage}
              onClick={onSelectPackage}
              promo={
                purchasesPackage.packageType === PACKAGE_TYPE.ANNUAL
                  ? paywall_screen2_parent ===
                    PaywallScreen2Parent.paywall_unlock
                    ? t('paywall.panel.ANNUAL.sevenDaysTrial')
                    : t('paywall.panel.ANNUAL.threeMonthsFree')
                  : undefined
              }
            />
          ))}
    </div>
  );
};

export default OfferSelector;
