import { Trans, useTranslation } from 'react-i18next';
import PaywallDescription from 'src/components/Paywall/PaywallDescription';
import GameControllerIcon from 'src/images/paywall/game-controller.svg?react';
import ThumbUpIcon from 'src/images/paywall/thumb-up.svg?react';
import MagicWandIcon from 'src/images/paywall/magic-wand.svg?react';
import StarIcon from 'src/images/paywall/star.svg?react';
import SmileyIcon from 'src/images/paywall/smiley.svg?react';
import Button from 'src/components/common/designSystem/Button';
import { useCurrentUser } from 'src/queries/user';
import { usePaywall } from 'src/contexts/PaywallContext';
import { UserType } from 'src/types/user.types';

const ChildPaywallScreenDescription = ({
  onContinue,
  isContinuing,
}: {
  onContinue: () => void;
  isContinuing?: boolean;
}) => {
  const { nbTrialDays } = usePaywall();
  const { data: user } = useCurrentUser();
  const userType = user?.user_type;
  const translationPrefix =
    userType === UserType.parent
      ? 'paywall.description.parent'
      : 'paywall.description.child';
  const { t } = useTranslation();

  return (
    <div className={'flex flex-col p-5 gap-10'}>
      <div
        className={
          'font-extrabold text-xl text-structural-darkest text-left text-balance'
        }
      >
        <Trans i18nKey={`${translationPrefix}.title`}>
          Profitez de l’expérience Baobab
          <span
            className={
              'bg-gradient-to-r bg-clip-text text-transparent from-paywall-darkest-from to-paywall-darkest-to'
            }
          >
            sans limite !
          </span>
        </Trans>
      </div>

      <div className={'text-left text-base text-balance'}>
        <Trans
          i18nKey={`${translationPrefix}.subtitle`}
          values={{ nbTrialDays }}
        >
          <span className={'font-bold'}>Testez 14 jours, c’est gratuit !</span>
        </Trans>
        <div>{t(`${translationPrefix}.subtitle2`)}</div>
      </div>

      <div className={'flex flex-col gap-4 items-start'}>
        <PaywallDescription
          title={t(`${translationPrefix}.item1.title`)}
          description={t(`${translationPrefix}.item1.description`)}
          IconComponent={
            userType === UserType.parent ? MagicWandIcon : ThumbUpIcon
          }
        />
        <PaywallDescription
          title={t(`${translationPrefix}.item2.title`)}
          description={t(`${translationPrefix}.item2.description`)}
          IconComponent={
            userType === UserType.parent ? ThumbUpIcon : GameControllerIcon
          }
        />
        <PaywallDescription
          title={t(`${translationPrefix}.item3.title`)}
          description={t(`${translationPrefix}.item3.description`)}
          IconComponent={userType === UserType.parent ? SmileyIcon : StarIcon}
        />
      </div>

      <Button
        data-testid={'btn-start-trial'}
        type={'primary'}
        onClick={onContinue}
        loading={isContinuing}
      >
        {t(`${translationPrefix}.button`)}
      </Button>
    </div>
  );
};

export default ChildPaywallScreenDescription;
