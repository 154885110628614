import { useGetUnreadNotifications } from 'src/queries/notifications';
import { NOTIFICATIONS_SHOWN_IN_LIST } from 'src/types/notification.types';

const useGetUnreadNotificationsInList = () => {
  const { data: unreadNotifications, isLoading } = useGetUnreadNotifications();

  const flatNotifications = unreadNotifications?.pages
    .flatMap(page => page.items)
    ?.filter(
      notification =>
        !notification.disabled &&
        NOTIFICATIONS_SHOWN_IN_LIST.includes(notification.type),
    );

  return { notifications: flatNotifications, isLoading };
};

export default useGetUnreadNotificationsInList;
