export default {
  notFound: 'Invitation invalide',
  invite: "Créer un lien d'invitation",
  modalQrTitle: 'Scannez ce QR code avec le téléphone de votre enfant',
  modalShareTitle: 'Partagez ce lien d’inscription à votre enfant',
  shareLink: 'Partager le lien',
  linkExpires: 'Note: le lien est valide 24h',
  child: {
    button: 'Renvoyer le lien de connexion',
    parent: {
      button: 'Inviter mon parent',
      title: 'Inviter mon parent',
      share: {
        description:
          'Rejoins moi sur Baobab, l’app pour des révisions efficaces ! 👉',
      },
    },
    error: 'Une erreur est survenue, veuillez réessayer',
  },
  parent: {
    button: 'Demander à un adulte',
    paywall: {
      newParent:
        'Je veux utiliser Baobab, une app pour m’aider à réviser mes leçons. J’ai besoin que tu crées un compte parent pour continuer',
      existingParent:
        'Je veux utiliser Baobab, une app pour m’aider à réviser mes leçons. Peux-tu souscrire à l’abonnement pour que je continue ?',
    },
    error: 'Une erreur est survenue, veuillez réessayer',
  },
  card: {
    title: '1 ami inscrit',
    button: 'Inviter mes amis',
    message: 'Rejoins moi sur Baobab, l’app pour des révisions stylées ! 👉',
    messageParent:
      'On utilise Baobab pour les révisions, c’est super ! A essayer : ',
    error: 'Une erreur est survenue, veuillez réessayer',
    copied: 'Lien de partage copié',
  },
};
