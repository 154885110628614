import { useTranslation } from 'react-i18next';
import UserManagement from 'src/components/UserManagement/UserManagement';
import BlankLayout from 'src/layouts/BlankLayout';
import { Outlet } from 'react-router-dom';
import Footer from 'src/components/common/designSystem/Footer';
import InAppNotifications from 'src/components/Notifications/InAppNotifications';
import ParentMainTabBar from 'src/components/Layout/ParentMainTabBar';
import ParentHeader from 'src/components/Parent/ParentHeader';

const ParentHomePage = () => {
  return (
    <div className={'relative w-full h-full'}>
      <BlankLayout>
        <div
          className={
            'h-full w-full overflow-hidden overflow-y-auto flex flex-col py-4 px-3'
          }
        >
          <Outlet />
          <Footer>
            <ParentMainTabBar />
          </Footer>
        </div>
      </BlankLayout>
      <InAppNotifications />
    </div>
  );
};

export const ParentHomeTab = () => {
  const { t } = useTranslation();

  return (
    <div className={'flex flex-col gap-2 flex-1'}>
      <ParentHeader title={t('settings.title')} />
      <div className={'flex flex-col flex-grow'}>
        <UserManagement />
      </div>
    </div>
  );
};

export default ParentHomePage;
